import React from 'react'

import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import { makeStyles } from 'tss-react/mui'
import { useTypedSelector } from '../../State/RootReducer'

interface StyleProps {
  bottomBarZIndex: number
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bottomBar: {
    top: 'auto',
    bottom: 0,
    zIndex: props.bottomBarZIndex,
  },
  bottomNav: {
    flex: 1,
    backgroundColor: theme.palette.background.header,
    maxWidth: '100%',
  },
  label: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
  },
  root: {
    // padding: 0,
    // minWidth: 0,
    // fontSize: 10,
    color: theme.palette.text.header,
    // backgroundColor: theme.palette.background.header,
    // flex: 1,
  },
  disabled: {
    pointerEvents: 'none',
    color: theme.shape.bottomLabel.disabledColor,
    minWidth: 0,
    fontSize: 10,
  },
  selected: {
    zIndex: 1,
    '& .MuiBottomNavigationAction-label': {
      fontSize: '0.7rem',
    },
  },
  wrapper: {
    minWidth: 0,
    fontSize: 10,
    color: theme.shape.bottomLabel.nonSelectedColor,
    // '&.Mui-selected': {
    //   paddingTop: 0,
    // },
  },
}))

export interface Action {
  label: string
  icon: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  pathname?: string
}

export interface BottomButtonGroupProps {
  value: number
  onChange?: (value: number) => void
  actions: Action[]
  bottomNavClass?: string
  zIndex?: number
}

const BottomButtonGroup: React.VFC<BottomButtonGroupProps> = ({ value, onChange, actions, bottomNavClass, zIndex }) => {
  const { classes, cx } = useStyles({ bottomBarZIndex: zIndex ?? 9999 })
  const isHager = useTypedSelector(state => state.app.app) === 'hager'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNavigation = (v: any): void => {
    onChange?.(v)
    window.scrollTo(0, 0)
  }

  return (
    <AppBar position="fixed" color="primary" className={classes.bottomBar}>
      <Toolbar className={classes.toolbar}>
        <BottomNavigation showLabels={isHager} value={value} className={cx(classes.bottomNav, bottomNavClass)} onChange={(e, v) => onNavigation(v)}>
          {actions.map((action, i) => (
            <BottomNavigationAction
              className={action.disabled ? classes.disabled : classes.wrapper}
              key={i}
              {...action}
              classes={{
                root: classes.root,
                selected: classes.selected,
                label: classes.label,
              }}
            />
          ))}
        </BottomNavigation>
      </Toolbar>
    </AppBar>
  )
}

export default BottomButtonGroup
