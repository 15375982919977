import React from 'react'
import { Button } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.action.active,
  },
}))

export interface CallLinkProps {
  telNumber: string
  handleClick: (value: string) => void
}

const CallLink: React.VFC<CallLinkProps> = ({ telNumber, handleClick }) => {
  const { classes } = useStyles()
  return (
    <Button
      onClick={() => {
        handleClick(telNumber)
      }}
      className={classes.link}
    >
      {telNumber}
    </Button>
  )
}

export default CallLink
