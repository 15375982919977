import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDropzone } from 'react-dropzone'
import { Typography, CircularProgress } from '@mui/material'
import { useAuthFetchWithResult } from '../../Hooks/AuthFetch'
import { useSnackbar } from '../../Hooks/Snackbar'
import { useCustomTranslation } from '../../Hooks/useCustomTranslation'

const useStyles = makeStyles()(theme => ({
  uploadBox: {
    backgroundColor: theme.palette.background.paper2,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey.grey03,
    borderWidth: '1px',
    textAlign: 'center',
    width: '100%',
    minHeight: 60,
    position: 'relative',
    cursor: 'pointer',
  },
  uploadActive: {
    backgroundColor: '#7ab41c30',
  },
  label: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}))

export interface UploadProps {
  url?: string
  content?: React.ReactElement
  showFile?: boolean
  oneFile?: boolean
  onUploaded?: () => void
  onFilesDropped?: (file: Blob) => void
  className?: string
}

const Upload: React.VFC<UploadProps> = ({ url, content, showFile = true, oneFile = false, onUploaded, onFilesDropped, className }) => {
  const { classes, cx } = useStyles()
  const authFetch = useAuthFetchWithResult()
  const [uploadFiles, setUploadFiles] = useState<string[]>([])
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const { t } = useCustomTranslation()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async files => {
      let allFiles = files.map(f => f.name)
      setUploadFiles(allFiles)
      if (oneFile && allFiles.length > 1) {
        enqueueErrorStack(t('shell.files.moreThanOne'))
        return
      }
      await Promise.all(
        files.map(f =>
          (async () => {
            const formData = new FormData()
            formData.append('data', f)
            formData.append('name', f.name)
            if (onFilesDropped) onFilesDropped(f)
            if (url) {
              try {
                await authFetch({ path: url, method: 'POST', payload: formData })
                if (onUploaded) onUploaded()
                enqueueSuccessStack(t('shell.files.uploadSuccess', { fileName: f.name }))
              } catch (err) {
                enqueueErrorStack(t('shell.files.uploadFailed', { fileName: f.name }))
              } finally {
                allFiles = allFiles.filter(uFile => uFile !== f.name)
                setUploadFiles(allFiles.slice())
              }
            }
          })()
        )
      )
    },
    noKeyboard: true,
  })

  const renderContent = (): React.ReactElement => {
    if (content) {
      return content
    }
    return isDragActive ? (
      <Typography className={classes.label}>{t('shell.files.putNow')}</Typography>
    ) : (
      <Typography className={classes.label}>{t('shell.files.uploadText')}</Typography>
    )
  }

  return (
    <div
      {...getRootProps({
        className: cx(classes.uploadBox, { [classes.uploadActive]: isDragActive }, className),
      })}
    >
      <input {...getInputProps()} />
      {uploadFiles.length > 0 && showFile
        ? uploadFiles.map(name => (
            <p key={name} className={classes.label}>
              {url ? <CircularProgress size={12} className={classes.progress} /> : null}
              <Typography variant="body1" display={'inline'}>
                {name}
              </Typography>
            </p>
          ))
        : renderContent()}
    </div>
  )
}

export default Upload
