/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cloneElement, CSSProperties, ReactNode } from 'react'

interface Styles {
  groupHeadingStyles: CSSProperties
  noOptionsMsgStyles: CSSProperties
  optionStyles: CSSProperties
  loadingMsgStyles: CSSProperties
  defaultItemSize: number
}

export const flattenGroupedChildren = (children: ReactNode[]): any => {
  return children!.reduce((result: any, child: any) => {
    if (child.props.children != null && typeof child.props.children === 'string') {
      return [...result, child]
    } else {
      const {
        props: { children: nestedChildren = [] },
      } = child

      return [...result, cloneElement(child, { type: 'group' }, []), ...nestedChildren]
    }
  }, [])
}

export const isFocused = ({ props: { isFocused } }: any): boolean => {
  return isFocused === true
}

export const getCurrentIndex = (children: ReactNode[]): number => {
  return Math.max(children.findIndex(isFocused), 0)
}

export const createGetHeight = ({ groupHeadingStyles, noOptionsMsgStyles, optionStyles, loadingMsgStyles, defaultItemSize }: Styles) => {
  return function getHeight(child: any): number {
    const {
      props: {
        type,
        children,
        inputValue,
        selectProps: { noOptionsMessage, loadingMessage },
      },
    } = child

    if (type === 'group') {
      const { height = defaultItemSize } = groupHeadingStyles
      return +height
    } else if (type === 'option') {
      const { height = defaultItemSize } = optionStyles
      return +height
    } else if (typeof noOptionsMessage === 'function' && children === noOptionsMessage({ inputValue })) {
      const { height = defaultItemSize } = noOptionsMsgStyles
      return +height
    } else if (typeof loadingMessage === 'function' && children === loadingMessage({ inputValue })) {
      const { height = defaultItemSize } = loadingMsgStyles
      return +height
    } else {
      return defaultItemSize
    }
  }
}
