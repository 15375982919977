import { createTheme, responsiveFontSizes, Theme, PaletteOptions, ThemeOptions } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

export type RecursivePartial<T> = {
  // eslint-disable-next-line
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object | undefined ? RecursivePartial<T[P]> : T[P]
}

export interface ThemeObject {
  themes: { light?: Theme; dark?: Theme; hager?: Theme }
  defaultTheme: AvailableTheme
}

export type AvailableTheme = 'dark' | 'light'

const SPACING_BASE = 6

const space = (spacing: number): number => SPACING_BASE * spacing

const defaultTheme: ThemeOptions = {
  space,
  effectStyles: {
    shadowMain: '0px 4px 12px 0px rgba(0, 0, 0, 0.1)',
    shadowStrong: '0px 4px 5px 0px rgba(0, 0, 0, 0.2)',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1400,
      xl: 1920,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    bottomBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        // @ts-expect-error align does not exist
        align: 'left', // theres a ...  maybe bug...  where <grid container align="center"> with textfields that are outlined need textAlign set to left! otherwise the hole in the legend is centered ...
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: 28,
      lineHeight: 1.285,
      fontWeight: 700,
      letterSpacing: 0,
    },
    h2: {
      fontSize: 22,
      lineHeight: 1.27,
      letterSpacing: 0,
      fontWeight: 400,
    },
    h3: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: 1.34,
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    overline: {
      fontSize: 11,
      lineHeight: 1.45,
      textTransform: 'uppercase',
      fontWeight: 500,
      letterSpacing: '0.5px',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.29,
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontWeight: 400,
      textTransform: 'inherit',
    },
    caption: {
      fontSize: 11,
      lineHeight: 1.45,
      letterSpacing: '0.4px',
      fontWeight: 400,
    },
    headerCategoryTitle: {
      fontSize: 11,
      lineHeight: 1.45,
      letterSpacing: 0.5,
      fontWeight: 400,
    },
    accordionTitle: {
      variant: 'subtitle1',
    },
    paper: 'subtitle1',
    sidebar: 'body2',
    dialog: 'h3',
  },
  shape: {
    dialog: {
      title: {
        minHeight: 42,
      },
    },
    borderRadius: 12,
    dialogDivider: 0,
    accordion: {
      arrowSize: 24,
    },
    secondaryAction: {
      color: '#737373',
      borderColor: '#737373',
    },
    inputField: {
      height: 40,
      labelFontSize: 16,
      labelFontSizeShrinked: 14,
      labelColor: '#49454F',
      labelColorActive: '#5A8F1F',
      labelFontWeightShrinked: 'normal',
      labelActiveTransform: 'translate(14px, -5px) scale(0.87)',
      labelActiveLeft: -9,
      labelFloatingActive: 'translate(14px, -6px) scale(0.78)',
      labelFloatingInactive: 'translate(14px, -50%) scale(1)',
      disabledBackgroundColor: '#e6e6e6',
      fontSize: 14,
    },
    markdownEditor: {
      shadowPopover: '0px 5px 10px rgba(0, 0, 0, 0.3)',
      background: '#fff',
      editorShellColor: '#000',
      draggableButtonBorderRadius: '4px',
      draggableButtonBackground: '#efefef',
      defaultFontWeight: 400,
      tableHeaderBackground: '#dfdfdf',
    },
    select: {
      dropdownIndicatorColor: '#5A8F1F',
      controlIsFocusedColor: '#5A8F1F',
      paddingLeft: 8,
      labelFontSize: 14,
      labelFontSizeDisabled: 14,
      textColor: '#4a4a4a',
      fontSize: 14,
    },
    dayButton: {
      size: 36,
      sizeSm: 36,
    },
    logoutButton: {
      color: 'inherit',
    },
    sidebar: {
      borderRadius: 12,
      height: 'calc(100vh - 24px)',
      top: 12,
      bottom: 12,
      left: 12,
      drawerWidth: 256,
      drawerCollapsedWidth: 72,
    },
    header: {
      left: 256 + 24, //drawerWidth + spacing
      leftCollapsed: 72 + 24, //drawerCollapsedWidth + spacing
      right: 12,
      top: 12,
      borderRadius: 12,
      /** height = header component height + top position */
      height: 66,
      mobileHeight: 59,
      padding: 6,
      marginBottom: 6,
    },
    tabs: {
      topMargin: 0,
      height: 27,
    },
    paper: {
      icon: {
        width: 12,
      },
      headline: {
        marginLeft: SPACING_BASE * 2,
      },
    },
    filter: {
      height: 36,
      menuItemHeight: 36,
      searchFieldHeight: 36,
    },
    bottomLabel: {
      disabledColor: '#585a5e',
      nonSelectedColor: '#afafaf',
    },
    searchField: {
      hoverBorderColor: '#79b41c',
      maxWidth: '300px',
    },
    snackbar: {
      borderRadius: 12,
      color: {
        warningDark: '#E6A64C',
        errorDark: '#E6564C',
        successDark: '#5A8F1F',
        informationDark: '#832474',
      },
      backgroundColor: {
        warningLight: '#F1DEC3',
        errorLight: '#FFCCBC',
        successLight: '#D7E9BB',
        informationLight: '#E7B6DF',
      },
    },
    stepIconComponent: {
      color: {
        errorLight: '#FFCCBC',
        errorDark: '#E6564C',
        warningLight: '#F1DEC3',
      },
    },
    dateRange: {
      activeTextFontWeight: 'bold',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  spacing: SPACING_BASE,
}

const grey = {
  grey00: '#4a4a4a',
  grey01: '#1e252b',
  grey02: '#585a5e',
  grey03: '#a4aaae',
  grey04: '#737373',
  grey05: '#afafaf',
  grey06: '#e6e6e6',
  grey07: '#f7f7f7',
  grey08: '#313B44',
  grey09: '#f3f3f3',
  grey10: '#eaeff1',
  shadow: '#c4c4c4',
  shadowLight: '#ffffff',
  inputBorder: '#AFAFAF',
  inputBorderDisabled: '#bebebe7d',
}

const shades = {
  shade01: '#737373',
  shade02: '#AFAFAF',
  shade03: '#E6E6E6',
  shade04: '#F7F7F7',
}

const storageType = {
  SERIE: '#B7D382',
  SERIE_M_WR: '#62B7D8',
  FARM: '#FFDA88',
  'FARM-ENGINEERING': '#FFDA88',
  ENGENEERING: '#D2ACF3', //TYPO in DB....
  FELDTEST: '#FF5555',
  NONE: '#CCCCCC',
  MESSE: '#CCCCCC',
}

const lightPalette: PaletteOptions = {
  shades,
  grey,
  storageType,
  background: {
    paper2: '#f7f7f7',
    chip: '#e6e6e6',
    paperBorder: '#e6e6e6',
    sidebar: 'linear-gradient(135deg,  #1e252b 60%, #585a5e 100%)',
    header: '#ffffff',
    application: '#f4f4f4',
    paperAccent: '#79b41c',
    warrantyBubbles: '#afafaf',
    weekDayButtonInactive: '#e6e6e6',
    weekDayButtonActive: '#79b41c',
    layoutEditorItem: '#e6e6e6',
    changedInputField: '#ffffff',
    extension: {
      directMarketing: '#D7FD7E',
    },
  },
  liveView: {
    liveViewItem: '#ffffff',
    liveViewBranch: '#1A8FC8',
    energyBubble: '#00AAE1',
    energyBubbleActive: '#83bada',
  },
  map: {
    editButton: '#79b41c',
  },
  primary: {
    light: '#D7E9BB',
    main: '#79b41c',
    dark: '#5A8F1F',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#c191b9',
    main: '#832474',
    dark: '#530048',
    contrastText: '#ffffff',
  },
  error: {
    main: '#E6564C',
    light: '#EC9690',
    dark: '#E6564C',
  },
  warning: {
    light: '#ECC690',
    main: '#E6A64C',
    dark: '#E6A64C',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#4a4a4a',
    paperTitle: '#666666',
    secondary: '#afafaf',
    primaryDisabled: '#6666667d',
    header: '#4a4a4a',
    headerTitle: '#737373',
    sidebar: '#DADADA',
  },
  action: {
    active: '#468400',
  },
  accordion: {
    border: '#e6e6e6',
    title: '#666666',
    text: '#666666',
  },
  fileButton: {
    background: '#eaeff1',
    border: 'white',
    iconColor: '#4a4a4a',
    boxShadow: '-5px 5px 10px #c4c4c4, 5px -5px 10px #ffffff',
  },
  filter: {
    activeBackground: '#D7E9BB',
    inactiveBackground: '#F7F7F7',
    hoverBorder: '#4a4a4a',
    activeBorder: '#79b41c',
    inactiveBorder: '#e6e6e6',
    activeText: '#79b41c',
    inactiveText: '#4a4a4a',
    searchFieldBackground: '#F7F7F7',
    menuItemHoverBackground: '#D7E9BB',
    selectedMenuItemHoverBackground: 'rgba(121, 180, 28, 0.24)', // as there is a global menu item hover bg color override, here we revert it to what is autocalculated when the override is removed
  },
  buttonGroup: {
    inactiveBackground: '#ffffff',
    activeBackground: '#D7E9BB',
    inactiveColor: '#737373',
    activeColor: '#79b41c',
    borderColor: '#afafaf',
    borderColorActive: '#79b41c',
    activeIcon: '#DADADA',
    inactiveIcon: 'transparent',
    iconColor: '#757575',
    hover: '#ace653',
    borderWidth: '1px',
    borderWidthInactive: '1px',
  },
  chart: {
    background: 'transparent',
    totalEnergyChargedBar: '#0DB2C7',
    totalGridChargedBar: '#99e4f6',
    totalGridChargedBarLightestShade: '#33c6ea',
    totalChargedBackground: '#BBDEE9',
    totalDischargedEnergyCardBackground: '#FAE6E6',
    totalDischargedEnergyBackground: '#CC6601',
    solarChargedBar: '#EBDA2F',
    solarChargedBarLightestShade: '#FFF485',
    solarChargedBackground: '#F4EFB9',
    solarStatCardIcon: '#E6A64C',
    chargeDurationCardBackground: '#D7E9BB',
  },
  select: {
    optionActive: '#efefef',
    optionFocused: '#dcdcdc',
    optionSelected: '#dcdcdc',
  },
  sidebar: {
    active: '#79b41c',
  },
  success: {
    light: '#ace653',
    main: '#79b41c',
    dark: '#468400',
  },
  purple: {
    light: '#b555a3',
    dark: '#530048',
    main: '#832474',
    contrastText: 'white',
  },
  yellow: {
    light: '#ffff67',
    main: '#EBDA2F',
    dark: '#b5a900',
  },
  ocppConnectorStatusColor: {
    available: '#00C600',
    preparing: '#D09FF7',
    charging: '#0085C3',
    suspendedEVSE: '#8900F5',
    suspendedEV: '#FE9494',
    finishing: '#00AAE1',
    reserved: '#FF8F33',
    unavailable: '#737373',
    undefined: '#AFAFAF',
    faulted: '#DC2E2E',
    occupied: '#DC2EAB',
  },
  blue: {
    light: '#B6E8EE',
    main: '#0DB2C7',
    dark: '#0A91A2',
  },
}

const darkPalette: PaletteOptions = deepmerge(lightPalette, {
  mode: 'dark',
  grey: {
    shadow: 'transparent',
    shadowLight: 'transparent',
  },
  background: {
    default: '#1E252B',
    paper: '#4A4D51',
    paper2: '#55595e',
    chip: '#6c7177',
    paperBorder: '#979797',
  },
  text: {
    primary: '#dadada',
    secondary: '#fafafa',
    paperTitle: '#dadada',
    primaryDisabled: '#dadada7d',
  },
  accordion: {
    border: '#737373',
    title: '#dadada',
  },
  fileButton: {
    background: 'transparent',
    border: 'white',
    iconColor: '#dadada',
    boxShadow: 'none',
  },
  filter: {
    activeBackground: '#4a4a4a',
    inactiveBackground: '#3f4246',
    hoverBorder: '#79b41c',
    activeBorder: '#79b41c',
    inactiveBorder: '#3f4246',
    activeText: '#79b41c',
    inactiveText: '#fafafa',
    searchFieldBackground: '#3f4246',
    menuItemHoverBackground: 'rgba(255,255,255,0.08)',
    selectedMenuItemHoverBackground: 'rgba(121, 180, 28, 0.24)', // as there is a global menu item hover bg color override, here we revert it to what is autocalculated when the override is removed
  },
  action: {
    active: '#ace653',
  },
  select: {
    optionActive: '#5d6065',
    optionFocused: '#3f4246',
    optionSelected: '#3f4246',
  },
  chart: {
    background: '#5F6267',
  },
  sidebar: {
    active: '#79b41c',
  },
}) as PaletteOptions

export const defaultOverrides = (theme: Theme): OverridesStyleRules => ({
  MuiSlider: {
    styleOverrides: {
      root: {
        height: 5,
      },
      rail: {
        boxShadow: 'inset -1px 1px 2px #aeaeae, inset 1px -1px 2px #ffffff',
        height: 5,
        borderRadius: 5,
        border: '1px solid #979797',
      },
      track: {
        boxShadow: 'inset -1px 1px 2px #568014, inset 1px -1px 2px #9ce824;',
        height: 5,
        borderRadius: 5,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
      },
      mark: {
        height: 5,
        top: 14,
        color: 'white',
      },
      thumb: {
        height: 16,
        width: 16,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: theme.shape.inputField.fontSize,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        color: theme.palette.text.primary,
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      // Needed to prevent adding a global style for every input field
      // see https://github.com/mui/material-ui/pull/29213
      disableInjectingGlobalStyles: true,
    },
    styleOverrides: {
      root: {
        minHeight: 32,
        // no max height because of the multiline inputs
        borderRadius: 12,
        backgroundColor: theme.palette.background.paper,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey.grey06,
        },
      },
    },
  },
  MuiGrid: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        transform: 'translate(14px, 9px) scale(1)',
        fontSize: 14,
        backgroundColor: 'transparent',
        letterSpacing: 0.5,
        '&.Mui-focused': {
          color: theme.palette.primary.dark,
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.78)',
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
        padding: 0,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      gutters: {
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: theme.palette.primary.main,
      },
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        fontSize: theme.shape.inputField.fontSize,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.dark,
          borderWidth: 2,
        },
      },
      multiline: {
        paddingRight: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      notchedOutline: {
        borderColor: theme.palette.grey.inputBorder,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionEnd: {
        marginLeft: 0,
      },
      positionStart: {
        marginLeft: `-${theme.spacing(1)}`,
      },
      root: {
        color: theme.palette.grey.grey00,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minHeight: 36,
        maxHeight: 36,
        textTransform: 'none',
        borderRadius: 20,
        '& .MuiTypography-root': {
          fontSize: theme.shape.inputField.fontSize,
        },
      },
      containedSecondary: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 0,
        paddingLeft: 24,
      },
    },
  },
  MuiDialog: {
    MuiButton: {
      color: 'primary',
    },
    styleOverrides: {
      paper: {
        [theme.breakpoints.down('md')]: {
          borderRadius: 12,
          height: 'auto',
          maxHeight: '90%',
          width: '90%',
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 27,
        backgroundColor: theme.palette.background.paper,
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: 27,
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        paddingBottom: 4,
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
  },
  MuiPickerDTTabs: {
    styleOverrides: {
      tabs: {
        margin: 0,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  MuiPickersModal: {
    styleOverrides: {
      dialogRoot: {
        padding: 0,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        zIndex: 999,
        borderRadius: 12,
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
        padding: 6,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        borderRadius: 4,
        whiteSpace: 'break-spaces',
      },
      popper: {
        zIndex: 1300,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: 'rgba(121, 180, 28, 1)',
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  MuiPickersBasePicker: {
    styleOverrides: {
      pickerView: {
        // [theme.breakpoints.down('sm')]: {
        //   minWidth: 250,
        //   maxWidth: 250,
        //   paddingLeft: theme.spacing(2),
        //   paddingRight: theme.spacing(2),
        // },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundImage: 'unset',
        borderRadius: 12,
        boxShadow: theme.effectStyles.shadowMain,
        '&.MuiTableHead-root,&.MuiTablePagination-root,&.MuiAlert-root,&.MuiTableBody-root': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        borderRadius: '12px 12px 0 0',
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: theme.spacing(1),
        '&:first-of-type,&:last-of-type': {
          borderRadius: '12px 12px 0 0',
        },
        '&.Mui-expanded': {
          borderRadius: 12,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        border: 0,
        borderRadius: 0,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: 12,
        padding: '0 !important',
      },
      list: {
        backgroundColor: theme.palette.shades.shade04,
        '& .MuiMenuItem-root:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '& .MuiTypography-root': {
          fontSize: theme.shape.inputField.fontSize,
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        padding: 0,
        backgroundColor: theme.palette.shades.shade04,
      },
    },
  },
})

const override = (theme: Theme, overrides?: (theme: Theme) => OverridesStyleRules): ThemeOptions =>
  deepmerge(defaultOverrides(theme), overrides?.(theme) ?? {})

const lightTheme = createTheme({
  ...defaultTheme,
  palette: lightPalette,
})

const darkTheme = createTheme({
  ...defaultTheme,
  palette: darkPalette,
})

const getTheme = (
  themeMode: 'light' | 'dark',
  themeOptions?: RecursivePartial<ThemeOptions>,
  overrides?: (theme: Theme) => OverridesStyleRules
): Theme => {
  const mergedThemeOptions = deepmerge(themeMode === 'dark' ? darkTheme : lightTheme, themeOptions ?? {})
  const combinedOverrides = override(mergedThemeOptions, overrides)
  const mergedTheme = deepmerge(mergedThemeOptions, { components: combinedOverrides })
  const createdTheme = createTheme(mergedTheme)

  return responsiveFontSizes(createdTheme)
}

export const getLightTheme = (themeOptions?: RecursivePartial<ThemeOptions>, overrides?: (theme: Theme) => OverridesStyleRules): Theme => {
  return getTheme('light', themeOptions, overrides)
}

export const getDarkTheme = (themeOptions?: RecursivePartial<ThemeOptions>, overrides?: (theme: Theme) => OverridesStyleRules): Theme => {
  return getTheme('dark', themeOptions, overrides)
}

export const hexToRgba = (hex: string, opacity: number): string => {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
}
