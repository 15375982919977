import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.action.active,
  },
}))

export interface MailLinkProps {
  mailAddress: string
  handleClick?: (value: string) => void
  className?: string
}

const MailLink: React.VFC<MailLinkProps> = ({ mailAddress, handleClick, className }) => {
  const { classes, cx } = useStyles()

  /**
   * only call function if defined, else just trigger standard behavior
   */
  const onClick = (): void => {
    if (handleClick) {
      handleClick(mailAddress)
    }
  }

  return (
    <a onClick={onClick} href={'mailto:' + mailAddress} className={cx(classes.link, className)}>
      {mailAddress}
    </a>
  )
}

export default MailLink
