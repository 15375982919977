import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AuthState, AuthAction } from './AuthModel'

const initialState: AuthState = {
  fetchCount: 0,
  publicKey: undefined,
  token: undefined,
  decoded: undefined,
  reAuthToken: undefined,
  remember: false,
}

function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {
  const publicKey = sessionStorage.getItem('publicKey')
  const decoded = sessionStorage.getItem('decoded')
  const reAuthToken = sessionStorage.getItem('reAuthToken')

  const stateWithSession = {
    ...state,
    publicKey: state.publicKey ?? publicKey ?? undefined,
    decoded: state.decoded ?? (decoded ? JSON.parse(decoded) : undefined),
    reAuthToken: state.reAuthToken ?? reAuthToken ?? undefined,
  }

  switch (action.type) {
    case 'AUTH_UPDATE':
      return { ...stateWithSession, ...action.payload }
    case 'AUTH_FETCH_ADD':
      return { ...stateWithSession, fetchCount: state.fetchCount + 1 }
    case 'AUTH_FETCH_SUB':
      return { ...stateWithSession, fetchCount: state.fetchCount - 1 }
    case 'AUTH_PURGE':
      return initialState
    default:
      return stateWithSession
  }
}

const persistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['publicKey', 'remember', 'reAuthToken'],
}

export default persistReducer(persistConfig, authReducer)
