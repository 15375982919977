import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export const RouteContentSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height="250px"></Skeleton>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height="500px"></Skeleton>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height="500px"></Skeleton>
      </Grid>
    </Grid>
  )
}
