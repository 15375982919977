import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { PositionedAction } from './types'
import React from 'react'

interface Props {
  action: PositionedAction
}

const useStyles = makeStyles<{ downSm: boolean }>()((theme, props) => ({
  secondaryAction: {
    width: props.downSm ? '100%' : 'auto',
  },
  secondaryButton: {
    color: theme.shape.secondaryAction.color,
    borderColor: theme.shape.secondaryAction.borderColor,
    width: '100%',
  },
  primaryAction: {
    width: props.downSm ? '100%' : 'auto',
  },
}))

/**
 * Action button which can be primary or secondary
 */
const ActionButton: React.FC<Props> = ({ action }) => {
  const { name, onClick, style, disabled } = action
  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { classes } = useStyles({ downSm })
  const isPrimary = style === 'primary'

  const PrimaryButton = (
    <Box key={name} className={classes.primaryAction}>
      <Button
        sx={{ width: '100% ' }}
        variant={'contained'}
        onClick={onClick}
        data-cy={`button-modal-secondary-${name}`}
        data-qa={`button-modal-secondary-${name}`}
        disabled={disabled}
      >
        {name}
      </Button>
    </Box>
  )

  const SecondaryButton = (
    <Box key={name} className={classes.secondaryAction}>
      <Button
        color={'inherit'}
        className={classes.secondaryButton}
        sx={{ width: '100% ' }}
        variant={'outlined'}
        onClick={onClick}
        data-cy={`button-modal-secondary-${name}`}
        data-qa={`button-modal-secondary-${name}`}
        disabled={disabled}
      >
        {name}
      </Button>
    </Box>
  )

  if (isPrimary) return PrimaryButton

  return SecondaryButton
}

export default ActionButton
