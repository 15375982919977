import axios from 'axios'

export const downloadFile = (fileId: string | number, fileName: string, ext: string, apiEndpoint: string, token?: string): void => {
  axios({
    url: apiEndpoint + `/files/items/${fileId}/file`,
    responseType: 'blob', // important
    method: 'get',
    headers: {
      authorization: token ? 'Bearer ' + token : undefined,
    },
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.${ext}`) //or any other extension
    document.body.appendChild(link)
    link.click()
  })
}

export const getTruncatedString = ({ text, length = 45 }: { text?: string; length?: number }): string | undefined => {
  if (text && text?.length > length) {
    return text?.substring(0, length) + '...'
  }
  return text
}

export const stringLength = 45
