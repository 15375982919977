import React, { useEffect, useState } from 'react'
import { useTheme, Tooltip, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { components, ControlProps, Theme as SelectTheme, SingleValue } from 'react-select'
import { useAuthFetchWithResult } from '../../Hooks/AuthFetch'
import { objectApiResult } from '../../Libs/ApiHelpers'
import AsyncSelect from 'react-select/async'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import DropdownIndicator from './DropdownIndicator'
import { getSelectStyles } from './styles'
import { LabeledOption } from '../../Types/globalTypes'

interface StyleProps {
  isMulti?: boolean
  minimal?: boolean
  isFloating?: boolean
  highlighted?: boolean
  fullWidth?: boolean
  disabled?: boolean
}

const useSelectStyles = makeStyles<StyleProps>()((theme, props) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  root: {
    minWidth: 150,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  container: {
    minWidth: 150,
    width: props.fullWidth ? '100%' : undefined,
    '& > div': {
      paddingTop: props.isMulti ? theme.spacing(1) : 0,
      height: props.isMulti || props.minimal ? 'max-content' : theme.shape.inputField.height,
      maxHeight: props.isMulti || props.minimal ? 'max-content' : theme.shape.inputField.height,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: props.disabled ? theme.shape.inputField.disabledBackgroundColor : 'inherit',
      '& input': {
        height: theme.shape.inputField.height,
        marginTop: -3,
      },
      '&:focused': {
        borderColor: 'red',
      },
    },
  },
  floatingLabel: {
    position: 'absolute',
    transition: '0.2s ease all',
    transformOrigin: 'left center',
    top: props.isFloating ? -5 : '50%',
    left: 0,
    transform: props.isFloating ? theme.shape.inputField.labelActiveTransform : 'translateY(-50%)',
    fontSize: theme.shape.inputField.labelFontSize,
    color: theme.palette.text.primary,
    fontWeight: props.isFloating ? theme.shape.inputField.labelFontWeightShrinked : 'inherit',
    background: `linear-gradient(0deg, ${props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper} 0%, ${
      props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper
    } 50%, ${theme.palette.background.paper} 50%, ${theme.palette.background.paper} 100%)`,
    paddingLeft: props.isFloating ? `calc(${theme.spacing(1)} / 2)` : 14,
    paddingRight: `calc(${theme.spacing(1)} / 2)`,
    zIndex: props.isFloating ? 1 : 'inherit',
    '&:active': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const useTooltipStyles = makeStyles()(theme => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  tooltip: {
    backgroundColor: theme.palette.error.main,
    top: -8,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  arrow: {
    color: theme.palette.error.main,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  highlight: {
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: 8,
  },
}))

export interface SelectFetchedProps<T> {
  label: string
  url: string
  filterParameter: keyof T
  // payload filter
  filter?: string
  mapFunction: (item: T) => { label: string; value: string }
  placeholder?: string
  className?: string
  onSelect?: (id: string | undefined) => void
  endpoint?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortMethod?: (itemA: any, itemB: any) => number
  reset?: number
  value?: { label: string; value: string }
  helperText?: string
  disabled?: boolean
  clearable?: boolean
  highlighted?: boolean
}

/** TODO: add a description of what this component does */
export const SelectFetched = <T,>({
  label,
  url,
  filterParameter,
  filter,
  mapFunction,
  placeholder,
  className,
  onSelect,
  endpoint,
  sortMethod,
  reset,
  value,
  helperText,
  disabled,
  clearable,
  highlighted,
}: SelectFetchedProps<T>): React.ReactElement => {
  const authFetch = useAuthFetchWithResult()
  const muiTheme = useTheme()
  const { classes: inputClasses, cx } = useSelectStyles({ isMulti: false, minimal: false })
  const [inputValue, setInputValue] = useState<null | undefined>(undefined)
  const { classes } = useTooltipStyles()
  const [selected, setSelected] = useState<{ label: string; value: string } | undefined>()

  useEffect(() => {
    if (reset !== undefined && reset > 0) {
      setInputValue(null)
      setSelected(undefined)
    }
  }, [reset])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Control = (defaultProps: ControlProps<any, any>): React.ReactElement => {
    const { classes } = useSelectStyles({ isFloating: defaultProps.isFocused || defaultProps.hasValue, highlighted })

    return (
      <>
        <Typography className={classes.floatingLabel}>{label}</Typography>
        <components.Control {...defaultProps} />
      </>
    )
  }

  const promiseOptions = AwesomeDebouncePromise((inputValue: string): Promise<{ value: string; label: string }[]> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const test = authFetch<any>({
      path: inputValue
        ? `${url}?filter=${String(filterParameter)}=*${inputValue}*${filter ? `,${filter}` : ''}`
        : `${url}${filter ? `?filter=${filter}` : ''}`,
      endpoint,
    }).then(result => {
      const apiObject = objectApiResult(result) as T[]
      return apiObject.map(user => mapFunction(user)).sort((a, b) => (sortMethod ? sortMethod(a, b) : a.label.localeCompare(b.label)))
    })
    return test
  }, 1000)

  const handleOnChange = (value: SingleValue<LabeledOption<string> | undefined>): void => {
    if (value) {
      setSelected(value)
      onSelect?.(value.value)
      setInputValue(undefined)
    } else {
      if (value === null) {
        onSelect?.(undefined)
        setSelected(undefined)
        setInputValue(undefined)
      }
    }
  }

  return (
    <Tooltip title={helperText ?? ''} open={!!helperText} arrow classes={classes}>
      <span>
        <AsyncSelect
          loadOptions={promiseOptions}
          defaultOptions
          menuPortalTarget={document.body}
          components={{ DropdownIndicator, IndicatorSeparator: () => null, Control }}
          styles={getSelectStyles(undefined, muiTheme, false)}
          menuPlacement="auto"
          placeholder={placeholder ?? ''}
          className={cx(inputClasses.container, className)}
          theme={(theme: SelectTheme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: muiTheme.palette.primary.main,
            },
          })}
          onChange={value => handleOnChange(value as SingleValue<LabeledOption<string>>)}
          cacheOptions={false}
          isClearable={clearable}
          escapeClearsValue={true}
          value={value ?? selected ?? inputValue}
          isDisabled={disabled}
        />
      </span>
    </Tooltip>
  )
}
