import { NavigationAction } from './NavigationModel'

/**
 * updates the currently selected page of the installation table
 * @param page installation page selected (page1 = 1-10, page2 = 11-20, ...)
 */
export const updateNavigationPage = (page: number): NavigationAction => {
  return { type: 'NAV_UPDATE_PAGE', page: page }
}

export const updateHeaderTab = (headerTab: number): NavigationAction => {
  return { type: 'UPDATE_HEADER_TAB', headerTab: headerTab }
}
