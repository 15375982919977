import React, { useState } from 'react'

import Download from './Download'
import Upload from './Upload'
import { DeleteGenerateUrl, DownloadGenerateUrl } from './FileTransferModel'

export interface FileTransferProps {
  deleteGenerateUrl: DeleteGenerateUrl
  downloadListUrl: string
  downloadGenerateUrl: DownloadGenerateUrl
  uploadUrl: string
  apiEndpoint: string
}

const FileTransfer: React.VFC<FileTransferProps> = ({ deleteGenerateUrl, downloadListUrl, downloadGenerateUrl, uploadUrl, apiEndpoint }) => {
  const [refreshList, setRefreshList] = useState(0)
  const onUploaded = (): void => setRefreshList(refreshList + 1)

  return (
    <div>
      <Download
        listUrl={downloadListUrl}
        downloadGenerateUrl={downloadGenerateUrl}
        deleteGenerateUrl={deleteGenerateUrl}
        refresh={refreshList}
        apiEndpoint={apiEndpoint}
      />
      <Upload url={uploadUrl} onUploaded={onUploaded} />
    </div>
  )
}

export default FileTransfer
