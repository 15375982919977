import React, { memo, useEffect } from 'react'

import { Slider as MuiSlider, Typography, Tooltip, TooltipProps, SliderProps, SliderTypeMap, SliderValueLabelProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDebounce } from '../../Hooks/useDebounce'
import { useDerivedState } from '../../Hooks/UseDerivedState'

interface StyleProps {
  isOnModal: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  wrapper: {
    position: 'relative',
    margin: 'auto',
    width: `calc(100% - ${theme.spacing(5)})`,
  },
  rail: {
    backgroundColor: theme.palette.grey.grey06,
  },
  labelsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  minLabel: {
    position: 'absolute',
    left: '0',
    translate: '-35%',
  },
  maxLabel: {
    position: 'absolute',
    right: '0',
    translate: '35%',
  },
  tooltip: {
    '&.MuiTooltip-tooltip.MuiTooltip-tooltipArrow': {
      margin: '4px 0',
      backgroundColor: theme.palette.primary.main,
      '& .MuiTooltip-arrow': {
        color: theme.palette.primary.main,
      },
    },
  },
  popper: {
    zIndex: props.isOnModal ? theme.zIndex.modal + 1 : theme.zIndex.tooltip,
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
}))

export interface ValueLabelComponentProps {
  open: boolean
  value: string
  tooltipPosition: TooltipProps['placement']
  isOnModal: boolean
}

function SliderValueLabelComponent(props: SliderValueLabelProps & ValueLabelComponentProps): React.ReactElement {
  const { children, value, open, tooltipPosition, isOnModal } = props
  const { classes } = useStyles({ isOnModal })

  return (
    <Tooltip
      enterTouchDelay={0}
      placement={tooltipPosition}
      title={value}
      arrow
      classes={{
        popper: classes.popper,
        tooltipPlacementTop: classes.tooltip,
        tooltipPlacementBottom: classes.tooltip,
      }}
      open={open}
    >
      {children}
    </Tooltip>
  )
}

export interface ShellSliderProps {
  min?: number
  max?: number
  defaultValue?: number
  value?: number | [number, number]
  onChange: (value?: number | number[]) => void
  minLabel?: React.ReactNode
  maxLabel?: React.ReactNode
  marks?: SliderProps['marks']
  step?: number
  valueLabelFormat?: SliderTypeMap['props']['valueLabelFormat']
  label?: string
  disabled?: boolean
  tooltipPosition?: TooltipProps['placement']
  valueLabelDisplay?: SliderTypeMap['props']['valueLabelDisplay']
  getAriaValueText?: SliderTypeMap['props']['getAriaValueText']
  type?: 'range'
  isOnModal?: boolean
}

const Slider: React.VFC<ShellSliderProps> = ({
  min,
  max,
  defaultValue,
  value,
  onChange,
  minLabel,
  maxLabel,
  marks,
  step,
  valueLabelFormat,
  label,
  disabled,
  getAriaValueText,
  tooltipPosition = 'top',
  valueLabelDisplay = 'on',
  isOnModal = false,
  type,
}) => {
  const { classes } = useStyles({ isOnModal })
  const [displayedValue, setDisplayedValue] = useDerivedState<number | number[] | undefined>(value)
  const debouncedValue = useDebounce(displayedValue, 500)

  useEffect(() => {
    onChange(debouncedValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return (
    <div className={classes.wrapper}>
      {label && (
        <Typography id="slider" gutterBottom>
          {label}
        </Typography>
      )}
      <MuiSlider
        min={min}
        max={max}
        onChange={(event, value) => {
          setDisplayedValue(value)
        }}
        defaultValue={defaultValue ? defaultValue : 0}
        valueLabelDisplay={valueLabelDisplay}
        value={displayedValue}
        marks={marks}
        step={step}
        classes={{
          rail: classes.rail,
          root: classes.root,
        }}
        valueLabelFormat={valueLabelFormat}
        components={{
          ValueLabel: props => SliderValueLabelComponent({ ...props, tooltipPosition, isOnModal }),
        }}
        aria-labelledby="slider"
        disabled={disabled}
        getAriaValueText={getAriaValueText}
      />
      {(minLabel || maxLabel) && (
        <div className={classes.labelsWrapper}>
          <Typography className={classes.minLabel} variant="caption">
            {minLabel}
          </Typography>
          <Typography className={classes.maxLabel} variant="caption">
            {maxLabel}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default memo(Slider)
