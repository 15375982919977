import React from 'react'

import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import { useStyles } from '../styles'

/**
 * Checkbox for two values with icons, that can be enabled and disabled.
 */
export interface OnOffCheckboxFieldProps {
  onChange: (value: [boolean, boolean]) => void
  value: [boolean, boolean]
  icons: [React.ReactNode, React.ReactNode]
  tooltips?: [string, string]
  label?: string
  classNameChecked?: string
  className?: string
  disabled?: boolean
}

export const OnOffCheckboxField: React.VFC<OnOffCheckboxFieldProps> = ({
  value,
  onChange,
  icons,
  tooltips,
  label,
  className,
  classNameChecked,
  disabled,
}) => {
  const { classes, cx } = useStyles({})

  const icon1 = (
    <FormControlLabel
      className={classes.checkboxWrapper}
      labelPlacement={'start'}
      classes={{ label: classes.checkboxLabel }}
      control={
        <Checkbox
          icon={icons[0]}
          checkedIcon={icons[0]}
          name="on"
          onChange={event => {
            onChange([event.target.checked, event.target.checked ? false : value[1]])
          }}
          checked={value[0]}
          color={'primary'}
          classes={{ root: cx(classes.checkboxRoot, className), colorPrimary: classes.checkboxChecked }}
          disabled={disabled}
        />
      }
      label={label}
    />
  )

  const icon2 = (
    <div className={classes.checkboxWrapper}>
      <Checkbox
        icon={icons[1]}
        checkedIcon={icons[1]}
        name="off"
        onChange={event => onChange([event.target.checked ? false : value[0], event.target.checked])}
        checked={value[1]}
        color="primary"
        classes={{ root: cx(classes.checkboxRoot, className), colorPrimary: classes.checkboxChecked }}
        disabled={disabled}
      />
    </div>
  )
  return (
    <div className={classes.optionRow}>
      {tooltips ? (
        <Tooltip title={tooltips[0] || ''}>
          <span>{icon1}</span>
        </Tooltip>
      ) : (
        icon1
      )}
      {tooltips ? (
        <Tooltip title={tooltips[1] || ''}>
          <span>{icon2}</span>
        </Tooltip>
      ) : (
        icon2
      )}
    </div>
  )
}
