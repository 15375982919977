import { Skeleton } from '@mui/material'
import { Typography } from '@mui/material'
import React from 'react'

type TextSkeletonVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2'

interface TextSkeletonProps {
  variant: TextSkeletonVariant
  className?: string
}

export const TextSkeleton: React.FC<TextSkeletonProps> = ({ variant, className }): React.ReactElement => (
  <Typography variant={variant} className={className}>
    <Skeleton variant="text" />
  </Typography>
)
