import React from 'react'

import { makeStyles } from 'tss-react/mui'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const SuspenseRootFallback: React.VFC = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" size={80} thickness={3.6} />
    </div>
  )
}

export default SuspenseRootFallback
