import React, { useState, useEffect, memo, ChangeEvent, useRef } from 'react'
import { useStyles, useTooltipStyles } from './styles'

import { TextField as MuiTextField, Tooltip, TextFieldProps, Box } from '@mui/material'

import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import { J1ToparrowD, J1DownarrowD, J2Multiply } from '@e3dc-react/icons'
import { DECIMAL_REGEX, INTEGER_REGEX } from './defaults'
import {
  addDecimals,
  clampNumber,
  clampNumericString,
  determineDecimalPoints,
  getDecimalAddendWithXDecimalPoints,
  parseDecimal,
  parseInteger,
} from './number-field-utils'

export interface NumberFieldProps {
  onChange: (value: number | '', fieldName: string) => void
  name?: string
  variant?: TextFieldProps['variant']
  value?: number | ''
  label?: string
  placeholder?: string
  /** delay in miliseconds after which the callback will be fired. Prevents firing callback after each value change. Default value = 300 */
  delayInMs?: number
  prefix?: string
  min?: number
  max?: number
  className?: string
  resetValue?: number | ''
  helperText?: string
  disabled?: boolean
  required?: boolean
  clearable?: boolean
  /** shows what type of value it is (e.g. 100 kWh <- adornment) */
  suffix?: string
  initAfterRender?: boolean
  highlighted?: boolean
  isDecimal?: boolean
  maxIntegerDigits?: number
  maxDecimalDigits?: number
  isExceededBoundaryVisible?: boolean
  shouldSetVisibleValueAfterDebounce?: boolean
  /** An array of characters for keys that will be ignored when pressed. Usual cases are 'e', '-', '+'... */
  preventKeys?: string[]
  error?: boolean
}

export const NumberField: React.VFC<NumberFieldProps> = ({
  onChange,
  min,
  max,
  placeholder,
  value,
  label,
  helperText,
  className,
  prefix,
  suffix,
  maxIntegerDigits,
  maxDecimalDigits = 13,
  isDecimal = false,
  resetValue = '',
  variant = 'outlined',
  delayInMs = 300,
  disabled = false,
  required = false,
  clearable = true,
  initAfterRender = false,
  highlighted = false,
  isExceededBoundaryVisible = false,
  shouldSetVisibleValueAfterDebounce = false,
  preventKeys = ['e'],
  name = '',
  error,
  ...otherProps
}) => {
  const { classes } = useTooltipStyles({})
  const { classes: inputClasses } = useStyles({ type: 'number', clearable, hasSuffix: !!suffix, highlighted })

  const [numberFieldValue, setNumberFieldValue] = useState<string>(value?.toString() ?? '')
  const decimalPoints = useRef(isDecimal ? determineDecimalPoints(value ?? '') : 0)
  const lastValue = useRef<number | ''>(value ?? '')
  if ((value ?? '') !== lastValue.current) {
    lastValue.current = value ?? ''
    setNumberFieldValue(value?.toString() ?? '')
  }

  useEffect(() => {
    const updateCorrectedNumber = (): void => {
      if (numberFieldValue.trim() === '') {
        lastValue.current = ''
        onChange('', name)
        return
      }

      setNumberFieldValue(clampNumericString(numberFieldValue, min, max))

      const parsedNumber = isDecimal ? parseDecimal(numberFieldValue, maxDecimalDigits, min, max) : parseInteger(numberFieldValue, min, max)
      if (parsedNumber === null) return

      if (parsedNumber !== lastValue.current) {
        lastValue.current = parsedNumber
        onChange(parsedNumber, name)
      }
    }

    const timeout = setTimeout(updateCorrectedNumber, delayInMs)
    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberFieldValue, min, max, delayInMs, isDecimal, maxDecimalDigits, name])

  const increaseNumber = (): void => {
    /**TODO:  max length of number field is 15 (at least two symbols before decimal numbers start) */
    const number = parseFloat(numberFieldValue)
    if (isNaN(number) || numberFieldValue.trim() === '') {
      setNumberFieldValue((min || 0).toString())
      return
    }

    const numberSum = addDecimals(number, getDecimalAddendWithXDecimalPoints(decimalPoints.current))
    const clampedNumber = clampNumber(numberSum, min, max)
    setNumberFieldValue(clampedNumber.toString())
  }

  const decreaseNumber = (): void => {
    /**TODO:  max length of number field is 15 (at least two symbols before decimal numbers start) */
    const number = parseFloat(numberFieldValue)
    if (isNaN(number) || numberFieldValue.trim() === '') {
      setNumberFieldValue((min || 0).toString())
      return
    }

    const numberSum = addDecimals(number, -getDecimalAddendWithXDecimalPoints(decimalPoints.current))
    const clampedNumber = clampNumber(numberSum, min, max)
    setNumberFieldValue(clampedNumber.toString())
  }

  const onNumberInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const inputVal = event.target.value

    const testRegex = isDecimal ? DECIMAL_REGEX : INTEGER_REGEX
    if (!testRegex.test(inputVal)) return

    if (maxIntegerDigits || maxDecimalDigits) {
      const [integerPart, decimalPart] = inputVal.split(/[.,]/)

      if (maxIntegerDigits && integerPart?.length > maxIntegerDigits) return
      if (maxDecimalDigits && decimalPart?.length > maxDecimalDigits) return
    }

    setNumberFieldValue(inputVal.replaceAll(',', '.'))

    if (isDecimal) decimalPoints.current = determineDecimalPoints(inputVal)
  }

  const trimMultipleZeroesFromStart = (input: string): string => {
    if (input.length > 1 && /^0+/.test(input)) {
      return input.replace(/^0+/, isDecimal ? '0' : '')
    }

    return input
  }

  const parsedNumberField = parseFloat(numberFieldValue)

  return (
    <Tooltip
      title={helperText || ''}
      open={!!helperText}
      arrow
      classes={classes}
      placement="bottom"
      PopperProps={{
        disablePortal: true,
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
        ],
      }}
    >
      <MuiTextField
        fullWidth
        onChange={onNumberInputChange}
        label={label}
        value={trimMultipleZeroesFromStart(numberFieldValue)}
        variant={variant}
        placeholder={placeholder}
        margin="dense"
        type={'text'}
        lang={navigator.language === 'de' ? 'de-DE' : navigator.language === 'fr' ? 'fr-FR' : 'en-US'}
        error={Boolean(helperText) || error}
        disabled={disabled}
        required={required}
        className={className}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {suffix && <div>{suffix}</div>}
              {!disabled && clearable && (
                <IconButton onClick={() => setNumberFieldValue(resetValue.toString())} size="large">
                  <J2Multiply />
                </IconButton>
              )}
              <Box className={inputClasses.numberFieldArrows}>
                <IconButton onClick={increaseNumber} disabled={disabled || (max !== undefined && parsedNumberField >= max)} size="large">
                  <J1ToparrowD />
                </IconButton>
                <IconButton onClick={decreaseNumber} disabled={disabled || (min !== undefined && parsedNumberField <= min)} size="large">
                  <J1DownarrowD />
                </IconButton>
              </Box>
            </InputAdornment>
          ),
          startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
          inputProps: {
            min,
            max,
            maxLength: 15,
            pattern: isDecimal ? '(-)?[0-9]*(.|,)?[0-9]*' : '(-)?[0-9]*',
            inputMode: isDecimal ? 'decimal' : 'numeric',
            onKeyPress: event => {
              if (preventKeys.includes(event.key)) {
                event.preventDefault()
              }
            },
            onKeyDown: event => {
              if (event.key === 'ArrowUp') {
                event.preventDefault()
                increaseNumber()
              } else if (event.key === 'ArrowDown') {
                event.preventDefault()
                decreaseNumber()
              }
            },
          },
          classes: { input: inputClasses.input },
        }}
        InputLabelProps={{
          classes: { root: inputClasses.inputLabel },
        }}
        classes={{ root: inputClasses.textField }}
        {...otherProps}
      />
    </Tooltip>
  )
}

export const MemoizedNumberField = memo(NumberField)
