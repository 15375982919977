import { AppState } from './App/AppModel'
import { AuthState } from './Auth/AuthModel'
import { ApiViewState } from './ApiView/ApiViewModel'
import { NavigationState } from './Navigation/NavigationModel'
import { createSelectorHook } from 'react-redux'

export interface DefaultRootState {
  app: AppState
  auth: AuthState
  apiView: ApiViewState
  navigation: NavigationState
}

export const useTypedSelector = createSelectorHook<DefaultRootState>()
