import React, { Children, ReactElement, VFC, forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { VariableSizeList as List } from 'react-window'
import { OptionProps, GroupBase } from 'react-select'
import { Box } from '@mui/material'

import { createGetHeight, flattenGroupedChildren, getCurrentIndex } from './util'
import { OptionTypeBase } from '../../Types/globalTypes'

interface MenuItemProps {
  data: ReactElement[]
  index: number
  setMeasuredHeight: (data: { index: number; measuredHeight: number }) => void
}

/**
 * Component to virtualize big amount of options to improve perfomance.
 * Used as Menulist for react-select lib.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const VirtualizedMenuList = (props: any): ReactElement => {
  const children = useMemo(() => {
    const children = Children.toArray(props.children)

    const head = children[0] || {}

    if (React.isValidElement<OptionProps<OptionTypeBase, boolean, GroupBase<OptionTypeBase>>>(head)) {
      const { props: { data: { options = [] } = {} } = {} } = head
      const groupedChildrenLength = options.length
      const isGrouped = groupedChildrenLength > 0
      const flattenedChildren = isGrouped && flattenGroupedChildren(children)

      return isGrouped ? flattenedChildren : children
    } else {
      return []
    }
  }, [props.children])

  const { getStyles, innerRef, selectProps, theme } = props
  const { classNamePrefix, isMulti } = selectProps || {}
  const list = useRef<List>(null)

  /**  default 'react-select' item height */
  const defaultItemSize = theme.spacing.controlHeight

  const groupHeadingStyles = getStyles('groupHeading', props)
  const loadingMsgStyles = getStyles('loadingMessage', props)
  const noOptionsMsgStyles = getStyles('noOptionsMessage', props)
  const optionStyles = getStyles('option', props)
  const getHeight = createGetHeight({
    groupHeadingStyles,
    noOptionsMsgStyles,
    optionStyles,
    loadingMsgStyles,
    defaultItemSize,
  })

  const heights = useMemo<number[]>(() => children.map(getHeight), [children, getHeight])
  const currentIndex = useMemo(() => getCurrentIndex(children), [children])
  const itemCount = children.length

  const [measuredHeights, setMeasuredHeights] = useState<{ [key: number]: number }>({})

  // calc menu height
  const { maxHeight, paddingBottom = 0, paddingTop = 0, ...menuListStyle } = getStyles('menuList', props)
  const totalHeight = useMemo(() => {
    return heights.reduce((sum, height, idx) => {
      if (measuredHeights[idx]) {
        return sum + measuredHeights[idx]
      } else {
        return sum + height
      }
    }, 0)
  }, [heights, measuredHeights])
  const totalMenuHeight = totalHeight + paddingBottom + paddingTop
  const menuHeight = Math.min(maxHeight, totalMenuHeight)
  const estimatedItemSize = Math.floor(totalHeight / itemCount)

  useEffect(() => {
    setMeasuredHeights({})
  }, [props.children])

  /** method to pass to inner item to set this items outer height */
  const setMeasuredHeight = (data: { index: number; measuredHeight: number }): void => {
    const { index, measuredHeight } = data
    if (measuredHeights[index] !== undefined && measuredHeights[index] === measuredHeight) {
      return
    }

    setMeasuredHeights(measuredHeights => ({
      ...measuredHeights,
      [index]: measuredHeight,
    }))

    /** this forces the list to rerender items after the item positions resizing */
    if (list.current) {
      list.current.resetAfterIndex(index)
    }
  }

  /** enables scrolling on key down arrow */
  useEffect(() => {
    if (currentIndex >= 0 && list.current !== null) {
      list.current.scrollToItem(currentIndex)
    }
  }, [currentIndex, children, list])

  return (
    <List
      className={classNamePrefix ? `${classNamePrefix}__menu-list${isMulti ? ` ${classNamePrefix}__menu-list--is-multi` : ''}` : ''}
      style={menuListStyle}
      ref={list}
      outerRef={innerRef}
      estimatedItemSize={estimatedItemSize}
      innerElementType={forwardRef(function InnerElement({ style, ...rest }, ref) {
        return (
          <Box
            ref={ref}
            style={{
              ...style,
              height: `${parseFloat(style.height) + paddingBottom + paddingTop}px`,
            }}
            {...rest}
          />
        )
      })}
      height={menuHeight}
      width="100%"
      itemCount={itemCount}
      itemData={children}
      itemSize={index => measuredHeights[index] || heights[index]}
    >
      {({ data, index, style }) => {
        return (
          <Box
            style={{
              ...style,
              top: `${parseFloat(style.top!.toString()) + paddingTop}px`,
            }}
          >
            <MenuItem data={data[index]} index={index} setMeasuredHeight={setMeasuredHeight} />
          </Box>
        )
      }}
    </List>
  )
}

/**  Component to display inner item for VirtualizedMenuList. */
const MenuItem: VFC<MenuItemProps> = (props): ReactElement => {
  const { data, index, setMeasuredHeight } = props
  const ref = useRef<HTMLDivElement>(null)

  /** using useLayoutEffect prevents bounciness of options of re-renders */
  useLayoutEffect(() => {
    if (ref.current) {
      const measuredHeight = ref.current.getBoundingClientRect().height

      setMeasuredHeight({ index, measuredHeight })
    }
  }, [index, setMeasuredHeight])

  return (
    <Box key={`option-${index}`} ref={ref}>
      {data}
    </Box>
  )
}
export default VirtualizedMenuList
