export enum FetchErrorCodes {
  InvalidRequestQueryInput = 100,
  InvalidRequestPayloadInput = 101,
  InvalidRequestParamsInput = 102,
  InvalidRequestHeaderInput = 103,
  InvalidResponseOutput = 104,

  NoAuthorizationHeaderPresent = 200,
  InvalidToken = 201,
  JwtExpired = 202,
  UnknownUser = 203,
  UserLevelHasNoAccess = 205,
  InsufficientRightsToStorage = 206,
  UnhandledJwtError = 207,
  InsufficientActionRights = 208,
  NdaUnlockedRenewJwt = 209,
  NdaExpired = 210,
  ReferenceHasNoAccess = 211,
  InvalidAuthStrategy = 212,
  NoAccountLinkForInstallationFound = 230,
  NoUserToSerialForStorageFound = 231,
  UserLevelBrandTypeMismatch = 232,
  UnknownBrandType = 233,
  SsoIdIsMissingWithHagerBrandType = 234,
  WebsocketExpired = 235,

  InvalidRequestQueryFieldsFormat = 300,
  InvalidRequestQueryFieldsField = 201,
  InvalidRequestQueryFilterFormat = 310,
  InvalidRequestQueryFilterConditionFormat = 311,
  InvalidRequestQueryFilterConditionField = 312,
  InvalidRequestQuerySortFormat = 320,
  InvalidRequestQuerySortField = 321,
  InvalidRequestQuerySortDirection = 322,
  InvalidRequestQueryConnectFormat = 331,
  InvalidRequestQueryConnectField = 331,

  Error = 400,
  DoesNotExist = 404,
  DoesAlreadyExist = 405,
  LimitReached = 406,
  NotNecessary = 407,
  NotPossible = 408,
  Expired = 409,

  InterServiceCommunication = 500,
  EConnRefused = 501,
  UnknownService = 900,

  Auth = 1000,
  UserNotFound = 1001,
  MissingCompanyIdForNda = 1002,
  IpBlocked = 1003,
  IpDoesNotExist = 1004,

  BusinessWorld = 2000,

  Storage = 3000,
  StorageHasNoAddress = 3001,
  StorageHasNoActivationAgreement = 3002,
  StorageAddressAlreadyExists = 3003,
  OwnerNotSet = 3004,
  OwnerHasNoAddress = 3005,
  SameAsOwner = 3006,
  SamsungSerianlNoCheckFailed = 3007,
  SamsungBatterySerialNoSetupIncomplete = 3008,
  InitTimestampCheckMissing = 3009,
  /**
   * An unsupported device was requested
   */
  NotSupported = 3010,

  User = 4000,
  UserHasNoAddress = 4001,
  UserAddressAlreadyExists = 4002,
  InvalidMimeTypeOnlyPdf = 4003,
  NdaAlreadyExists = 4004,
  NdaDoesNotExist = 4005,
  CompanyHasNoAddress = 4006,
  CannotSwitchCompanyNdaEntryExists = 4007,
  UserAlreadyExists = 4008,
  UserDoesNotExist = 4009,
  NoActivationPresentForUsernameAndKey = 4010,
  ActivationHasBeenExpired = 4011,
  ActivationError = 4012,
  PasswordResetTokenDoesNotExist = 4013,
  PasswordResetTokenHasExpired = 4014,
  UserIsReference = 4016,
  UserHasNoEmail = 4017,
  UserDeleted = 4018,
  UserRegisterTokenDoesNotExist = 4019,
  UserRegisterTokenHasExpired = 4020,
  UserAlreadyReservedForRegistration = 4021,
  CompanyUstIdIsNotValid = 4022,
  NoCompanyIdFound = 4023,
  CurrentUserPasswordInvalid = 4024,
  NewUserPasswordInvalid = 4025,
  UserAlreadyIsAssignedToCompany = 4026,
  NdaAlreadyAccepted = 4027,
  PasswordPartOfBreach = 4029,

  Wallbox = 5000,

  Commissioning = 6000,
  InvalidFileType = 6001,

  ExpenseApplicationDoesNotExist = 7000,

  Files = 8000,
  ParentIdDoesNotExist = 8001,
  CategoryDoesNotExist = 8002,
  InvalidMimeType = 8003,
  FilesItemDoesNotExist = 8004,
  VersionDoesNotExist = 8005,
  PublicLinkNameAlreadyExists = 8006,
  SizeLimitReachedForInstallation = 8007,

  Notifications = 9000,
  NotificationsDoesNotExist = 9001,
  NotificationsItemDoesNotExist = 9002,
  UserLeveOfItemIsNotEnough = 9003,
  StartTimestampExceedsStop = 9004,

  CsvArchive = 10000,
  SerialNoNotFound = 10001,
  TcSerialCodeNotFound = 10002,
  TcSerialCodeAmbiguous = 10003,

  WeatherForecast = 11000,
  EmptyData = 11001,

  Emobility = 12000,
  NoAuth = 12001,
  RfidAlreadyInUse = 12002,

  Rscp = 13000,
  ServerConnectionCouldNotBeEstablished = 13001,
  TargetConnectionCouldNotBeEstablished = 13002,
  WorkerRequestTimeout = 13003,
  WorkerConnectTimeout = 13004,
  RequestTimeout = 13005,
  NoACtiveWorkerInstances = 13006,
  NotAvailablePleaseTryAgainLater = 13007,
  IndexOutOfBounds = 13008,
  InvalidRequestFormatUseMsgpackEncoding = 13009,
  UnknownRscpTag = 13010,
  WbNetworkConfigurationParametersAreMissingOrInvalid = 13011,
  ScanWasNotStarted = 13012,
  WallboxCouldNotBeAdded = 13013,
  ParameterAlreadyHasThisValue = 13014,
  RscpResponseError = 13015,
  RequestResponseIsUndefined = 13016,
  RcServerSOcketConnectionTimeout = 13017,
  RscpResponseInvalid = 13018,

  StoragesSimulator = 14000,
  InvalidSimulatorFileType = 14001,

  GdiTimeSeriesData = 15000,
  TableDoesNotExist = 15001,
  SchemaDoesNotExist = 15002,
  OneOrMoreColumnsCannotBeResolved = 15003,
  HashsumDoesNotExistOrHasNoData = 15004,
  SerialNoDoesNotExistOrHasNoData = 15005,
  UnknownS3Bucket = 15006,

  CrmNotes = 16000,
  NotesUserNotFound = 16001,
  UsersCanOnlyCreateNotesForThemselves = 16002,
  SapCustomerNotFound = 16003,

  JiraService = 17000,
  NoImportFile = 17001,
  InvalidJiraFileType = 17002,

  Installations = 21000,
  InstallationAlreadyHasAnOwner = 21001,
  DeviceLinksMissing = 21002,
  DeviceLinkIdNotFound = 21003,
  DeviceLinkIdDuplicate = 21004,
  DeviceTypeIsNotStorage = 21005,
  InstallationHasActiveDeviceLinks = 21006,
  InstallationIsIncomplete = 21007,
  OwnerMissing = 21010,
  SsoIdMissing = 21011,
  DeviceLinkIsReadOnlyAsReplaced = 21020,
  DeviceLinkAssociationCannotBeUpdated = 21021,
  PermissionsOfInstallationsAdminCannotBeChanged = 21022,
  InstallationAdminCannotBeDeleted = 21023,
  DeviceLinkDeviceIdReplacementNotPossible = 21024,
  EmsBlockingTimeDuplicatedDay = 21101,
  PairingNotSuccessful = 21201,
  PairingNotSuccessfulLocalIdIsEmpty = 21202,
  DisconnectingNotSuccessful = 21203,
  ModbusRtuAddressAlreadyUsed = 21204,
  InstallationBrandTypeLabelMismatch = 21205,
  PairingPollingNotSuccessful = 21206,
  UnpairedDeviceLinkReplacementForbidden = 21207,
  BrandTypeNotSpecified = 21209,
  DeviceShortUuidInvalid = 21210,
  DeviceTypeMismatch = 21211,
  InstallerAccountTypeNotCompany = 21212,
  AccountTypeCompanyNotAvailable = 21213,
  InstallerHasNoCompany = 21214,
  SerialIsNotPartOfAnInstallation = 21215,
  NoValidFarmMaster = 21216,
  NotE3dcInstallation = 21217,
  ModbusRtuAddressOutOfRamge = 21218,

  OnlyManagerCanInvite = 21220,
  /* company management related backend api response code*/
  /**
   * User already assigned to a company
   */
  UserAlreadyInCompany = 21221,
  OnlyManagerCanInviteToView = 21222,
  /**
   * User does not exist while adding it to a resource
   */
  IncorrectUser = 21223,
  /**
   * only USER, PARTNER or INSTALLER users can accept invitation
   */
  OnlyUserCanAccept = 21224,

  ActivationAgreementIsAlreadyComplete = 21230,
  CloudKeyIsAlreadyInUse = 21231,

  OnlyManagerCanViewRecipientsOfErrorLogNotifications = 21240,
  ErrorNotification = 22000,
  ErrorEntryIsAlreadyInSystemLogsOrHasTOWait = 22001,
  SystemLogEntryCreationFailed = 22002,
  /**
   * EHA error codes
   */
  VehicleIsAlreadyAdded = 26006,
  VinIsNotFound = 26007,
  VehicleImageIsNotFound = 26008,
  VehicleIsNotFound = 26009,
}
