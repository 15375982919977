import { i18n } from 'i18next'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppLang } from '../State/App/AppModel'

import { appUpdate } from '../State/App/AppActions'
import { useTypedSelector } from '../State/RootReducer'

export const useLanguageController = (i18n?: i18n): void => {
  const lang = useTypedSelector(state => state.app.lang)
  const dispatch = useDispatch()

  useEffect(() => {
    if (lang && i18n) i18n.changeLanguage(lang)
  }, [lang]) // eslint-disable-line

  // dispatch available language keys into state.app.langs
  useEffect(() => {
    dispatch(appUpdate({ langs: (i18n?.languages as AppLang[]) ?? [] }))
  }, []) // eslint-disable-line
}
