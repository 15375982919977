import React from 'react'
import { Skeleton } from '@mui/material'

interface AccordionSkeletonProps {
  open?: boolean
}

export const AccordionSkeleton: React.VFC<AccordionSkeletonProps> = ({ open = true }) => {
  if (open) return <Skeleton variant="rectangular" height="300px"></Skeleton>

  return <Skeleton variant="rectangular" height="48px" />
}
