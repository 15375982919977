import React from 'react'
import { DialogActions as MuiDialogActions, useTheme, Box, useMediaQuery } from '@mui/material'
import { DialogPaginationProps } from '../DialogPagination'
import ActionButton from './ActionButton'
import { ActionPosition, PositionedAction, UpToThreeActions } from './types'

interface Props {
  setOpen: (isOpen: boolean) => void
  actions: UpToThreeActions
  pagination?: DialogPaginationProps
}

/**
 * Up to three actions are displayed at the bottom of a dialog and are either primary or secondary.
 * On desktop they are positioned in 4 possible positions in a row.
 * On mobile they are positioned from top to bottom
 */
const Actions: React.VFC<Props> = ({ actions }) => {
  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))
  const actionsMap: Partial<Record<ActionPosition, PositionedAction>> = {}

  for (const action of actions) {
    if (action) actionsMap[action.position] = action
  }

  const action1 = actionsMap[1]
  const action2 = actionsMap[2]
  const action3 = actionsMap[3]
  const action4 = actionsMap[4]

  return (
    <MuiDialogActions sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: downSm ? 'column' : 'row', justifyContent: 'space-between', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: downSm ? 'column' : 'row', gap: 1 }}>
          {action1 && <ActionButton action={action1} />}
          {action2 && <ActionButton action={action2} />}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: downSm ? 'column' : 'row', gap: 1 }}>
          {action3 && <ActionButton action={action3} />}
          {action4 && <ActionButton action={action4} />}
        </Box>
      </Box>
    </MuiDialogActions>
  )
}

export default Actions
