import React from 'react'
import { useStyles } from './styles'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { TextSkeleton } from '../Skeletons'

interface ListSkeletonProps {
  columnNames?: string[]
  length?: number
}

export const ListSkeleton: React.VFC<ListSkeletonProps> = ({ columnNames, length = 10 }) => {
  const { classes } = useStyles()

  return (
    <Table size="small">
      {columnNames && (
        <TableHead>
          <TableRow>
            {columnNames.map((name, index) => (
              <TableCell key={index} align={index === columnNames.length - 1 && columnNames.length > 1 ? 'right' : undefined}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {new Array(length).fill('').map((_row, index) => (
          <TableRow key={index}>
            {columnNames ? (
              columnNames.map((name, index) => (
                <TableCell key={index} align={index === columnNames.length - 1 ? 'right' : undefined}>
                  <TextSkeleton variant="body1" />
                </TableCell>
              ))
            ) : (
              <>
                <TableCell>
                  <TextSkeleton variant="body1" />
                </TableCell>
                <TableCell>
                  <TextSkeleton variant="body1" className={classes.right} />
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
