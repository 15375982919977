import React, { useState } from 'react'
import { useTheme, useMediaQuery, DialogTitle, IconButton, Typography, DialogContent, Box } from '@mui/material'
import MuiDialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from 'tss-react/mui'
import Actions from './Actions'
import { UpToThreeActions } from './types'
import { Transition } from '../Transition'

interface StyleProps {
  place?: number
  app?: string
  scrollable: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingTop: (props?.place ?? 0) * 15 + 5,
    },
    height: '100%',
    '& .MuiDialog-paper.MuiPaper-root': {
      paddingBottom: 0,
      overflowX: props.scrollable ? undefined : 'hidden',
    },
  },
  dialogTitle: {
    minHeight: theme.shape.dialog.title.minHeight,
    display: 'flex',
    alignItems: 'center',
    [`${theme.breakpoints.down('md')} and (hover: none)`]: {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  dialogDivider: {
    borderTop: '2px solid',
    borderColor: theme.palette.grey.grey06,
    margin: `0 ${theme.shape.dialogDivider}px`,
  },
  title: {
    position: 'relative',
    marginLeft: theme.spacing(2),
    color: theme.palette.text.paperTitle,
    '&::before': {
      content: '""',
      display: 'block',
      width: 4,
      height: `calc(100% + ${theme.spacing(4)})`,
      backgroundColor: theme.palette.background.paperAccent,
      position: 'absolute',
      top: `-${theme.spacing(3)}`,
      left: `-${theme.spacing(2)}`,
    },
  },
}))

interface DialogProps {
  title: string
  onClose: () => void
  place?: number
  open: boolean
  actions: UpToThreeActions
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  scrollable?: boolean
  className?: string
}

/**
 * Dialog with simple title, content and 1-3 actions.
 */
const ActionDialog: React.FC<DialogProps> = ({ title, onClose, place, actions, children, className, maxWidth = 'md', scrollable = true }) => {
  const [isOpen, setIsOpen] = useState(true)
  const theme = useTheme()
  const fullScreen = useMediaQuery(`${theme.breakpoints.down('md')} and (hover: none)`) // aims at phones and tablets
  const { classes, cx } = useStyles({ place, scrollable })

  const handleClose = (): void => {
    setIsOpen(false)
  }

  return (
    <MuiDialog
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
      className={cx(classes.dialog, className)}
      TransitionComponent={Transition}
      keepMounted
      TransitionProps={{ onExited: () => onClose() }}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle} component={'div'}>
        <Typography variant={theme.typography.dialog} className={classes.title} color="textPrimary">
          {title}
        </Typography>
        <IconButton className={classes.closeButton} data-qa={'button-modal-close'} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>

      <Box className={classes.dialogDivider} />

      <Actions setOpen={setIsOpen} actions={actions} />
    </MuiDialog>
  )
}

export default ActionDialog
