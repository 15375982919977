import React from 'react'
import { IconButton } from '@mui/material'
import { useSnackbar as useNotistackSnackbar } from 'notistack'
import { J2Multiply } from '@e3dc-react/icons'
import { MOBILE_BOTTOM_BAR_HIGHT } from '../Configuration/navigation'
import { makeStyles } from 'tss-react/mui'
import { WEBVIEW_REGEX } from '../Configuration/webview'

type UseSnackbarReturnType = {
  enqueueErrorStack: (message: string, errorCode?: number | string, errorMessage?: string) => void
  enqueueSuccessStack: (message: string) => void
  enqueueWarningStack: (message: string) => void
  enqueueInfoStack: (message: string) => void

  /**
   * Ensures an Error will never been overseen. Enqueue the message of an Error to the snackbar.
   * Should only be used for situations where no specific error-handling is defined yet
   *
   * @param error
   */
  snackError: (error: Error) => void
}

interface StyleProps {
  isWebView: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  container: {
    borderRadius: theme.shape.snackbar.borderRadius,
    [theme.breakpoints.down('sm')]: {
      marginBottom: props.isWebView ? MOBILE_BOTTOM_BAR_HIGHT : 0,
    },
    '&.SnackbarItem-variantSuccess': {
      backgroundColor: theme.shape.snackbar.backgroundColor.successLight,
      color: theme.shape.snackbar.color.successDark,
    },
    '&.SnackbarItem-variantError': {
      backgroundColor: theme.shape.snackbar.backgroundColor.errorLight,
      color: theme.shape.snackbar.color.errorDark,
    },
    '&.SnackbarItem-variantWarning': {
      backgroundColor: theme.shape.snackbar.backgroundColor.warningLight,
      color: theme.shape.snackbar.color.warningDark,
    },
    '&.SnackbarItem-variantInfo': {
      backgroundColor: theme.shape.snackbar.backgroundColor.informationLight,
      color: theme.shape.snackbar.color.informationDark,
    },
  },
}))

/**
 * custom hook to wrap the snackbar
 */
export const useSnackbar = (): UseSnackbarReturnType => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar()
  const { classes } = useStyles({ isWebView: WEBVIEW_REGEX.test(navigator.userAgent) })

  const snackError = (error: Error): void => {
    console.error(error)
    enqueueSnackbar(error.message, {
      variant: 'error',
    })
  }

  const enqueueErrorStack = (message: string, errorCode?: number | string, errorMessage?: string): void => {
    let snackbarMessage = message

    if (errorCode) {
      snackbarMessage += `. Error: ${errorCode}`
      if (errorMessage) {
        snackbarMessage += ` - ${errorMessage}`
      }
    }
    if (!errorCode && errorMessage) {
      snackbarMessage += `. Error: ${errorMessage}`
    }

    enqueueSnackbar(snackbarMessage, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      className: classes.container,
      action: key => {
        return (
          <IconButton onClick={() => closeSnackbar(key)} size="large" color="inherit">
            <J2Multiply />
          </IconButton>
        )
      },
    })
  }

  const enqueueSuccessStack = (message: string): void => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      className: classes.container,
      action: key => {
        return (
          <IconButton onClick={() => closeSnackbar(key)} size="large" color="inherit">
            <J2Multiply />
          </IconButton>
        )
      },
    })
  }

  const enqueueWarningStack = (message: string): void => {
    enqueueSnackbar(message, {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      className: classes.container,
      action: key => {
        return (
          <IconButton onClick={() => closeSnackbar(key)} size="large" color="inherit">
            <J2Multiply />
          </IconButton>
        )
      },
    })
  }

  const enqueueInfoStack = (message: string): void => {
    enqueueSnackbar(message, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      className: classes.container,
      action: key => {
        return (
          <IconButton onClick={() => closeSnackbar(key)} size="large" color="inherit">
            <J2Multiply />
          </IconButton>
        )
      },
    })
  }
  return { enqueueErrorStack, enqueueSuccessStack, enqueueWarningStack, enqueueInfoStack, snackError }
}
