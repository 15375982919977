import React from 'react'

import { Box, FormControlLabel, Switch, SwitchProps } from '@mui/material'
import { useStyles } from './styles'

export interface SwitchFieldProps extends SwitchProps {
  highlighted?: boolean
  label?: string
  labelPlacement?: 'start' | 'end'
  formControllClass?: string
  className?: string
}

export const SwitchField: React.VFC<SwitchFieldProps> = ({ label, highlighted, labelPlacement = 'end', className, formControllClass, ...props }) => {
  const { classes } = useStyles({})
  const switchComponent = <Switch className={className} {...props} />
  if (label) {
    return (
      <FormControlLabel
        className={className}
        control={switchComponent}
        labelPlacement={labelPlacement}
        label={
          highlighted ? (
            <>
              {labelPlacement === 'start' && <span className={classes.highlightedLabel}>✱</span>}
              {label}
              {labelPlacement === 'end' && <span className={classes.highlightedLabel}>✱</span>}
            </>
          ) : (
            <span className={formControllClass}>{label}</span>
          )
        }
      />
    )
  }
  return <Box>{switchComponent}</Box>
}
