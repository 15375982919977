import React from 'react'
import { Moment } from 'moment'
import { TextField as MuiTextField, Hidden, TextFieldProps } from '@mui/material'
import { useStyles } from '../styles'

import {
  DesktopDateTimePicker as KeyboardDateTimePicker,
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
  DesktopDateTimePickerProps as KeyboardDateTimePickerProps,
} from '@mui/x-date-pickers'

/**
 * Keyboard Date Picker Component
 */
export interface DateTimePickerProps {
  onChange?: (date: Moment | null) => void
  value?: Moment
  label?: string
  variant?: TextFieldProps['variant']
  clearable?: boolean
  helperText?: string | boolean
  required?: boolean
  disabled?: boolean
  minDate?: Moment
  minDateMessage?: string
  inputFormat?: string
}

export const DateTimePicker: React.VFC<DateTimePickerProps> = ({
  onChange,
  value,
  label,
  clearable,
  disabled,
  helperText,
  minDate,
  minDateMessage,
  variant = 'outlined',
  required = false,
  inputFormat,
}) => {
  const { classes } = useStyles({})
  const parameter: Partial<MuiDateTimePickerProps<Moment, Moment> & KeyboardDateTimePickerProps<Moment, Moment>> = {
    inputFormat: inputFormat ?? 'DD.MM.YYYY HH:mm',
    ampm: false,
    value,
    onChange: (date: Moment | null) => (onChange ? onChange(date) : {}),
    minDate,
  }

  return (
    <>
      <Hidden mdUp>
        <MuiDateTimePicker {...(parameter as MuiDateTimePickerProps<Moment, Moment>)} className={classes.textField} />
      </Hidden>
      <Hidden mdDown>
        <KeyboardDateTimePicker
          {...(parameter as KeyboardDateTimePickerProps<Moment, Moment>)}
          renderInput={props => (
            <MuiTextField {...props} variant={variant} required={required} fullWidth disabled={disabled} className={classes.textField} />
          )}
        />
      </Hidden>
    </>
  )
}
