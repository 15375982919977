import { persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import storage from 'redux-persist/lib/storage'
import { NavigationAction, NavigationState } from './NavigationModel'

const initialState: NavigationState = {
  page: 0,
  headerTab: 0,
}

function navigationReducer(state: NavigationState = initialState, action: NavigationAction): NavigationState {
  switch (action.type) {
    case 'NAV_UPDATE_PAGE':
      return { ...state, page: action.page ?? 0 }
    case 'UPDATE_HEADER_TAB':
      return { ...state, headerTab: action.headerTab ?? 0 }
    default:
      return state
  }
}

const persistConfig = {
  key: 'navigation',
  // TODO: do we need to persist the state? this has unexpected/unwanted side effects. as an example: the tab navigation reacts after an refresh not as an user would expect it should
  storage: storage,
  stateReconciler: hardSet,
}

export default persistReducer(persistConfig, navigationReducer)
