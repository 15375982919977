import { useState, useEffect } from 'react'
import Joi, { ValidationErrorItem } from 'joi'
import { useCustomTranslation } from '../useCustomTranslation'

export const useValidation = (obj: unknown, schema: Joi.Schema): ParseDetailsReturnType => {
  const { t } = useCustomTranslation()
  const [validationResult, setValidationResult] = useState<ParseDetailsReturnType>({})
  const currentLanguage = localStorage.getItem('i18nextLng')

  useEffect(() => {
    const messages = {
      'any.forbidden': t('joi.any.forbidden'),
      'any.invalid': t('joi.any.invalid'),
      'any.required': t('joi.any.required'),
      'any.only': t('joi.any.only'),
      'any.default': t('joi.any.default'),
      'any.ref': t('joi.any.ref'),
      'boolean.base': t('joi.boolean.base'),
      'binary.base': t('joi.binary.base'),
      'binary.min': t('joi.binary.min'),
      'binary.max': t('joi.binary.max'),
      'binary.length': t('joi.binary.length'),
      'alternatives.any': t('joi.alternatives.any'),
      'array.base': t('joi.array.base'),
      'array.includes': t('joi.array.includes'),
      'array.includesRequiredUnknowns': t('joi.array.includesRequiredUnknowns'),
      'array.includesRequiredKnowns': t('joi.array.includesRequiredKnowns'),
      'array.includesRequiredBoth': t('joi.array.includesRequiredBoth'),
      'array.min': t('joi.array.min'),
      'array.max': t('joi.array.max'),
      'array.length': t('joi.array.length'),
      'array.excludes': t('joi.array.excludes'),
      'array.orderedLength': t('joi.array.orderedLength'),
      'array.sparse': t('joi.array.sparse'),
      'array.unique': t('joi.array.unique'),
      'date.base': t('joi.date.base'),
      'date.min': t('joi.date.min'),
      'date.max': t('joi.date.max'),
      'date.format': t('joi.date.format'),
      'object.base': t('joi.object.base'),
      'object.unknown': t('joi.object.unknown'),
      'object.min': t('joi.object.min'),
      'object.max': t('joi.object.max'),
      'object.length': t('joi.object.length'),
      'object.with': t('joi.object.with'),
      'object.without': t('joi.object.without'),
      'object.missing': t('joi.object.missing'),
      'object.xor': t('joi.object.xor'),
      'object.oxor': t('joi.object.oxor'),
      'object.and': t('joi.object.and'),
      'object.nand': t('joi.object.nand'),
      'object.assert': t('joi.object.assert'),
      'object.instance': t('joi.object.instance'),
      'number.base': t('joi.number.base'),
      'number.min': t('joi.number.min'),
      'number.max': t('joi.number.max'),
      'number.less': t('joi.number.less'),
      'number.greater': t('joi.number.greater'),
      'number.integer': t('joi.number.integer'),
      'number.negative': t('joi.number.negative'),
      'number.positive': t('joi.number.positive'),
      'number.precision': t('joi.number.precision'),
      'number.multiple': t('joi.number.multiple'),
      'string.empty': t('joi.string.empty'),
      'string.base': t('joi.string.base'),
      'string.min': t('joi.string.min'),
      'string.max': t('joi.string.max'),
      'string.length': t('joi.string.length'),
      'string.alphanum': t('joi.string.alphanum'),
      'string.token': t('joi.string.token'),
      'string.uri': t('joi.string.uri'),
      'string.uriCustomScheme': t('joi.string.uriCustomScheme'),
      'string.isoDate': t('joi.string.isoDate'),
      'string.lowercase': t('joi.string.lowercase'),
      'string.uppercase': t('joi.string.uppercase'),
      'string.trim': t('joi.string.trim'),
      'string.ip': t('joi.string.ip'),
      'string.ipVersion': t('joi.string.ipVersion'),
      'string.email': t('joi.string.email'),
      'string.guid': t('joi.string.guid'),
      'string.hex': t('joi.string.hex'),
      'string.hostname': t('joi.string.hostname'),
      'string.creditCard': t('joi.string.creditCard'),
      'string.pattern.base': t('joi.string.pattern.base'),
      'string.pattern.name': t('joi.string.pattern.name'),
      'object.rename.multiple': t('joi.object.rename.multiple'),
      'object.rename.override': t('joi.object.rename.override'),
      root: 'value',
    }
    const errorObj = schema.validate(obj, {
      abortEarly: false,
      allowUnknown: true,
      messages,
    })
    if (errorObj.error && errorObj.error.details) {
      setValidationResult(parseDetails(errorObj.error.details))
    } else {
      setValidationResult({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj, schema, currentLanguage])

  return validationResult
}

export interface ParseDetailsReturnType {
  [key: string]: Joi.ValidationErrorItem
}

const parseDetails = (errors: ValidationErrorItem[]): ParseDetailsReturnType => {
  const res: { [key: string]: ValidationErrorItem } = {}
  for (const error of errors) {
    res[error.path.join('.')] = error
  }
  return res
}
