import React from 'react'
import { Typography } from '@mui/material'
import { ControlProps, components } from 'react-select'
import { makeStyles } from 'tss-react/mui'

interface StyleProps {
  isFloating?: boolean
  highlighted?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  controlLabel: {
    position: 'absolute',
    transition: '0.2s ease all',
    transformOrigin: 'left center',
    top: props.isFloating ? -5 : '50%',
    left: 0,
    transform: props.isFloating ? theme.shape.inputField.labelActiveTransform : 'translateY(-50%)',
    fontSize: props.isFloating ? theme.shape.inputField.labelFontSizeShrinked : theme.shape.inputField.labelFontSize,
    color: props.isFloating ? theme.shape.inputField.labelColorActive : theme.palette.text.primary,
    fontWeight: props.isFloating ? theme.shape.inputField.labelFontWeightShrinked : 'inherit',
    background: `linear-gradient(0deg, ${props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper} 0%, ${
      props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper
    } 50%, ${theme.palette.background.paper} 50%, ${theme.palette.background.paper} 100%)`,
    paddingLeft: props.isFloating ? `calc(${theme.spacing(1)} / 2)` : 14,
    paddingRight: `calc(${theme.spacing(1)} / 2)`,
    zIndex: props.isFloating ? 1 : 'inherit',
    '&:active': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

/**
 * This function provides the React control item as one of the select components
 */
export const getControlItem = (
  label: string,
  highlighted: boolean,
  required: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((defaultProps: ControlProps<any, any>) => React.ReactElement) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Control = (defaultProps: ControlProps<any, any>): React.ReactElement => {
    const { isFocused, hasValue } = defaultProps
    const { classes } = useStyles({ isFloating: isFocused || hasValue, highlighted })
    const labelString = [label]

    if (required) labelString.push('*')

    return (
      <>
        <Typography className={classes.controlLabel}>{isFocused ? labelString.join(' ') : ''}</Typography>
        <components.Control {...defaultProps} />
      </>
    )
  }
  return Control
}
