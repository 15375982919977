import React, { useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography, Button, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List, { ListItem } from './List'

const useStyles = makeStyles()(theme => ({
  center: {
    textAlign: 'center',
  },
}))

/**
 * @interface Component to display an expanded details list
 * @property data items to be displayed in a list. Should have an id
 */
export interface CollapsedDetailsListProps {
  title: string
  data: ListItem[] | null
  maxItemsShown?: number
}

const CollapsedDetailsList: React.VFC<CollapsedDetailsListProps> = ({ title, data, maxItemsShown = 5 }) => {
  const { classes } = useStyles()
  const [sliced, setSliced] = useState(maxItemsShown)

  return (
    <Accordion square disabled={!data || data.length <= 0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data && data.length > 0 && (
          <Grid container>
            <Grid item xs={12}>
              <List data={data.slice(0, sliced)} />
            </Grid>
            {sliced < data.length && (
              <Grid item xs={12} className={classes.center}>
                <Button variant="text" onClick={() => setSliced(sliced => (sliced += maxItemsShown))} fullWidth>
                  weitere
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default CollapsedDetailsList
