import { Box, CircularProgress, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useStyles } from './styles'
import { TextSkeleton } from '../Skeletons'

interface PieChartSkeletonProps {
  radius?: number
  loading?: boolean
  progress?: number
  progressDetails?: React.ReactElement
  showLoadingPercent?: boolean
}

export const PieChartSkeleton: React.VFC<PieChartSkeletonProps> = ({
  radius = 250,
  loading,
  progress,
  progressDetails,
  showLoadingPercent = true,
}) => {
  const theme = useTheme()
  const { classes } = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  const size = matches ? 200 : radius

  return (
    <Grid container justifyContent="center">
      {(loading || progress) && (
        <Box className={classes.pieChartProgress}>
          <CircularProgress />
          {showLoadingPercent && progress !== undefined && `${(progress * 100).toFixed(2)}%`}
          {progressDetails}
        </Box>
      )}
      <Skeleton variant="circular" height={size} width={size} className={classes.pieSkeleton}></Skeleton>
      <Grid container spacing={2} justifyContent="center">
        {new Array(4).fill('').map((_item, index) => (
          <Grid key={index} item xs={2}>
            <TextSkeleton variant="body1"></TextSkeleton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
