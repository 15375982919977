import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Error } from '@mui/icons-material'
import InfoText from '../InfoText/InfoText'
import {
  AccordionSkeleton,
  ApiTableSkeleton,
  BarChartSkeleton,
  EditorSkeleton,
  LineChartSkeleton,
  ListSkeleton,
  PieChartSkeleton,
  RouteContentSkeleton,
} from '../Skeletons'

const useStyles = makeStyles()(theme => ({
  center: {
    justifyContent: 'center',
  },
  message: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  background: {
    background:
      'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 95%, rgba(255,255,255,0) 100%)',
    padding: theme.spacing(2),
  },
  wrapper: {
    position: 'relative',
    width: '100%',
  },
}))

interface ErrorMessageProps {
  title: string
  className?: string
  icon?: React.ReactNode
}

export const ErrorMessage: React.VFC<ErrorMessageProps> = ({ title, className, icon }): React.ReactElement => {
  const { classes, cx } = useStyles()

  return <InfoText icon={icon ?? <Error color={'error'} />} title={title} className={cx(classes.center, className)} />
}

export type SkeletonType =
  | 'apiTable'
  | 'list'
  | 'barChart'
  | 'pieChart'
  | 'lineChart'
  | 'composedChart'
  | 'comparisonChart'
  | 'accordion'
  | 'accordionClosed'
  | 'editor'
  | 'route'
  | 'Editor'

interface ErrorSkeletonProps {
  title: string
  type?: SkeletonType
  height?: number
}

export const ErrorSkeleton: React.VFC<ErrorSkeletonProps> = ({ title, type = 'accordion', height }) => {
  const { classes, cx } = useStyles()
  const accordionType = type === 'accordion' || type === 'accordionClosed' || type === 'pieChart'

  return (
    <div className={classes.wrapper}>
      {getSkeleton(type, height)}
      <ErrorMessage title={title} className={cx(classes.message, { [classes.background]: !accordionType })} />
    </div>
  )
}

const getSkeleton = (type: SkeletonType, height: number | undefined): React.ReactElement => {
  switch (type) {
    case 'apiTable':
      return <ApiTableSkeleton />
    case 'list':
      return <ListSkeleton />
    case 'barChart':
      return <BarChartSkeleton height={height} />
    case 'lineChart':
      return <LineChartSkeleton height={height} />
    case 'pieChart':
      return <PieChartSkeleton />
    case 'accordion':
      return <AccordionSkeleton />
    case 'accordionClosed':
      return <AccordionSkeleton open={false} />
    case 'editor':
      return <EditorSkeleton />
    case 'route':
      return <RouteContentSkeleton />
    default:
      return <ListSkeleton />
  }
}
