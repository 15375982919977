import { makeStyles } from 'tss-react/mui'
import React from 'react'

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.action.active,
  },
}))

const ExternalLink: React.FC<{ target: string }> = ({ target }) => {
  const { classes } = useStyles()
  const hasProtocol = target.toLowerCase().startsWith('http')
  const webAddress = hasProtocol ? target : `http://${target}`

  return (
    <a href={webAddress} target={'_blank'} rel={'noreferrer'} className={classes.link}>
      {target}
    </a>
  )
}

export default ExternalLink
