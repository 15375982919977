import React from 'react'
import { Fab, FabProps } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

interface StyleProps {
  size: FabProps['size']
  fixed?: boolean
  hasBottomBar?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  root: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 'auto',
    minWidth: props.size === 'small' ? 40 : props.size === 'medium' ? 48 : 56,
    maxWidth: props.size === 'small' ? 40 : props.size === 'medium' ? 48 : 56,
    borderRadius: 24,
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      width: 'auto',
      transition: 'all 0.3s ease-in-out',
      maxWidth: 400,
      padding: `0 ${theme.spacing(2)}`,
      borderRadius: 24,
      position: 'absolute',
      right: 0,
      bottom: 0,

      '& .MuiFab-label span': {
        maxWidth: 400,
      },
    },
  },
  // rootSecondary: {
  //   color: theme.palette.primary.main,
  //   backgroundColor: theme.palette.common.white,
  //   borderColor: theme.palette.primary.main,
  //   borderWidth: 1,
  //   borderStyle: 'solid',
  //   fontWeight: theme.typography.fontWeightBold,
  //   '&:hover': {
  //     color: theme.palette.primary.main,
  //     backgroundColor: theme.palette.common.white,
  //     borderColor: theme.palette.primary.main,
  //     borderWidth: 1,
  //     borderStyle: 'solid',
  //     fontWeight: theme.typography.fontWeightBold,
  //   },
  // },
  text: {
    maxWidth: 0,
    transition: 'max-width 0.2s ease-in-out',
    overflow: 'hidden',
  },
  container: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    minWidth: props.size === 'small' ? 40 : props.size === 'medium' ? 48 : 56,
    minHeight: props.size === 'small' ? 40 : props.size === 'medium' ? 48 : 56,
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(11),
      zIndex: 11,
    },
  },
}))

export interface FabButtonProps {
  icon: React.ReactNode
  extended?: boolean
  extendOnHover?: boolean
  size?: FabProps['size']
  text?: string
  iconPosition?: 'left' | 'right'
  color?: FabProps['color']
  onClick: () => void
  className?: string
  fixed?: boolean
  hasBottomBar?: boolean
}
export const FabButton: React.VFC<FabButtonProps> = ({
  icon,
  extended,
  text,
  extendOnHover,
  onClick,
  className,
  size = 'medium',
  iconPosition = 'right',
  color,
  fixed,
  hasBottomBar = true,
  ...otherProps
}) => {
  const { classes, cx } = useStyles({ size, fixed, hasBottomBar })

  return (
    <div className={cx(classes.container, className)}>
      <Fab
        // className={classNames({ [classes.root]: extendOnHover, [classes.rootSecondary]: color === 'secondary' })}
        className={cx({ [classes.root]: extendOnHover })}
        color={color ?? 'primary'}
        size={size}
        variant={extended ? 'extended' : 'circular'}
        onClick={onClick}
        {...otherProps}
      >
        {iconPosition === 'left' && icon}
        <span className={cx({ [classes.text]: extendOnHover })}>{text}</span>
        {iconPosition === 'right' && icon}
      </Fab>
    </div>
  )
}

export default FabButton
