import React from 'react'
import ErrorBoundary from './ErrorBoundary'
import { useNavigate } from 'react-router-dom'

interface Props {
  logo?: string
  errorComponent?: React.ReactNode
}

/** Wrap ErrorBoundary with history hook */
const ErrorBoundaryWrapper: React.FC<Props> = props => {
  const navigate = useNavigate()
  return <ErrorBoundary {...props} navigate={navigate} />
}

export default ErrorBoundaryWrapper
