import React, { useEffect, memo } from 'react'
import { useStyles, useTooltipStyles } from './styles'

import { TextField as MuiTextField, Tooltip, TextFieldProps as MuiTextFieldProps, CircularProgress, SxProps, InputLabelProps } from '@mui/material'

import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import { useDebounce } from '../../Hooks/useDebounce'
import { useDerivedState } from '../../Hooks/UseDerivedState'
import { J2Multiply } from '@e3dc-react/icons'

export interface TextFieldProps {
  onChange?: (value: string, fieldName: string) => void
  variant?: MuiTextFieldProps['variant']
  value?: string
  label?: string | React.ReactElement
  placeholder?: string
  /** delay in miliseconds after which the callback will be fired. Prevents firing callback after each value change. Default value = 300 */
  delayInMs?: number
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  className?: string
  resetValue?: number
  helperText?: string
  disabled?: boolean
  required?: boolean
  clearable?: boolean
  fullWidth?: boolean
  multiline?: boolean
  spaceRight?: boolean
  rows?: number
  rowsMax?: number
  name?: string
  endAdornment?: React.ReactElement
  startAdornment?: React.ReactElement
  suffix?: string
  prefix?: string | React.ReactNode
  type?: 'password' | 'email' | 'time' | 'date' | 'datetime' | 'number'
  disableAutoComplete?: boolean
  autoFocus?: boolean
  loading?: boolean
  highlighted?: boolean
  sx?: SxProps
  InputLabelProps?: Partial<InputLabelProps>
  useTooltipErrorMessage?: boolean
  errorZIndex?: number
  step?: number
  useDebounceDaley?: boolean
  error?: boolean
}

export const TextField: React.VFC<TextFieldProps> = ({
  onChange,
  placeholder,
  value,
  label,
  helperText,
  className,
  rows,
  rowsMax,
  name,
  endAdornment,
  startAdornment,
  prefix,
  suffix,
  type,
  max,
  maxLength,
  minLength,
  variant = 'outlined',
  delayInMs = 300,
  disabled = false,
  required = false,
  clearable = true,
  multiline = false,
  fullWidth = true,
  spaceRight = false,
  disableAutoComplete = false,
  autoFocus = false,
  loading = false,
  highlighted = false,
  useTooltipErrorMessage = true,
  useDebounceDaley = true,
  step = 1,
  errorZIndex,
  error,
  ...otherProps
}) => {
  const [textFieldValue, setTextFieldValue] = useDerivedState(value ?? '')
  const debouncedValue = useDebounce(textFieldValue, delayInMs)
  const { classes, cx } = useTooltipStyles({ zIndex: errorZIndex })
  const { classes: inputStyles } = useStyles({ hasSuffix: !!suffix, highlighted })

  useEffect(() => {
    setTextFieldValue(value ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (onChange && !useDebounceDaley) onChange(debouncedValue, name as string)
    if (onChange && value !== debouncedValue && useDebounceDaley) onChange(debouncedValue, name as string)
  }, [debouncedValue]) // eslint-disable-line

  const TextField = (
    <MuiTextField
      onChange={event => setTextFieldValue(event.target.value)}
      type={type ?? 'text'}
      label={label}
      fullWidth={fullWidth}
      error={Boolean(helperText) || error}
      helperText={useTooltipErrorMessage ? '' : helperText}
      value={textFieldValue || ''}
      className={cx(className, spaceRight ? inputStyles.spaceRight : undefined)}
      variant={variant}
      placeholder={placeholder}
      margin="dense"
      name={name}
      required={required}
      disabled={disabled}
      multiline={multiline}
      rows={multiline ? rows ?? 5 : undefined}
      maxRows={multiline ? rowsMax ?? 10 : undefined}
      autoFocus={autoFocus}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {suffix && <div>{suffix}</div>}
            {clearable && !disabled && value !== '' && value !== undefined && (
              <IconButton onClick={() => setTextFieldValue('')} classes={{ root: inputStyles.searchFieldIcon }} size="large">
                <J2Multiply />
              </IconButton>
            )}
            {endAdornment}
            {loading && <CircularProgress size={24} />}
          </InputAdornment>
        ),
        startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : startAdornment ?? null,
        classes: {
          disabled: inputStyles.disabled,
        },
        autoComplete: disableAutoComplete && type === 'password' ? 'new-password' : disableAutoComplete ? 'off' : 'on',
        inputProps: {
          maxLength: maxLength,
          minLength: minLength,
          step,
        },
      }}
      InputLabelProps={{
        classes: { root: inputStyles.inputLabel },
      }}
      classes={{ root: inputStyles.textField }}
      {...otherProps}
    />
  )

  if (useTooltipErrorMessage) {
    return (
      <Tooltip
        title={helperText || ''}
        open={!!helperText}
        arrow
        classes={classes}
        PopperProps={{
          disablePortal: true,
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: [],
              },
            },
          ],
        }}
      >
        {TextField}
      </Tooltip>
    )
  }

  return TextField
}

export const MemoizedTextField = memo(TextField)
