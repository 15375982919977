import React, { useState } from 'react'

import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { J2Document, J2Download } from '@e3dc-react/icons'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadFile } from '../../Libs/document.service'
import { useAuthToken } from '../../Hooks/AuthFetch'
import { useTranslation } from 'react-i18next'

interface StyleProps {
  depth?: number
  fullWidth?: boolean
  hasIcon: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => {
  const getRootBackgroundColor = (): string => {
    if (props.depth && props.depth > 0) {
      if (props.depth % 2 === 0) return theme.palette.background.paper
      return theme.palette.shades.shade04
    }
    return theme.palette.background.paper
  }

  const getHoverBackgroundColor = (): string => {
    if (props.depth && props.depth > 0) {
      if (props.depth % 2 === 0) return theme.palette.background.paper2
      return theme.palette.shades.shade03
    }
    return theme.palette.background.paper
  }

  return {
    root: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      marginRight: theme.spacing(2),
      backgroundColor: getRootBackgroundColor(),
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${theme.palette.shades.shade03}`,
      borderRadius: theme.shape.borderRadius,
      boxSizing: 'border-box',
      transition: 'all 0.3s',

      '&:hover': {
        backgroundColor: getHoverBackgroundColor(),
        transition: 'all 0.3s',
        cursor: 'pointer',
      },
    },
    tile: {
      height: 84,
      maxHeight: 84,
      width: 234,
      maxWidth: 234,
    },
    listItem: {
      height: 32,
      maxHeight: 32,
      width: '100%',
      maxWidth: '100%',
    },
    fileClickHolder: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'nowrap',
    },
    contentTile: {
      minWidth: 126,
      maxWidth: props.hasIcon ? 126 : 200,
      overflow: 'hidden',
      lineHeight: '20px',
      height: '100%',
    },
    listItemContent: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    iconHolderTile: {
      height: 60,
      width: 48,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.primary.dark,
    },
    iconTile: {
      fontSize: 40,
    },
    iconListItem: {
      fontSize: 24,
    },
    title: {
      fontWeight: 'bold',
      wordBreak: 'break-word',
    },
    additionalContent: {
      position: 'absolute',
      right: 0,
      top: theme.spacing(1),
    },
    fileExtension: {
      color: theme.palette.primary.dark,
    },
    downloadIcon: {
      color: theme.palette.text.primary,
    },
  }
})

export interface MenuItemModel {
  label: string
  onClick: () => void
}

export interface FileButtonProps {
  name: string
  ext: string
  id?: number
  description?: string
  onClickDownload?: (name: string) => void
  onClickDelete?: (name: string) => void
  fullWidth?: boolean
  fileClassName?: string
  // content placed on the top right corner, used primarly for action buttons
  additionalContent?: React.ReactNode
  depth?: number
  date?: string
  size?: string
  hasIcon?: boolean
  version?: number
  menuItems?: MenuItemModel[]
  actionIcon?: React.ReactElement
  imageUrl?: string
  apiEndpoint: string
}

const FileButton: React.VFC<FileButtonProps> = ({
  name,
  ext,
  id,
  description,
  onClickDownload,
  onClickDelete,
  additionalContent,
  menuItems,
  fullWidth = false,
  fileClassName,
  depth = 0,
  date,
  size,
  hasIcon = true,
  version,
  actionIcon,
  imageUrl,
  apiEndpoint,
}) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles({ depth, fullWidth, hasIcon })
  const [getAuthToken] = useAuthToken()
  const firstLine = date && size ? `${date}, ${size}` : date ?? size
  const secondLine = t('file.version', { version })
  const [documentMenuAnchor, setDocumentMenuAnchor] = useState<HTMLButtonElement | null>(null)

  const handleFileButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    setDocumentMenuAnchor(event.currentTarget)
  }

  const handleDocumentMenuClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    event.stopPropagation()
    setDocumentMenuAnchor(null)
  }

  const classNames = cx(classes.root, fileClassName, {
    [classes.listItem]: fullWidth && !fileClassName,
    [classes.tile]: !fileClassName && !fullWidth,
  })

  const handleOnClick = (): void => {
    if (onClickDownload) {
      onClickDownload(name)
      return
    }
    if (id) {
      downloadFile(id, name, ext, apiEndpoint, getAuthToken())
    }
  }

  return (
    <Box className={classNames} data-cy={`button-download-${id}`} onClick={handleOnClick}>
      {additionalContent && <Box className={classes.additionalContent}>{additionalContent}</Box>}
      <Box className={classes.fileClickHolder}>
        {hasIcon && (
          <Box className={classes.iconHolderTile}>
            {imageUrl ? (
              <Box style={{ display: 'flex', width: 30, height: 30 }}>
                <Box component="img" style={{ width: 30, height: 30 }} src={imageUrl} alt="small preview" />
              </Box>
            ) : (
              <J2Document className={cx(fullWidth ? classes.iconListItem : classes.iconTile)} color="primary" />
            )}
            {!fullWidth && (
              <Typography className={classes.fileExtension} variant={'subtitle2'}>
                {ext}
              </Typography>
            )}
          </Box>
        )}
        <Box className={cx(fullWidth ? classes.listItemContent : classes.contentTile)}>
          <Typography variant="subtitle1" className={classes.title} noWrap title={`${name}.${ext}`}>
            {`${name}.${ext}`}
          </Typography>
          {firstLine && (
            <Typography variant="caption" title={firstLine} component="div">
              {firstLine}
            </Typography>
          )}
          {typeof version === 'number' && (
            <Typography variant="caption" title={secondLine} component="div">
              {secondLine}
            </Typography>
          )}
          {description && (
            <Typography variant="caption" title={description} component="div">
              {description}
            </Typography>
          )}
        </Box>
        {onClickDelete && (
          <Box className={classes.iconHolderTile} onClick={() => onClickDelete(name)}>
            <DeleteIcon className={classes.icon} />
          </Box>
        )}
        <Box>
          <IconButton
            className={classes.downloadIcon}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => (menuItems ? handleFileButtonClick(event) : {})}
          >
            {actionIcon ?? <J2Download />}
          </IconButton>
        </Box>
      </Box>
      {menuItems ? (
        <Menu anchorEl={documentMenuAnchor} open={!!documentMenuAnchor} onClose={handleDocumentMenuClose}>
          {menuItems?.map((item, i) => {
            return (
              <MenuItem
                onClick={event => {
                  item.onClick()
                  handleDocumentMenuClose(event)
                }}
                key={i}
              >
                {item.label}
              </MenuItem>
            )
          })}
        </Menu>
      ) : null}
    </Box>
  )
}

export default FileButton
