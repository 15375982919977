import React from 'react'
import { Stack, DialogActions as MuiDialogActions, Button, useTheme, Box } from '@mui/material'
import { ButtonWithLoading } from '../Buttons/Buttons'
import DialogPagination, { DialogPaginationProps } from './DialogPagination'
import { DialogActions } from './Dialog'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  leftActionSpacer: {
    flex: '1 0 0',
  },
  leftActionButton: {
    marginLeft: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}))

interface Props {
  setOpen: (isOpen: boolean) => void
  actions?: DialogActions
  additionalActionsLeft?: React.ReactNode
  pagination?: DialogPaginationProps
}

/**
 * Actions are displayed at the bottom of a dialog and are either primary or secondary.
 */
const Actions: React.VFC<Props> = ({ setOpen, additionalActionsLeft, pagination, actions }) => {
  const { classes } = useStyles()
  const theme = useTheme()

  return (
    <MuiDialogActions sx={{ paddingTop: 2, paddingBottom: 2 }}>
      {additionalActionsLeft && (
        <>
          <div className={classes.leftActionButton}>{additionalActionsLeft}</div>
          <div className={classes.leftActionSpacer}></div>
        </>
      )}

      {pagination && <DialogPagination count={pagination.count} page={pagination.page} setPage={pagination.setPage} />}

      <Stack direction={'row'} spacing={1}>
        {actions?.secondary &&
          (Array.isArray(actions.secondary) ? (
            actions.secondary.map(action => (
              <Box key={action.name}>
                <Button
                  color={'inherit'}
                  sx={{ color: theme.shape.secondaryAction.color, borderColor: theme.shape.secondaryAction.borderColor }}
                  variant={'outlined'}
                  onClick={() => {
                    action.onClick ? action.onClick() : setOpen(false)
                  }}
                  data-cy={`button-modal-secondary-${action.name}`}
                  data-qa={`button-modal-secondary-${action.name}`}
                  disabled={action.disabled}
                >
                  {action.name}
                </Button>
              </Box>
            ))
          ) : (
            <Box>
              <Button
                color={'inherit'}
                sx={{ color: theme.shape.secondaryAction.color, borderColor: theme.shape.secondaryAction.borderColor }}
                variant={'outlined'}
                onClick={() => setOpen(false)}
                data-cy={`button-modal-secondary-${actions.secondary.name}`}
                data-qa={`button-modal-secondary-${actions.secondary.name}`}
                disabled={actions.secondary.disabled}
              >
                {actions.secondary.name}
              </Button>
            </Box>
          ))}
        {actions?.additional && (
          <Box>
            <ButtonWithLoading
              isLoading={Boolean(actions.additional.isLoading)}
              onClick={() => actions.additional?.onClick?.() ?? setOpen(true)}
              disabled={actions.additional.disabled}
              label={actions.additional.name}
              dataCy={'button-modal-additional'}
            />
          </Box>
        )}
        {actions?.primary && (
          <Box>
            <ButtonWithLoading
              isLoading={Boolean(actions.primary.isLoading)}
              onClick={() => actions.primary?.onClick?.() ?? setOpen(true)}
              disabled={actions.primary.disabled}
              label={actions.primary.name}
              dataCy={'button-modal-primary'}
              dataQa={'button-modal-primary'}
            />
          </Box>
        )}
      </Stack>
    </MuiDialogActions>
  )
}

export default Actions
