import React from 'react'
import { Dialog, DialogTitle, Typography, DialogActions, Button, DialogContent } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ExitToApp } from '@mui/icons-material'
import { useCustomTranslation } from './../../Hooks/useCustomTranslation'

const useStyles = makeStyles()(() => ({
  icon: {
    justifyContent: 'center',
    display: 'flex',
    '& .MuiSvgIcon-root': {
      fontSize: 50,
    },
  },
}))

export interface LogoutDialogProps {
  open: boolean
  handleClose: () => void
  handleLogout: () => void
}

const LogoutDialog: React.VFC<LogoutDialogProps> = ({ open, handleClose, handleLogout }) => {
  const { classes } = useStyles()
  const { t } = useCustomTranslation()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className={classes.icon}>
        <ExitToApp />
      </DialogContent>
      <DialogTitle>
        <Typography variant="h6">{t('common.logoutForSure')}</Typography>
      </DialogTitle>
      <DialogActions>
        <div>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('common.cancel')}
          </Button>
        </div>

        <div>
          <Button onClick={handleLogout} color="primary">
            {t('common.logout')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default LogoutDialog
