import React from 'react'
import { useTheme, Tooltip, Typography, TextFieldProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ReactSelect, { components, ControlProps, Theme as SelectTheme } from 'react-select'
import DropdownIndicator from './DropdownIndicator'
import { getSelectStyles } from './styles'

interface StyleProps {
  isMulti?: boolean
  minimal?: boolean
  isFloating?: boolean
  highlighted?: boolean
  fullWidth?: boolean
  disabled?: boolean
}

type LabeledOption<T> = {
  label: string | React.ReactElement
  value: T
}

const useSelectStyles = makeStyles<StyleProps>()((theme, props) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  root: {
    minWidth: 150,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  container: {
    minWidth: 150,
    width: props.fullWidth ? '100%' : undefined,
    '& > div': {
      paddingTop: props.isMulti ? theme.spacing(1) : 0,
      height: props.isMulti || props.minimal ? 'max-content' : theme.shape.inputField.height,
      maxHeight: props.isMulti || props.minimal ? 'max-content' : theme.shape.inputField.height,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: props.disabled ? theme.shape.inputField.disabledBackgroundColor : 'inherit',
      '& input': {
        height: theme.shape.inputField.height,
        marginTop: -3,
      },
      '&:focused': {
        borderColor: 'red',
      },
    },
    p: {
      fontSize: theme.shape.inputField.fontSize,
    },
  },
  floatingLabel: {
    position: 'absolute',
    transition: '0.2s ease all',
    transformOrigin: 'left center',
    top: props.isFloating ? -5 : '50%',
    left: 0,
    transform: props.isFloating ? theme.shape.inputField.labelActiveTransform : 'translateY(-50%)',
    fontSize: theme.shape.inputField.labelFontSize,
    color: theme.palette.text.primary,
    fontWeight: props.isFloating ? theme.shape.inputField.labelFontWeightShrinked : 'inherit',
    background: `linear-gradient(0deg, ${props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper} 0%, ${
      props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper
    } 50%, ${theme.palette.background.paper} 50%, ${theme.palette.background.paper} 100%)`,
    paddingLeft: props.isFloating ? `calc(${theme.spacing(1)} / 2)` : 14,
    paddingRight: `calc(${theme.spacing(1)} / 2)`,
    zIndex: props.isFloating ? 1 : 'inherit',
    '&:active': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const useTooltipStyles = makeStyles()(theme => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  tooltip: {
    backgroundColor: theme.palette.error.main,
    top: -8,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  arrow: {
    color: theme.palette.error.main,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  highlight: {
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: 8,
  },
}))

export interface ShellMultiSelectProps<T> {
  options: LabeledOption<T>[] | undefined
  value?: T[] | undefined
  onChange: (value: T[]) => void
  label?: string
  error?: { message: string } | string
  className?: string
  disabled?: boolean
  clearable?: boolean
  helperText?: string
  noOptionsMessage?: ({ inputValue }: { inputValue: string }) => string | null
  margin?: TextFieldProps['margin']
  fullWidth?: boolean
  placeholder?: string
  dataCy?: string
  highlighted?: boolean
}
/**
 * This component displays a select component with the option to choose multiple items at the same time.
 */
export const MultiSelect = <T,>({
  options,
  value,
  onChange,
  label,
  error,
  className,
  disabled,
  clearable = false,
  helperText,
  noOptionsMessage,
  placeholder,
  highlighted,
}: ShellMultiSelectProps<T>): React.ReactElement => {
  const muiTheme = useTheme()
  const { classes } = useTooltipStyles()
  const { classes: inputClasses, cx } = useSelectStyles({ isMulti: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Control = (defaultProps: ControlProps<any, any>): React.ReactElement => {
    const { classes } = useSelectStyles({ isFloating: defaultProps.isFocused || defaultProps.hasValue, highlighted })

    return (
      <>
        <Typography className={classes.floatingLabel}>{label}</Typography>
        <components.Control {...defaultProps} />
      </>
    )
  }

  return (
    <Tooltip title={typeof error === 'string' ? error : error?.message || helperText || ''} open={!!error || !!helperText} arrow classes={classes}>
      <span>
        <ReactSelect
          value={options ? value?.map(item => options.find(option => option.value === item)) : []}
          onChange={items => onChange(items?.map((item: LabeledOption<T>) => item!.value))}
          menuPortalTarget={document.body}
          noOptionsMessage={noOptionsMessage}
          components={{ DropdownIndicator, IndicatorSeparator: () => null, Control }}
          blurInputOnSelect
          styles={getSelectStyles(helperText ?? error, muiTheme, undefined, highlighted)}
          options={options}
          menuPlacement="auto"
          isClearable={clearable}
          placeholder={placeholder ?? ''}
          isMulti={true}
          isDisabled={disabled}
          className={cx(inputClasses.container, className)}
          theme={(theme: SelectTheme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: muiTheme.palette.primary.main,
            },
          })}
        />
      </span>
    </Tooltip>
  )
}
