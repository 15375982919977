/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * e.g. of conversion Object of type T { id: [1,2,3], name: ['test', 'name', 'one'] } => array of type U { id: 1, name: 'test' }
 * @function objectApiResult
 * @template T - type of object got by fetch
 * @template U - T is converted to an array of U items
 */
const objectApiResult = <T extends { [key: string]: any[] }, U extends { [key: string]: any }[]>(apiResult: T): U => {
  if (!apiResult) return [] as any as U

  const res = []
  const attributes = Object.keys(apiResult)
  if (attributes.length > 0) {
    for (let i = 0; i < apiResult[attributes[0]].length; i++) {
      const object: { [key: string]: any } = {}
      attributes.forEach(attr => {
        object[attr] = apiResult[attr][i]
      })

      res.push(object)
    }
  }

  return res as U
}

export default objectApiResult

interface ApiObject {
  [key: string]: any[]
}

export const revertObjectApiResult = (apiObject: { [key: string]: any }[]): { [key: string]: any[] } => {
  const newObject = Object.keys(apiObject[0]).reduce<ApiObject>((prev, curr) => {
    if (!prev[curr]) return { ...prev, [curr]: [] }
    return prev
  }, {})

  apiObject.forEach(object => {
    Object.keys(object).forEach(item => newObject[item]?.push(object[item]))
  })

  return newObject
}
