/**
 * Given a number or a string representing a number, it returns the amount of decimal points.
 * If the value is NaN, it returns 0.
 * */
export const determineDecimalPoints = (decimalNumber: string | number): number => {
  const decimalNumberSting = decimalNumber.toString()
  if (isNaN(parseFloat(decimalNumberSting))) return 0

  return decimalNumberSting.split('.').length > 1 ? decimalNumberSting.split('.')[1].length : 0
}

/**
 * - Given 0 it returns 1
 * - Given 1 it returns 0.1
 * - Given 2 it returns 0.01
 * - Given 3 it returns 0.001
 * - etc...
 */
export const getDecimalAddendWithXDecimalPoints = (decimalPoints: number): number => {
  if (decimalPoints <= 0) return 1
  return parseFloat(`0.${'0'.repeat(decimalPoints - 1)}1`)
}

/**
 * Custom decimal addition to bypass rounding errors like 0.1 + 0.2 = 0.30000000000000004
 */
export const addDecimals = (addend1: number, addend2: number): number => {
  const maxDecimalPoints = Math.max(determineDecimalPoints(addend1), determineDecimalPoints(addend2))
  return parseFloat((addend1 + addend2).toFixed(maxDecimalPoints))
}

/**
 * Contains a number between an optional minimum and optional maximum value
 */
export const clampNumber = (number: number, min: number | undefined, max: number | undefined): number => {
  if (min !== undefined && number < min) return min
  if (max !== undefined && number > max) return max
  return number
}

/**
 * Parses a string into a number, then compares it to a minimum and a maximum value.
 * If the value is out of bounds, it returns the bounded value as string, else it returns the input string unchanged.
 */
export const clampNumericString = (numericString: string, min: number | undefined, max: number | undefined): string => {
  const number = parseFloat(numericString)
  if (isNaN(number)) return numericString
  if (min !== undefined && number < min) return min.toString()
  if (max !== undefined && number > max) return max.toString()
  return numericString
}

/**
 * Parses a string to a decimal, clamped and with a maximum amount of decimal places.
 * Returns null if the value can not be parsed.
 */
export const parseDecimal = (number: string, maxDecimalDigits: number, min: number | undefined, max: number | undefined): number | null => {
  const parsedNumber = parseFloat(number)
  if (isNaN(parsedNumber)) return null
  const limitedDecimalPlacesNumber = parseFloat(parsedNumber.toFixed(maxDecimalDigits))
  return clampNumber(limitedDecimalPlacesNumber, min, max)
}

/**
 * Parses a string to an integer, clamped.
 * Returns null if the value can not be parsed.
 */
export const parseInteger = (number: string, min: number | undefined, max: number | undefined): number | null => {
  const parsedNumber = parseFloat(number)
  if (isNaN(parsedNumber)) return null
  return clampNumber(parsedNumber, min, max)
}
