import { Button, Popover } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { useState } from 'react'

const useStyles = makeStyles()(theme => ({
  menuContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiButtonBase-root': {
      width: '100%',
      marginBottom: theme.spacing(1),

      '&.MuiButton-outlined': {
        background: theme.palette.background.paper,
      },
    },
  },
}))

interface ButtonMenuProps {
  label: string
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({ label, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const { classes } = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const onClose = (): void => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  return (
    <>
      <Popover
        elevation={0}
        onClose={onClose}
        open={menuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.menuContainer,
        }}
      >
        {children}
      </Popover>
      <Button onClick={handleClick} variant={'outlined'} color="secondary">
        {label}
      </Button>
    </>
  )
}

export default ButtonMenu
