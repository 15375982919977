import { Box, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: 'solid',
    borderWidth: '2px',
    borderColor: theme.palette.background.paper2,
    width: 234,
    maxWidth: 234,
    minHeight: 84,
    margin: theme.spacing(2),
    color: theme.palette.grey.grey05,
    textAlign: 'center',
  },
}))

interface Props {
  message: string
  actionText?: string
}

const FileButtonBlankView: React.VFC<Props> = ({ message, actionText }) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.container}>
      <Typography variant="subtitle1">{message}</Typography>
      {actionText && <Typography variant="caption">{actionText}</Typography>}
    </Box>
  )
}

export default FileButtonBlankView
