import React, { ErrorInfo } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Box, Container, Grid, Typography } from '@mui/material'

const RETRY_REFRESHED = 'retry-refreshed'
const CHUNK_LOAD_ERROR = 'ChunkLoadError'

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  info?: ErrorInfo
}

export interface ErrorBoundaryProps {
  logo?: string
  errorComponent?: React.ReactNode
  navigate: NavigateFunction
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps & WithTranslation, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch = (error: Error, info: ErrorInfo): void => {
    if (error.name === CHUNK_LOAD_ERROR) {
      const hasRefreshed = JSON.parse(window.sessionStorage.getItem(RETRY_REFRESHED) || 'false')
      if (!hasRefreshed) {
        window.sessionStorage.setItem(RETRY_REFRESHED, 'true')
        return window.location.reload()
      }
    }

    this.setState({ hasError: true, error, info })
  }

  render = (): React.ReactElement | React.ReactNode => {
    const { logo, t, errorComponent, navigate } = this.props
    const errorInformation = `Error name: ${this.state.error?.name}, Error message: ${this.state.error?.message}`

    if (this.state.hasError) {
      if (errorComponent) return errorComponent
      return (
        <Container maxWidth="xs">
          <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 100 }}>
              <>
                {logo && (
                  <Box
                    component="img"
                    src={logo}
                    alt="Logo"
                    sx={{
                      mt: 4,
                      mb: 1,
                      maxHeight: 100,
                      cursor: 'pointer',
                      pb: 2,
                    }}
                    onClick={() => navigate('/')}
                  />
                )}
                <Typography variant="h4" align={'center'}>
                  {t('shell.jsError.error')}
                </Typography>
                <Typography variant="subtitle1" align={'center'} sx={{ mb: 4 }}>
                  {t('shell.jsError.message')}
                </Typography>
                {this.state.error && (
                  <Typography variant="caption" style={{ paddingTop: '50px' }}>
                    {errorInformation}
                  </Typography>
                )}
              </>
            </Grid>
          </Grid>
        </Container>
      )
    }

    return this.props.children
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = withTranslation()(ErrorBoundary)

export default Component
