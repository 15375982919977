import React from 'react'
import { Link as MuiLink } from '@mui/material'

import { Link as RouterLink } from 'react-router-dom'

export interface LinkRouterProps {
  to: string
  className?: string
  disabled?: boolean
  dataCy?: string
}

const LinkRouter: React.FC<LinkRouterProps> = ({ to, className, children, disabled, dataCy }) => {
  if (disabled) return <>{children}</>
  const protocolRegex = new RegExp('(http://){1}|(https://){1}')
  const isExternalLink = protocolRegex.test(to)
  return (
    <>
      {isExternalLink ? (
        <MuiLink className={className} component="a" target="_blank" rel="noopener noreferrer" href={to} data-cy={dataCy}>
          {children}
        </MuiLink>
      ) : (
        <MuiLink component={RouterLink} to={to} className={className} data-cy={dataCy}>
          {children}
        </MuiLink>
      )}
    </>
  )
}

export default LinkRouter
