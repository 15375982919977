import React from 'react'
import { DefaultOptions, QueryClient, QueryClientProvider } from 'react-query'

interface QueryProviderProps {
  queryClientConfig?: DefaultOptions
}

const QueryProvider: React.FC<QueryProviderProps> = ({ children, queryClientConfig }) => {
  const queryClient = new QueryClient({
    defaultOptions: queryClientConfig,
  })
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryProvider
