import React from 'react'
import { useStyles } from './styles'
import { Grid, List, ListItem, ListItemSecondaryAction, Skeleton } from '@mui/material'
import { TextSkeleton } from './TextSkeleton'

export const ApiListSkeleton = (): React.ReactElement => {
  const { classes, cx } = useStyles()

  return (
    <List className={classes.list}>
      {Array.from(Array(5).keys()).map((item, index) => (
        <ListItem key={item} className={cx({ [classes.listItem]: index < 4 })}>
          <Grid container>
            <Grid item xs={11}>
              <TextSkeleton variant="h4"></TextSkeleton>
              <TextSkeleton variant="body1"></TextSkeleton>
            </Grid>
          </Grid>
          <ListItemSecondaryAction>
            <Skeleton variant="circular" height={50} width={50}></Skeleton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}
