import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Box, Typography, Grid, Table, TableRow, TableCell, TableBody } from '@mui/material'
import { TextSkeleton } from './TextSkeleton'
import { useStyles } from './styles'

export type ColumnAmount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

interface ApiTableSkeletonProps {
  columnAmount?: ColumnAmount
  withFilter?: boolean
  filterCount?: number
  rowAmount?: ColumnAmount
}

export const ApiTableSkeleton: React.VFC<ApiTableSkeletonProps> = ({ columnAmount = 1, rowAmount = 10, withFilter = true, filterCount = 3 }) => {
  const { classes } = useStyles()

  const columns = new Array(columnAmount).fill('').map((item, index) => {
    return (
      <Grid key={index} item xs={Math.floor(12 / columnAmount) as ColumnAmount}>
        <TextSkeleton variant="h4" />
      </Grid>
    )
  })

  const filters = new Array(filterCount).fill('').map((item, index) => {
    return <Skeleton key={`filter-skeleton-${index}`} variant="rectangular" height={40} width={300} className={classes.filter} />
  })

  return (
    <>
      {withFilter && (
        <Box className={classes.filterContainer}>
          <Typography variant={'h6'} className={classes.headline}>
            Filter
          </Typography>
          <Box className={classes.filterWrapper}>{filters}</Box>
        </Box>
      )}
      <Grid container alignItems="stretch" justifyContent="space-between" spacing={2}>
        {columns}
      </Grid>
      <Table size="small">
        <TableBody>
          {new Array(rowAmount).fill('').map((_item, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextSkeleton variant="h4" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end">
        <Grid item xs={4}>
          <TextSkeleton variant="h5" />
        </Grid>
      </Grid>
    </>
  )
}
