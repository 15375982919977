import React, { useEffect } from 'react'
import moment, { Moment } from 'moment'
import { TextField as MuiTextField, Hidden, TextFieldProps } from '@mui/material'
import { useStyles } from '../styles'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { J2Multiply } from '@e3dc-react/icons'
import { DatePicker as KeyboardDatePicker, DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'
import { DateValidationError } from '@mui/x-date-pickers/internals'
import { useCustomTranslation } from '../../../Hooks/useCustomTranslation'

export interface DatePickerProps {
  onChange: (date: Moment | null) => void
  value?: Moment | null
  label?: string
  variant?: TextFieldProps['variant']
  clearable?: boolean
  minDate?: Moment
  minDateMessage?: string
  maxDate?: Moment
  helperText?: string
  required?: boolean
  format?: string
  disabled?: boolean
  initAfterRender?: boolean
  iconPaddingRight?: string
  shouldDisableDate?: (date: Moment) => boolean
  onError?: (error: DateValidationError | null, value: Moment | null) => void
  views?: ['day' | 'month' | 'year']
  className?: string
}

export const DatePicker: React.VFC<DatePickerProps> = ({
  onChange,
  value,
  label,
  minDate,
  minDateMessage,
  maxDate,
  helperText,
  format,
  disabled = false,
  variant = 'outlined',
  clearable = true,
  required = false,
  initAfterRender = false,
  iconPaddingRight,
  shouldDisableDate = () => false,
  onError = () => null,
  views,
  className,
}) => {
  const parameter: Partial<MuiDatePickerProps<Moment, Moment> & MuiDatePickerProps<Moment, Moment>> = {
    inputFormat: format || 'DD.MM.yyyy',
    onChange,
    label,
    value,
    minDate,
    maxDate,
    disabled,
    views,
    shouldDisableDate,
    onError,
  }

  const { classes, cx } = useStyles({ iconPaddingRight })
  const { selectedAppLanguage } = useCustomTranslation()

  useEffect(() => {
    moment.locale(selectedAppLanguage)
  }, [selectedAppLanguage])

  return (
    <>
      <Hidden mdUp>
        <MuiDatePicker
          {...(parameter as MuiDatePickerProps<Moment, Moment>)}
          renderInput={props => (
            <MuiTextField
              className={cx(classes.datePicker, className)}
              {...props}
              variant={variant}
              fullWidth
              helperText={helperText}
              required={required}
              error={!!helperText}
              FormHelperTextProps={{ error: !!helperText }}
            />
          )}
        />
      </Hidden>
      <Hidden mdDown>
        <KeyboardDatePicker
          {...(parameter as MuiDatePickerProps<Moment, Moment>)}
          renderInput={props => {
            return (
              <MuiTextField
                className={cx(classes.datePicker, className)}
                {...props}
                helperText={helperText}
                error={!!helperText}
                FormHelperTextProps={{ error: !!helperText }}
              />
            )
          }}
          InputProps={
            clearable
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => (onChange ? onChange(null) : {})} size="large">
                        <J2Multiply />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      </Hidden>
    </>
  )
}
