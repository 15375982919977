import React from 'react'
import { useState, useEffect } from 'react'

import { SuspenseRootFallback } from '../Suspense'
import { useRelogin } from '../../Hooks/Relogin'
import { useTypedSelector } from '../../State/RootReducer'

const Relogin: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true)

  const logout = useTypedSelector(state => state.app.logout)
  const relogin = useRelogin()

  useEffect(() => {
    const _fetch = async (): Promise<void> => {
      await relogin() // in case of errors, relogin redirects us to login page
      setLoading(false)
    }

    if (!logout) {
      _fetch()
    } else {
      setLoading(false)
    }
  }, [logout]) //eslint-disable-line

  if (loading) return <SuspenseRootFallback />

  return <>{children}</>
}

export default Relogin
