import React from 'react'
import { useTheme, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ReactSelect, {
  components,
  ControlProps,
  SingleValueProps,
  OptionProps,
  IndicatorsContainerProps,
  Theme as SelectTheme,
  SingleValue as SingleValueType,
} from 'react-select'
import DropdownIndicator from './DropdownIndicator'
import { CustomStyles } from './FeatureRichSelect'
import { getSelectStyles } from './styles'

type LabeledOption<T> = {
  label: string | React.ReactElement
  value: T
}

interface Props<T> {
  options: LabeledOption<T>[] | undefined
  value?: T | undefined
  onChange: (value: T) => void
  className?: string
  customSelectStyles?: CustomStyles
}

const useStyles = makeStyles()(theme => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  root: {
    minWidth: 75,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  container: {
    zIndex: 1000,
    minWidth: 75,
    width: '100%',
    ' p + div': {
      paddingTop: 0,
      height: theme.shape.inputField.height,
      maxHeight: theme.shape.inputField.height,
      borderRadius: '24px',
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.background.paper2,
      ':hover': {
        borderColor: theme.palette.primary.dark,
      },
      '& input': {
        height: theme.shape.inputField.height,
        marginTop: -3,
      },
    },
    span: {
      fontSize: theme.shape.inputField.fontSize,
    },
  },
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Control = (defaultProps: ControlProps<any, any>): React.ReactElement => {
  return (
    <>
      <Typography />
      <components.Control {...defaultProps} innerProps={Object.assign({}, defaultProps.innerProps)} />
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IndicatorsContainer = (props: IndicatorsContainerProps<any, any>): React.ReactElement => {
  return <components.IndicatorsContainer {...props}>{props.children}</components.IndicatorsContainer>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: OptionProps<any, any>): React.ReactElement => {
  const hasDescription = !!props.data.description
  return (
    <components.Option {...props}>
      <Typography>{props.data.label}</Typography>
      {hasDescription && <Typography variant="caption">{props.data.description}</Typography>}
    </components.Option>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SingleValue = (props: SingleValueProps<any>): React.ReactElement => {
  return (
    <components.ValueContainer {...props}>
      <Typography variant="caption">{props.data.label}</Typography>
    </components.ValueContainer>
  )
}

/**
 * A single select component that is characterized by having no (floating) label and allowing to select a single value out of the given options
 * @param param0
 * @returns
 */
export const SingleSelect = <T,>({ options, value, onChange, className, customSelectStyles }: Props<T>): React.ReactElement => {
  const muiTheme = useTheme()
  const { cx, classes } = useStyles()

  return (
    <ReactSelect
      value={options?.find(option => option.value === value) ?? null}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={item => onChange((item as SingleValueType<LabeledOption<any>>)?.value)}
      components={{ DropdownIndicator, IndicatorSeparator: () => null, Control, Option, SingleValue, IndicatorsContainer }}
      blurInputOnSelect
      options={options}
      menuPlacement="auto"
      menuPosition="absolute"
      menuShouldBlockScroll
      isMulti={false}
      className={cx(classes.container, className)}
      styles={getSelectStyles(undefined, muiTheme, false, true, false, false, customSelectStyles)}
      theme={(theme: SelectTheme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: muiTheme.palette.primary.main,
        },
      })}
    />
  )
}
