import { useState, useCallback } from 'react'

// we need to know token changes imidiatly and cannot wait for hooks to re-render after a redux update.
// the getAuthToken will always reference the local "token" variable and return the CURRENT value
// the setAuthToken will set the local "token" variable and trigger a state change to force a re-render to bubble-up the state change and re-render parent hooks in case they need to listen for changes
let token: string | undefined

export const useAuthToken = (): [() => string | undefined, (token?: string) => void] => {
  const [, setForceUpdate] = useState<boolean>(false)

  // emulate state changes by returning a new pseudo func WHEN token changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAuthToken = useCallback(() => token, [token])

  return [
    getAuthToken,
    newToken => {
      token = newToken
      setForceUpdate(i => !i)
    },
  ]
}
