import { shallowEqual } from 'fast-equals'
import { ModalAction, ModalState } from './ModalModels'

const initialState: ModalState<unknown, string> = {
  modals: [],
}

const modalReducer = <P, T extends string>(state = initialState, action: ModalAction<P, T>): ModalState<unknown, string> => {
  switch (action.type) {
    case 'SHOW_MODAL':
      if (shallowEqual(state.modals[state.modals.length - 1], action.modal) || !action.modal) return state
      return {
        modals: state.modals.concat(action.modal),
      }
    case 'HIDE_MODAL':
      return {
        modals: state.modals.slice(0, -1),
      }
    case 'HIDE_ALL_MODALS':
      return {
        modals: [],
      }
    default:
      return state
  }
}

export default modalReducer
