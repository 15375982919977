import { ApiViewAction, ApiViewState } from './ApiViewModel'

const initialState: ApiViewState = {
  refresh: 0,
}

const apiViewReducer = (state: ApiViewState = initialState, action: ApiViewAction): ApiViewState => {
  switch (action.type) {
    case 'REFRESH_API_VIEW':
      return { ...state, refresh: state.refresh < 1000000 ? state.refresh + 1 : 0 }
    default:
      return state
  }
}

export default apiViewReducer
