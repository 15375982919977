import React, { useEffect, useState } from 'react'
import { List as ListElement, ListItem as MuiListItem, ListItemText, ListItemSecondaryAction, Collapse, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useScrollBottomAction } from '../../Hooks/useScrollBottomAction'
import { DragDropContext, Droppable, Draggable, DraggableProvided, DropResult } from 'react-beautiful-dnd'
import { ApiListSkeleton } from '../Skeletons'
import { J2Multiply, K1Information } from '@e3dc-react/icons'
import { InfoText } from '../InfoText'

interface StyleProps {
  amount: number
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  border: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  scrollbar: {
    height: '100%',
    overflowY: 'auto',
  },
  listItemText: {
    marginRight: theme.spacing(2),
    overflow: 'hidden',
  },
  list: {
    '& .MuiListItem-secondaryAction': {
      paddingRight: props.amount * 40,
    },
  },
}))

export interface ListOnDragProps {
  itemId: string | number
  sourceIndex: number
  destinationIndex: number
}

export interface ListItem {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  icon?: React.ReactNode
  // used for drag and drop to identify the dragged item
  dragId?: number | string
  onSelect?: () => void
  dataCy?: string
  customFunctionalities?: React.ReactNode[]
}

interface ListProps {
  data: ListItem[]
  scrollable?: boolean
  draggable?: boolean
  /** the loading state will be shown till promise is executed  */
  onDragEnd?: (props: ListOnDragProps) => Promise<void>
  /** normal loading state */
  loading?: boolean
  fixedHeightScroll?: boolean
  collapsibleContent?: (index: number) => React.ReactNode
  dataCyPrefix?: string
  noDataText?: string
}

const reorder = (list: ListItem[], startIndex: number, endIndex: number): ListItem[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const List: React.VFC<ListProps> = ({
  data,
  onDragEnd,
  collapsibleContent,
  dataCyPrefix,
  noDataText,
  scrollable = false,
  draggable = false,
  loading = false,
  fixedHeightScroll = false,
}) => {
  const [shownData, setShownData] = useState(scrollable ? data.slice(0, 10) : data)
  const actionAmount = (collapsibleContent ? 1 : 0) + (data?.[0]?.customFunctionalities?.length ?? 0)
  const { classes, cx } = useStyles({ amount: actionAmount })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (scrollable) {
      setShownData(data.slice(0, 10))
    } else {
      setShownData(data)
    }
  }, [data, scrollable])

  // updates limit of fetched data when scrolling on mobile view
  useScrollBottomAction(() => {
    if (!scrollable) return
    if (shownData.length < data.length) {
      setShownData(data.slice(0, shownData.length + 5))
    }
  })

  const onDrag = async (result: DropResult): Promise<void> => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }
    setIsLoading(true)
    const reorderedShownData = reorder(shownData, result.source.index, result.destination.index)
    await onDragEnd?.({ itemId: result.draggableId, sourceIndex: result.source.index, destinationIndex: result.destination.index })
    setShownData(reorderedShownData)
    setIsLoading(false)
  }

  const ListItem = ({ item, index, provided }: { item: ListItem; index: number; provided?: DraggableProvided }): React.ReactElement => {
    const [collapsibleRowOpen, setCollapsibleRowOpen] = useState(false)

    return (
      <>
        <MuiListItem
          key={`listitem-${item.primary}-${index}`}
          button
          onClick={
            item.onSelect
              ? () => item.onSelect!()
              : () => {
                  return
                }
          }
          data-cy={`${dataCyPrefix}-${item.dataCy}`}
          className={cx({ [classes.border]: index < data.length - 1 })}
          ref={provided?.innerRef}
          {...provided?.draggableProps}
          {...provided?.dragHandleProps}
        >
          <ListItemText className={classes.listItemText} primary={item.primary || null} secondary={item.secondary || null} />
          <ListItemSecondaryAction>
            {item.icon} {item.customFunctionalities && item.customFunctionalities.map((functionality, index) => functionality)}
            {collapsibleContent && (
              <IconButton onClick={() => setCollapsibleRowOpen(!collapsibleRowOpen)} size="large">
                {collapsibleRowOpen ? <J2Multiply /> : <K1Information />}
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </MuiListItem>
        {collapsibleContent && (
          <Collapse in={collapsibleRowOpen} timeout="auto" unmountOnExit>
            {collapsibleRowOpen && collapsibleContent(index)}
          </Collapse>
        )}
      </>
    )
  }

  if (isLoading || loading) return <ApiListSkeleton />
  if (draggable) {
    return (
      <DragDropContext onDragEnd={onDrag}>
        <Droppable droppableId="dropId">
          {provided => (
            <ListElement
              style={{ padding: '0px', width: '100%', cursor: 'draggable' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
              key={`listitem-${provided.droppableProps['data-rbd-droppable-id']}`}
            >
              {shownData.map((item, index) => {
                return (
                  <Draggable key={`key-${item.dragId}`} draggableId={`${item.dragId}`} index={index}>
                    {provided => <ListItem provided={provided} item={item} index={index} />}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </ListElement>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <ListElement className={cx(classes.list, { [classes.scrollbar]: fixedHeightScroll })} style={{ padding: '0px', width: '100%' }}>
      {shownData.length === 0 && noDataText ? (
        <InfoText value={noDataText} icon={<K1Information />} />
      ) : (
        shownData.map((item, index) => {
          return <ListItem key={index} item={item} index={index} />
        })
      )}
    </ListElement>
  )
}

export default List
