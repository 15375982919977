import { useState, useEffect } from 'react'

/**
 * Hook to derive state from component property
 */
export const useDerivedState = <T,>(property: T): [T, (state: T) => void] => {
  const [state, setState] = useState<T>(property)

  useEffect(() => {
    setState(property)
  }, [property])

  return [state, setState]
}
