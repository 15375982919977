import { AppAction, AppActionPayload } from './AppModel'

const appUpdate = (payload: AppActionPayload): AppAction => {
  return { type: 'APP_UPDATE', payload }
}

const addMissingTranslationKey = (payload: AppActionPayload): AppAction => {
  return { type: 'ADD_MISSING_KEY', payload }
}

const resetMissingTranslationKeys = (payload: AppActionPayload): AppAction => {
  return { type: 'RESET_MISSING_KEYS', payload }
}

export { appUpdate, addMissingTranslationKey, resetMissingTranslationKeys }
