import { useCallback } from 'react'
import { useDispatch, useStore } from 'react-redux'

import axios, { AxiosResponse, CancelToken } from 'axios'
import JWT from 'jsonwebtoken'
import 'whatwg-fetch'

import { authPurge, authUpdate } from '../State/Auth/AuthActions'
import { useAuthToken } from './AuthFetch'
import { useTypedSelector } from '../State/RootReducer'
import { AuthActionPayload } from '../State/Auth/AuthModel'
import { Decoded } from '../Routes/Login'
import { AuthFetchError } from '../Types/api'
import { StatusCodes } from '../Libs/http-status-codes'
import { FetchErrorCodes } from '../Libs'

export const fetchPublicKey = async (cancelToken?: CancelToken): Promise<string> => {
  const link =
    '' +
    (process.env.REACT_APP_AUTH_ENDPOINT ?? process.env.REACT_APP_AUTH_API_ENDPOINT) +
    (process.env.REACT_APP_API_ENDPOINT_PUBLIC_KEY_ROUTE ?? process.env.REACT_APP_API_ENDPOINT_AUTHROUTE + '/public-key')

  const { data } = await axios.get(link, {
    cancelToken,
  })

  return data.publicKey
}

export const fetchAuthTokens = async (
  reAuthToken: string,
  cancelToken: CancelToken
): Promise<AxiosResponse<{ reAuthToken: string; token: string }>> => {
  const data = new FormData()
  data.append('reAuthToken', reAuthToken)

  const response = await axios.post(
    '' + (process.env.REACT_APP_AUTH_ENDPOINT ?? process.env.REACT_APP_AUTH_API_ENDPOINT) + process.env.REACT_APP_API_ENDPOINT_AUTHROUTE + '/re-auth',
    data,
    {
      cancelToken,
    }
  )

  if (response.status < 200 || response.status >= 300) {
    console.error('re-auth response', response)
    throw Error('re-auth failed HTTP code ' + response.status)
  }

  return response
}

/**
 * create and store a Promise when calling relogin() to re-auth ONCE and resolve once done
 */
let _reloginPromise: Promise<string> | undefined = undefined

/**
 * @return {Function} relogin
 */
export const useRelogin = (): (() => Promise<string>) => {
  const dispatch = useDispatch()
  const publicKey = useTypedSelector(state => state.auth.publicKey)
  const reAuthToken = useTypedSelector(state => state.auth.reAuthToken)
  const remember = useTypedSelector(state => state.auth.remember)
  const store = useStore()
  const [, setAuthToken] = useAuthToken()
  const source = axios.CancelToken.source()

  /** TODO: Decide if code is relevant
   * warning: I do not understand the purpose of this code
   * reason for commeting out: The code leads to issues after re-authentication. Some requests fail until the page is refreshed.
   */
  // useEffect(() => {
  //   return () => source.cancel('Operation canceled')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const _fetch = useCallback(() => {
    if (_reloginPromise) return _reloginPromise

    // eslint-disable-next-line no-async-promise-executor
    _reloginPromise = new Promise(async resolve => {
      let newPublicKey = undefined
      let newTokens = undefined
      let newDecoded: Decoded | undefined = undefined

      try {
        try {
          if (!publicKey) newPublicKey = await fetchPublicKey(source.token)
          if (reAuthToken) newTokens = await fetchAuthTokens(reAuthToken, source.token)
        } catch (error) {
          if (axios.isCancel(error)) {
            // throw err
            console.log(error)
            return
          }
          if (axios.isAxiosError(error)) {
            const err = error as AuthFetchError

            // unknown reauth error
            if (err?.response?.data?.statusCode === StatusCodes.UNAUTHORIZED && err?.response?.data?.errno === FetchErrorCodes.UnhandledJwtError) {
              console.error('re-auth Error')
              dispatch(authPurge())
              setAuthToken(undefined)
            }

            console.log('Relogin', err)

            if (window.location.pathname !== '/server-offline' && window.location.pathname.startsWith('/login') && err.message === 'Network Error') {
              window.location.replace(process.env.REACT_APP_BASEURL + '/server-offline')
            }
          }
        }

        if (newTokens && (newPublicKey || publicKey)) {
          try {
            // Default algorithm is HS256. DONT USE THAT! it uses a single shared public/private key! instead the RS256 uses seperate public/private keys
            newDecoded = JWT.verify(newTokens.data.token, newPublicKey || publicKey || '', {
              algorithms: ['RS256'],
            }) as Decoded
          } catch (error) {
            const err = error as AuthFetchError

            console.error('Relogin > JWT.verify', err)

            if (err.message === 'invalid signature' && !newPublicKey) {
              // if newPublicKey is set we JUST NOW fetched the key... if not its coming from cache and might be wrong
              // refetch public key 1 time and try again
              newPublicKey = await fetchPublicKey(source.token)
              newDecoded = JWT.verify(newTokens.data.token, newPublicKey, {
                algorithms: ['RS256'],
              }) as Decoded
            }
          }
        }

        // relogin attempt failed
        //if (reAuthToken && !newDecoded) return await logout(store)

        const payload: AuthActionPayload = {}
        if (newPublicKey) payload.publicKey = newPublicKey
        if (newDecoded) {
          payload.decoded = newDecoded as Decoded
          // payload.token = newTokens.token // only set tokens if decoding was successful
          payload.reAuthToken = newTokens?.data.reAuthToken
        } else {
          payload.decoded = undefined
          // payload.token = false
          payload.reAuthToken = undefined
        }

        // relogin succeeded
        if (newDecoded && remember && store._PERSISTOR && !store._PERSISTOR_PERSIST) {
          await store._PERSISTOR.persist() // tell the persistor to start/continue saving data before firing the dispatch!
          store._PERSISTOR_PERSIST = true // save our decision to persist for future relogins etc! otherwise redux sends rehydrates with empty error objects
        }
        setAuthToken(newDecoded ? newTokens?.data.token : undefined)
        dispatch(authUpdate(payload))

        _reloginPromise = undefined // done, clear promise

        return resolve(newDecoded ? newTokens?.data.token ?? '' : '') // return new token so that the fetch can try again
      } catch (err) {
        console.log('Relogin', err)
      }
    })

    return _reloginPromise
  }, [publicKey, reAuthToken, remember]) //eslint-disable-line

  return _fetch
}
