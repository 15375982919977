import { makeStyles } from 'tss-react/mui'

export const useTooltipStyles = makeStyles<{ zIndex?: number }>()((theme, props = {}) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  tooltip: {
    backgroundColor: theme.palette.error.main,
    top: -8,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  popper: {
    zIndex: props?.zIndex ?? 998,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  arrow: {
    color: theme.palette.error.main,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  highlight: {
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: 8,
  },
}))

interface StyleProps {
  type?: string
  clearable?: boolean
  hasSuffix?: boolean
  highlighted?: boolean
  labelPlacement?: 'start' | 'end'
  iconPaddingRight?: string
}
export const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  checkboxWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // eslint-disable-next-line tss-unused-classes/unused-classes
  checkboxRoot: {
    padding: `calc(${theme.spacing(1)} / 2)`,
    border: `1px solid ${theme.palette.grey.shadow}`,
    borderRadius: theme.shape.borderRadius,
  },

  // eslint-disable-next-line tss-unused-classes/unused-classes
  checkboxChecked: {
    '&.Mui-checked': {
      borderColor: theme.palette.action.active,
    },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  checkboxError: {
    color: theme.palette.error.main,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  searchFieldIcon: {
    padding: 0,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  optionRow: {
    display: 'flex',
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  checkboxLabel: {
    marginRight: theme.spacing(1),
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  input: {
    fontSize: theme.typography.fontSize,
    appearance: props.type === 'number' ? 'textfield' : 'auto',
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  searchField: {
    maxWidth: theme.shape.searchField.maxWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    '& .MuiFormLabel-root': {
      paddingRight: theme.spacing(2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300],
      borderWidth: 2,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300],
    },
    '& .MuiOutlinedInput-root': {
      transition: 'all 0.2s ease 0s',
      '&:hover fieldset': {
        borderWidth: 1,
        borderColor: theme.shape.searchField.hoverBorderColor,
        transition: 'all 0.2s ease 0s',
      },
    },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  textField: {
    color: theme.palette.text.primary,
    ...(props.highlighted ? { backgroundColor: theme.palette.background.changedInputField } : {}),
    '& .MuiOutlinedInput-root': {
      transition: 'all 0.2s ease 0s',
      '&:hover fieldset': {
        borderWidth: 1,
        transition: 'all 0.2s ease 0s',
      },
      paddingRight: theme.spacing(1),
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      color: theme.palette.text.primaryDisabled,
      backgroundColor: theme.shape.inputField.disabledBackgroundColor,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey.inputBorderDisabled,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1),
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  disabled: {
    borderColor: theme.palette.grey.inputBorderDisabled,
    color: theme.palette.text.primaryDisabled,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  inputLabel: {
    '&:not(.MuiInputLabel-shrink)': {
      width:
        props.type === 'number' ? (props.hasSuffix ? 'calc(100% - 105px)' : 'calc(100% - 85px)') : props.hasSuffix ? 'calc(100% - 85px)' : '100%',
      height: '100%',
    },
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    maxWidth: '100%',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  spaceRight: {
    marginRight: theme.spacing(3),
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  numberFieldArrows: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.secondary.main,

    '& .MuiIconButton-root': {
      padding: 0,

      '&:last-child': {
        marginTop: -11,
      },
    },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  highlightedLabel: {
    color: theme.palette.primary.main,
    ...(props.labelPlacement === 'end' ? { paddingLeft: theme.spacing(1) } : { paddingRight: theme.spacing(1) }),
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  datePicker: {
    '& .MuiOutlinedInput-root': {
      paddingRight: props.iconPaddingRight ?? theme.spacing(1),
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    width: '100%',
  },
}))
