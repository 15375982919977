import React from 'react'
import { useStyles } from './styles'
import { Backdrop, CircularProgress, Grid, Skeleton } from '@mui/material'
import { TextSkeleton } from '../Skeletons'

export const BarChartSkeleton: React.FC<{
  loading?: boolean
  progress?: number
  height?: number
  progressDetails?: React.ReactElement
  showLoadingPercent?: boolean
}> = ({ loading, progress, height, progressDetails, showLoadingPercent = true }) => {
  const { classes } = useStyles()

  return (
    <Grid container justifyContent="center" spacing={2} alignItems="flex-end" alignContent="flex-end" style={{ height: height ?? '100%' }}>
      {(loading || progress) && (
        <Backdrop open classes={{ root: classes.limitedBackdrop }}>
          {loading && <CircularProgress />}
          {showLoadingPercent && progress !== undefined && `${(progress * 100).toFixed(2)}%`}
          {progressDetails}
        </Backdrop>
      )}
      {new Array(10).fill('').map((_item, index) => (
        <Grid key={index} item xs={1}>
          <Skeleton variant="rectangular" height={Math.cos(index - 1) * 150} />
        </Grid>
      ))}
      <Grid item xs={10}>
        <TextSkeleton variant="body1"></TextSkeleton>
      </Grid>
    </Grid>
  )
}
