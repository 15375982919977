import React from 'react'
import { J1DownarrowD } from '@e3dc-react/icons'
import { DropdownIndicatorProps, components } from 'react-select'
import { makeStyles } from 'tss-react/mui'

const useSelectStyles = makeStyles()(theme => ({
  indicator: {
    color: theme.palette.secondary.main,
  },
}))

/**
 * This component displays a dropdown indicator (arrow) typically used by select components
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: DropdownIndicatorProps<any, any>): React.ReactElement => {
  const { classes } = useSelectStyles()

  return (
    <components.DropdownIndicator {...props} className={classes.indicator}>
      <J1DownarrowD />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
