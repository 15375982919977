import { useEffect, useMemo } from 'react'
import i18next, { InitOptions, i18n } from 'i18next'
import i18nextLanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'
import { useFetch } from './useFetch'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../State/RootReducer'
import { shallowEqual } from 'fast-equals'
import { appUpdate } from '../State/App/AppActions'
import { getAppName } from '../Utils/get-app-name'

interface AppLanguage {
  language: string
  updatedAt: string
}

const useI18Next = (
  appName: string,
  translationEndpoint: string,
  lowerCaseLng: InitOptions['lowerCaseLng'] = false,
  load: InitOptions['load'] = 'all'
): i18n => {
  const dispatch = useDispatch()
  const translationVersions = useTypedSelector(state => state.app.translationVersions)
  const app = useTypedSelector(state => state.app.app)
  const appNameToUse = getAppName(appName ?? '', app ?? '')

  const [versions] = useFetch<AppLanguage[]>({
    url: `translations/version/${appNameToUse}`,
    endpoint: translationEndpoint,
  })

  const i18n = useMemo(() => {
    i18next
      .use(i18nextLanguageDetector)
      .use(ChainedBackend)
      .use(initReactI18next)
      .init({
        debug: false,
        fallbackLng: 'de',
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        lowerCaseLng,
        load,
        saveMissing: false,
        missingKeyHandler: (lngs: readonly string[], ns: string, key: string, fallbackValue: string): void => {
          console.log(key)
        },
        returnEmptyString: false,
        react: {
          useSuspense: true,
          bindI18n: 'languageChanged loaded',
          bindI18nStore: 'added removed',
          nsMode: 'default',
        },
        backend: {
          backends: [LocalStorageBackend, HttpBackend],
          backendOptions: [
            {
              versions: translationVersions,
            },
            {
              loadPath: (language: string) => {
                const languageShort = language[0].includes('-') ? language[0].split('-')[0] : language
                return translationEndpoint + `/translations/files/${appNameToUse}/${languageShort}`
              },
            },
          ],
        },
      })
    return i18next
  }, [load, lowerCaseLng, translationVersions, appNameToUse, translationEndpoint])

  useEffect(() => {
    if (versions) {
      const reducedVersions: Record<string, string> =
        versions.reduce((prevValue, language) => {
          return { ...prevValue, [language.language]: language.updatedAt }
        }, {}) ?? {}

      if (!translationVersions || !shallowEqual(reducedVersions, translationVersions)) {
        dispatch(appUpdate({ translationVersions: reducedVersions }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions])

  useEffect(() => {
    if (i18next.options.backend?.backendOptions?.[0]) {
      i18next.options.backend.backendOptions[0].versions = translationVersions
    }
  }, [translationVersions])

  return i18n
}

export default useI18Next
