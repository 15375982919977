import { Modal, ModalAction } from './ModalModels'

/**
 * adds a modal to the modal list
 */
export const addModal = <P, T extends string>(modal: Modal<P, T>): ModalAction<P, T> => {
  return { type: 'SHOW_MODAL', modal: modal }
}

/**
 * removes the last modal
 */
export const removeModal = <P, T extends string>(): ModalAction<P, T> => {
  return { type: 'HIDE_MODAL' }
}

export const removeAllModals = <P, T extends string>(): ModalAction<P, T> => {
  return { type: 'HIDE_ALL_MODALS' }
}
