import React, { useEffect } from 'react'

import { Checkbox, FormControlLabel, CheckboxProps, Typography, SxProps, Theme, FormHelperText } from '@mui/material'

import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'
import { useStyles } from '../styles'

export interface CheckboxFieldProps<T> {
  onChange?: (value: T) => void
  label?: string | React.ReactNode
  value?: T
  name?: string
  icon?: React.ReactNode
  /** values to which false and true should be mapped */
  mapping?: [T, T]
  disabled?: boolean
  checked?: boolean
  tabIndex?: number
  disableripple?: boolean
  color?: CheckboxProps['color']
  initAfterRender?: boolean
  highlighted?: boolean
  labelPlacement?: 'start' | 'end'
  sx?: SxProps<Theme>
  checkedStyle?: string
  error?: boolean
  helperText?: string | React.ReactNode
}

export const CheckboxField = <T,>({
  onChange,
  icon,
  value,
  label,
  mapping,
  disabled,
  tabIndex,
  disableripple,
  color = 'primary',
  initAfterRender = false,
  highlighted = false,
  labelPlacement = 'end',
  sx,
  checkedStyle,
  error,
  helperText,
  ...otherProps
}: CheckboxFieldProps<T>): React.ReactElement => {
  const { classes, cx } = useStyles({ highlighted, labelPlacement })
  const checked = mapping ? value === mapping[1] : value

  useEffect(() => {
    if (initAfterRender && onChange) {
      if (mapping) {
        onChange(value ? mapping[1] : mapping[0])
      } else {
        if (value) onChange(value)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkbox = (
    <Checkbox
      icon={icon}
      sx={sx}
      checkedIcon={icon}
      onChange={event => {
        if (onChange) {
          if (mapping) {
            onChange(event.target.checked ? mapping[1] : mapping[0])
          } else {
            onChange(event.target.checked as unknown as T)
          }
        }
      }}
      checked={!!checked}
      classes={{ checked: checkedStyle ? checkedStyle : classes.checkboxChecked }}
      className={cx({ [classes.checkboxError]: error })}
      color={color}
      disabled={disabled}
      inputProps={{ tabIndex, disableripple } as SwitchBaseProps['inputProps']}
      {...otherProps}
    />
  )

  if (label) {
    return (
      <>
        <FormControlLabel
          control={checkbox}
          labelPlacement={labelPlacement}
          label={
            highlighted ? (
              <>
                {labelPlacement === 'start' && <span className={classes.highlightedLabel}>✱</span>}
                <Typography variant="body2">{label}</Typography>
                {labelPlacement === 'end' && <span className={classes.highlightedLabel}>✱</span>}
              </>
            ) : (
              <Typography variant="body2">{label}</Typography>
            )
          }
        />
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </>
    )
  }

  return (
    <>
      {checkbox}
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  )
}
