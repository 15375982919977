import { AuthAction, AuthActionPayload } from './AuthModel'

export function authUpdate(payload: AuthActionPayload): AuthAction {
  return { type: 'AUTH_UPDATE', payload }
}

export function authFetchAdd(): AuthAction {
  return { type: 'AUTH_FETCH_ADD' }
}

export function authFetchSub(): AuthAction {
  return { type: 'AUTH_FETCH_SUB' }
}

export function authPurge(): AuthAction {
  return { type: 'AUTH_PURGE' }
}
