import React, { useState } from 'react'
import { HeaderContext } from './context'
import { HeaderContextProps, HeaderProps } from './types'
import { TabsBarTab } from '../../Elements/Tabs/TabsBar'
import { useTypedSelector } from '../../State/RootReducer'
import { updateHeaderTab } from '../../State/Navigation/NavigationActions'
import { useDispatch } from 'react-redux'
import { Breadcrumb } from '../../Elements/Header/Breadcrumbs'

// TODO: use a given context key to differ between provided / used tabs
const Provider: React.FC<React.PropsWithChildren<HeaderProps>> = ({ children, tabs = [], breadcrumbs = [] }) => {
  const dispatch = useDispatch()
  const [pageHeaderTabs, setHeaderTabs] = useState<TabsBarTab[]>(tabs)
  const [headerBreadcrumbs, setHeaderBreadcrumbs] = useState<Breadcrumb[]>(breadcrumbs)
  const [headerHeight, setHeaderHeight] = useState<number>()
  // const [activeTab, setActiveTab] = useState<number>(0)
  const activeTab = useTypedSelector(store => store.navigation.headerTab)
  const setActiveTab = (index: number): void => {
    dispatch(updateHeaderTab(index))
  }

  const contextValues = {
    tabs: pageHeaderTabs,
    setTabs: setHeaderTabs,
    activeTab,
    setActiveTab,
    breadcrumbs: headerBreadcrumbs,
    setBreadcrumbs: setHeaderBreadcrumbs,
    height: headerHeight,
    setHeight: setHeaderHeight,
  } as HeaderContextProps

  return <HeaderContext.Provider value={contextValues}>{children}</HeaderContext.Provider>
}

export default Provider
