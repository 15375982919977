import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { appUpdate } from '../../State/App/AppActions'
// import { useModeSelector } from 'use-light-switch'
import { useTypedSelector } from '../../State/RootReducer'
import { AvailableTheme, ThemeObject } from '../../Libs/muiTheme'

const ThemeProvider: React.FC<ThemeObject> = ({ children, themes, defaultTheme }) => {
  const dispatch = useDispatch()

  // const selectedTheme: AvailableTheme | undefined = useModeSelector({
  //   light: 'light',
  //   dark: 'dark',
  //   unset: 'light',
  // })

  const selectedTheme: AvailableTheme = 'light'

  useEffect(() => {
    const theme = selectedTheme
    dispatch(appUpdate({ theme: theme }))
  }, []) //eslint-disable-line

  const theme: AvailableTheme | undefined = useTypedSelector(state => state.app.theme)
  const activeTheme = theme ? themes[theme] : themes[defaultTheme]

  // dispatch theme keys into state.app.themes
  React.useEffect(() => {
    dispatch(appUpdate({ themes: Object.keys(themes) }))
  }, []) // eslint-disable-line

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={activeTheme!}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
