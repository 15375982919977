import React, { useState } from 'react'

import { useAuthFetchWithResponse } from '../../Hooks/AuthFetch'
import { humanFileSize } from '../../Libs/humanFileSize'
import FileButton from '../Buttons/FileButton'
import doDownload from './doDownload'
import { DeleteGenerateUrl, DownloadGenerateUrl, OnDeleteFinished } from './FileTransferModel'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import { useSnackbar } from '../../Hooks/Snackbar'
import { useCustomTranslation } from '../../Hooks/useCustomTranslation'
export interface DownloadSingleProps {
  fileName: string
  fileSize: number
  deleteGenerateUrl: DeleteGenerateUrl
  downloadGenerateUrl: DownloadGenerateUrl
  onDeleteFinished: OnDeleteFinished
  apiEndpoint: string
}

const DownloadSingle: React.VFC<DownloadSingleProps> = ({
  fileName,
  fileSize,
  downloadGenerateUrl,
  deleteGenerateUrl,
  onDeleteFinished,
  apiEndpoint,
}) => {
  const authFetch = useAuthFetchWithResponse()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const { t } = useCustomTranslation()
  const downloadFile = async (fileName: string): Promise<void> => {
    const response = await authFetch<BlobPart>({
      path: downloadGenerateUrl(fileName),
      method: 'GET',
      payload: undefined,
      isRetry: false,
      type: 'blob',
    })
    doDownload(response, fileName)
  }

  const deleteFile = async (fileName: string): Promise<void> => {
    try {
      setLoading(true)
      await authFetch({ path: deleteGenerateUrl(fileName), method: 'DELETE' })
      setOpen(false)
      enqueueSuccessStack(t('shell.files.deleteSuccess', { fileName }))
      if (onDeleteFinished) onDeleteFinished()
    } catch (err) {
      console.log(err)
      enqueueErrorStack(t('shell.files.deleteFailed'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={open}
        title={t('shell.files.deleteConfirmTitle')}
        text={t('shell.files.deleteConfirmText', { fileName })}
        onConfirm={() => deleteFile(fileName)}
        onClose={() => setOpen(false)}
        isLoading={loading}
      />
      <FileButton
        name={fileName}
        ext={'pdf'}
        description={humanFileSize(fileSize)}
        onClickDownload={fileName => downloadFile(fileName)}
        onClickDelete={() => setOpen(true)}
        apiEndpoint={apiEndpoint}
      />
    </>
  )
}

export default DownloadSingle
