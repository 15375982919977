/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  list: {
    padding: 0,
  },
  editor: {
    width: '100%',
    minWidth: 500,
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper2,
    margin: theme.spacing(2),
  },
  headline: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  filter: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  pieSkeleton: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  right: {
    textAlign: 'right',
  },
  listItem: {},
  limitedBackdrop: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    backgroundColor: 'inherit',
    top: -10,
    bottom: -10,
    left: -10,
    right: -10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  pieChartProgress: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '25%',
  },
}))
