import React, { CSSProperties, isValidElement } from 'react'
import { useTheme, Tooltip, Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ReactSelect, {
  components,
  ControlProps,
  SingleValueProps,
  OptionProps,
  IndicatorsContainerProps,
  Theme as SelectTheme,
  StylesConfig,
  SingleValue,
} from 'react-select'
import { truncate } from 'lodash'

import DropdownIndicator from './DropdownIndicator'
import { getSelectStyles } from './styles'
import VirtualizedMenuList from './VirtualizedMenuList'
import { OptionTypeBase } from '../../Types/globalTypes'

interface StyleProps {
  isMenuOpen?: boolean
  isMulti?: boolean
  minimal?: boolean
  isFloating?: boolean
  isFocused?: boolean
  highlighted?: boolean
  fullWidth?: boolean
  disabled?: boolean
}

type LabeledOption<T> = {
  label: string | React.ReactElement
  value: T
}

export type CustomStyles = { [key in keyof StylesConfig<OptionTypeBase, boolean>]: CSSProperties }

const useSelectStyles = makeStyles<StyleProps>()((theme, props) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  root: {
    minWidth: 150,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  container: {
    minWidth: 150,
    width: props.fullWidth ? '100%' : undefined,
    'p + div': {
      paddingTop: props.isMulti ? theme.spacing(1) : 0,
      height: props.isMulti || props.minimal ? 'max-content' : theme.shape.inputField.height,
      maxHeight: props.isMulti || props.minimal ? 'max-content' : theme.shape.inputField.height,
      borderRadius: theme.shape.borderRadius,
      '& input': {
        height: theme.shape.inputField.height,
        marginTop: -3,
      },
    },
    p: {
      fontSize: theme.shape.inputField.fontSize,
    },
  },
  /** TODO: adjust floating label disabled design */
  floatingLabel: {
    position: 'absolute',
    transition: '0.2s ease all',
    transformOrigin: 'left center',
    top: props.isFloating ? -5 : '50%',
    left: 0,
    opacity: props.disabled ? 0.5 : 1,
    transform: props.isFloating ? theme.shape.inputField.labelFloatingActive : theme.shape.inputField.labelFloatingInactive,
    fontSize: props.disabled ? '14px' : theme.shape.select.labelFontSize,
    color: props.isFloating ? theme.palette.text.primary : theme.palette.text.primary,
    fontWeight: props.isFloating ? theme.shape.inputField.labelFontWeightShrinked : 'inherit',
    paddingLeft: `calc(${theme.spacing(1)} / 2)`,
    paddingRight: `calc(${theme.spacing(1)} / 2)`,
    zIndex: props.isMenuOpen ? 4 : 1,
    '&:active': {
      color: theme.palette.primary.main,
    },
    '&::after': {
      content: '" "',
      zIndex: -1,
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      height: '50%',
      background: props.disabled
        ? 'transparent'
        : `linear-gradient(0deg, ${props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper} 0%, ${
            props.highlighted ? theme.palette.background.changedInputField : theme.palette.background.paper
          } 50%, ${theme.palette.background.paper} 50%, ${theme.palette.background.paper} 100%)`,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
  floatingLabelError: {
    color: theme.palette.error.main,
  },
}))

const useTooltipStyles = makeStyles()(theme => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  tooltip: {
    backgroundColor: theme.palette.error.main,
    top: -8,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  arrow: {
    color: theme.palette.error.main,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  highlight: {
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: 8,
  },
  selectContainer: {
    width: '100%',
    color: theme.shape.select.textColor ? theme.shape.select.textColor : 'inherit',
  },
}))

export interface ShellSelectProps<T> {
  options: LabeledOption<T>[] | undefined
  value?: T | undefined
  onChange: (value: T) => void
  label?: string
  error?: { message: string } | string
  className?: string
  disabled?: boolean
  clearable?: boolean
  helperText?: string
  noOptionsMessage?: ({ inputValue }: { inputValue: string }) => string | null
  placeholder?: string
  minimal?: boolean
  hasBorder?: boolean
  dataCy?: string
  highlighted?: boolean
  isSearchable?: boolean
  fullWidth?: boolean
  name?: string
  required?: boolean
  useTooltipErrorMessage?: boolean
  menuShouldBlockScroll?: boolean
  menuPortalTarget?: HTMLElement | null
  changeMenuPosition?: boolean
  customSelectStyles?: CustomStyles
  hasVirtualList?: boolean
}

export const FeatureRichSelect = <T,>({
  options,
  value,
  onChange,
  label,
  error,
  className,
  disabled,
  clearable = false,
  helperText,
  noOptionsMessage,
  placeholder,
  minimal = false,
  hasBorder = true,
  highlighted = false,
  isSearchable = false,
  dataCy,
  fullWidth = true,
  name,
  required = false,
  useTooltipErrorMessage = true,
  menuShouldBlockScroll = true,
  menuPortalTarget = document.body,
  changeMenuPosition = false,
  customSelectStyles,
  hasVirtualList = false,
}: ShellSelectProps<T>): React.ReactElement => {
  const muiTheme = useTheme()
  const { classes } = useTooltipStyles()

  const { classes: inputClasses, cx } = useSelectStyles({ isMulti: false, minimal, highlighted, fullWidth, disabled, isMenuOpen: false })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Control = (defaultProps: ControlProps<any, any>): React.ReactElement => {
    const { classes } = useSelectStyles({
      isFloating: defaultProps.isFocused || defaultProps.hasValue,
      isFocused: defaultProps.isFocused,
      isMenuOpen: defaultProps.menuIsOpen,
      highlighted,
      disabled,
    })

    return (
      <>
        <Typography noWrap={true} className={cx(classes.floatingLabel, { [classes.floatingLabelError]: !!error })}>
          {label}
        </Typography>
        <components.Control {...defaultProps} innerProps={Object.assign({}, defaultProps.innerProps, { 'data-cy': dataCy })} />
      </>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const IndicatorsContainer = (props: IndicatorsContainerProps<any, any>): React.ReactElement => {
    return <components.IndicatorsContainer {...props}>{props.children}</components.IndicatorsContainer>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Option = (props: OptionProps<any, any>): React.ReactElement => {
    const hasDescription = !!props.data.description
    return (
      <components.Option {...props}>
        <Typography>{props.data.label}</Typography>
        {hasDescription && <Typography variant="caption">{props.data.description}</Typography>}
      </components.Option>
    )
  }

  /** TODO: adjust single value design for disabled cases */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SingleValue = (props: SingleValueProps<any>): React.ReactElement => {
    if (isValidElement(props.data.label)) {
      /** ReactElement should be rendered as it is. */
      return <components.ValueContainer {...props}>{props.data.label}</components.ValueContainer>
    }

    return (
      <components.ValueContainer {...props}>
        <Typography sx={{ opacity: disabled ? '50%' : '100%' }}>{truncate(props.data.label, { length: 60 })}</Typography>
      </components.ValueContainer>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MenuList = (props: any): React.ReactElement => {
    return <components.MenuList {...props}>{props.children}</components.MenuList>
  }

  const Select = (
    <ReactSelect
      required={required}
      value={options?.find(option => option.value === value) ?? null}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={item => onChange((item as SingleValue<LabeledOption<any>>)?.value)}
      menuPortalTarget={menuPortalTarget}
      isSearchable={isSearchable}
      noOptionsMessage={noOptionsMessage}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        Control,
        Option,
        SingleValue,
        IndicatorsContainer,
        MenuList: hasVirtualList ? VirtualizedMenuList : MenuList,
      }}
      blurInputOnSelect
      styles={getSelectStyles(helperText ?? error, muiTheme, minimal, hasBorder, highlighted, changeMenuPosition, customSelectStyles)}
      options={options}
      menuPlacement="auto"
      menuPosition="absolute"
      menuShouldBlockScroll={menuShouldBlockScroll}
      isClearable={clearable}
      placeholder={placeholder ?? ''}
      isMulti={false}
      isDisabled={disabled}
      className={cx(inputClasses.container, className)}
      theme={(theme: SelectTheme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: muiTheme.palette.primary.main,
        },
      })}
    />
  )

  if (!useTooltipErrorMessage && helperText) {
    return (
      <Box className={cx({ [classes.selectContainer]: fullWidth })}>
        {Select}
        <Typography sx={{ paddingLeft: '14px', color: muiTheme.palette.error.main }} variant="caption">
          {helperText}
        </Typography>
      </Box>
    )
  }

  return (
    <Tooltip title={typeof error === 'string' ? error : error?.message || helperText || ''} open={!!error || !!helperText} arrow classes={classes}>
      <span className={cx({ [classes.selectContainer]: fullWidth })}>{Select}</span>
    </Tooltip>
  )
}
