import React from 'react'
import { Dialog as MuiDialog, DialogActions, Button, DialogTitle, Typography, DialogContent, Stack } from '@mui/material'
import { ButtonWithLoading } from '../Buttons'
import { useCustomTranslation } from '../../Hooks/useCustomTranslation'

interface ConfirmationDialogProps {
  text: string
  title: string
  buttonText?: [string, string]
  onConfirm: (onClose: () => void) => void
  onCancel?: (onClose: () => void) => void
  onClose: () => void
  open?: boolean
  isLoading?: boolean
  children?: React.ReactNode
}

const ConfirmationDialog: React.VFC<ConfirmationDialogProps> = ({
  title,
  text,
  buttonText,
  onConfirm,
  onClose,
  onCancel,
  open = true,
  isLoading = false,
  children,
}) => {
  const { t } = useCustomTranslation()

  return (
    <MuiDialog open={open} onClose={() => onClose()}>
      <DialogTitle marginBottom={2} marginTop={2}>
        <Typography color="primary">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{text}</Typography>
      </DialogContent>
      {children !== undefined && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Stack direction={'row'} spacing={1}>
          <Button autoFocus onClick={() => (onCancel ? onCancel(onClose) : onClose())} color="primary">
            {buttonText?.[0] ?? t('common.no')}
          </Button>
          <ButtonWithLoading
            onClick={() => {
              onConfirm(onClose)
            }}
            isLoading={isLoading}
            label={buttonText?.[1] ?? t('common.yes')}
          />
        </Stack>
      </DialogActions>
    </MuiDialog>
  )
}

export default ConfirmationDialog
