/* eslint-disable @typescript-eslint/no-explicit-any */
type Order = 'asc' | 'desc'
export type CompareValue = number
export const compareFunctionByAttribute = <T extends { [key: string]: any }>(attribute: string) => {
  return function compare(a: T, b: T): CompareValue {
    if (a[attribute] < b[attribute]) return -1
    if (a[attribute] > b[attribute]) return 1
    return 0
  }
}

export const defaultSorting = <T extends { [key: string]: any }>(order: Order, orderBy: string): ((a: T, b: T) => CompareValue) => {
  return order === 'desc' ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1) : (a, b) => (b[orderBy] < a[orderBy] ? 1 : -1)
}

export const stringSorting = <T extends { [key: string]: any }>(order: Order, orderBy: string): ((a: T, b: T) => CompareValue) => {
  return order === 'desc'
    ? (a, b) => {
        if (!a[orderBy] || !b[orderBy]) {
          return 0
        }

        return (a[orderBy] as string).localeCompare(b[orderBy])
      }
    : (a, b) => {
        if (!a[orderBy] || !b[orderBy]) {
          return 0
        }

        return (b[orderBy] as string).localeCompare(a[orderBy])
      }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const nullsLast = <T extends {}>(comparator: (a: T, b: T) => CompareValue) => {
  return function compare(a: null | undefined | T, b: null | undefined | T): CompareValue {
    if (a === undefined || a === null) {
      if (b === undefined || b === null) {
        return 0
      }

      return 1
    }

    if (b === undefined || b === null) {
      return -1
    }

    return comparator(a, b)
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const nullsFirst = <T extends {}>(comparator: (a: T, b: T) => CompareValue) => {
  return function compare(a: null | undefined | T, b: null | undefined | T): CompareValue {
    if (a === undefined || a === null) {
      if (b === undefined || b === null) {
        return 0
      }

      return -1
    }

    if (b === undefined || b === null) {
      return 1
    }

    return comparator(a, b)
  }
}

export const chartNameSort = (a: string, b: string): CompareValue => {
  const lastNameA = a.slice(1) // remove first letter of first name and .
  const lastNameB = b.slice(1) // remove first letter of first name and .

  if (lastNameA === lastNameB) {
    return a.slice(0, 1) > b.slice(0, 1) ? 1 : a.slice(0, 1) < b.slice(0, 1) ? -1 : 0
  }

  return lastNameA > lastNameB ? 1 : lastNameA < lastNameB ? -1 : 0
}
