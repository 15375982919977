import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { appUpdate } from '../../State/App/AppActions'

export const LocationProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const pathNameParts = location.pathname.split('/')

  // save last visited page in case of refresh on the server-offline route if  the server is
  useEffect(() => {
    if (
      !['login', 'server-offline', 'new-password', 'forgot-password', 'reset-password', 'create-password'].includes(
        pathNameParts[pathNameParts.length - 1]
      )
    ) {
      dispatch(appUpdate({ lastPage: location.pathname }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <>{children}</>
}
