import { useTranslation } from 'react-i18next'
import { StringMap, TFunction, TOptions } from 'i18next'
import { useTypedSelector } from '../State/RootReducer'

export const useCustomTranslation = (): { t: TFunction; selectedAppLanguage: string } => {
  const lang = useTranslation()

  const isEditMode = useTypedSelector(store => store.app.languageEditMode)

  const translateFunction = (key: string | string[], options?: TOptions<StringMap> | string): string | string[] => {
    if (isEditMode) {
      return key
    }

    return lang.t(key, options)
  }

  return { t: translateFunction, selectedAppLanguage: lang.i18n.language }
}
