import React, { useState } from 'react'
import { MenuItem, TextField, Menu, ListItemIcon, ListItemText } from '@mui/material'

import { CheckboxField } from '../InputFields'
/**
 * Select Checkbox Component for multiple selectable items
 */
export interface SelectCheckBoxProps {
  label: string
  availableItems: string[]
  selectedItems: string[]
  setSelectedItems: (items: string[]) => void
}
export const SelectCheckBox: React.VFC<SelectCheckBoxProps> = ({ label, availableItems, selectedItems, setSelectedItems }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null)

  const changeItem = (item: string): void => {
    const tmpSelectedItems = selectedItems.slice(0)
    if (selectedItems.includes(item)) {
      tmpSelectedItems.splice(tmpSelectedItems.indexOf(item), 1)
    } else {
      tmpSelectedItems.push(item)
    }
    setSelectedItems(tmpSelectedItems)
  }

  if (!availableItems) return <TextField label={label} value=" " onClick={() => undefined} variant="outlined" margin="dense" />
  return (
    <>
      <TextField label={label} value={selectedItems.join(', ')} onClick={e => setAnchorEl(e.currentTarget)} variant="outlined" margin="dense" />
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {availableItems.map(item => {
          return (
            <MenuItem key={item} onClick={() => changeItem(item)}>
              <ListItemIcon>
                <CheckboxField checked={selectedItems.includes(item)} tabIndex={-1} disableripple />
              </ListItemIcon>
              <ListItemText primary={item} />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
