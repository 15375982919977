import { persistReducer, Storage } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AppAction, AppState } from './AppModel'

const initialState: AppState = {
  title: '',
  sidebarCollapsed: false,
  tabs: [],
  activeTab: 0,
  theme: undefined,
  themes: [],
  lang: undefined,
  langs: [],
  translationVersions: {},
  lastPage: '',
  country: '',
  app: 'e3dc',
  moreActive: false,
  appSettingsOpen: false,
  missingTranslationKeys: [],
  showMissingTranslations: false,
}

function appReducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case 'APP_UPDATE':
      return { ...state, ...action.payload }
    case 'ADD_MISSING_KEY': {
      const missingKey = action.payload.missingTranslationKey
      if (!missingKey) return state
      return {
        ...state,
        missingTranslationKeys: state.missingTranslationKeys.includes(missingKey)
          ? state.missingTranslationKeys
          : [...state.missingTranslationKeys, missingKey],
      }
    }
    case 'RESET_MISSING_KEYS': {
      return { ...state, missingTranslationKeys: [] }
    }
    default:
      return state
  }
}

const persistConfig: { key: string; storage: Storage; whitelist: (keyof AppState)[] } = {
  key: 'app',
  storage: storage,
  whitelist: ['theme', 'lang', 'lastPage', 'country', 'translationVersions', 'logout'],
}

export default persistReducer(persistConfig, appReducer)
