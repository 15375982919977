import { Dialog } from '../Dialog'
import { TextField } from '../InputFields'
import React, { MutableRefObject } from 'react'
import { useContext } from 'react'
import { useCustomTranslation } from '../../Hooks/useCustomTranslation'

interface TranslationEditorContextProps {
  translations: MutableRefObject<string[]>
  updateTranslationList: (value: string[]) => void
}

export const TranslationEditorContext = React.createContext<Partial<TranslationEditorContextProps>>({})

export interface TranslationEditorProps {
  place: number
  onClose: () => void
}

const TranslationEditor: React.FC<TranslationEditorProps> = ({ place, onClose }) => {
  const { translations } = useContext(TranslationEditorContext)
  const { t } = useCustomTranslation()

  const getContent = (): React.ReactElement[] | undefined => {
    return translations?.current.map(translation => <TextField key={`TranslationKey-${translation}`} value={translation} />)
  }

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      title={'Translations'}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
    >
      {getContent()}
    </Dialog>
  )
}

export default TranslationEditor
