import { useEffect } from 'react'

export const useScrollBottomAction = (action: () => void): void => {
  const onScroll = (): void => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300) {
      action()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false)
    return () => window.removeEventListener('scroll', onScroll, false)
  })
}
