import { Tooltip, useTheme } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import AsyncSelectCreatable from 'react-select/async-creatable'
import DropdownIndicator from './DropdownIndicator'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useAuthFetchWithResult } from '../../Hooks/AuthFetch'
import { objectApiResult } from '../../Libs/ApiHelpers'
import { getSelectStyles } from './styles'
import { getControlItem } from './get-control-item'
import { LabeledOption } from '../../Types/globalTypes'
import { SingleValue } from 'react-select'

interface StyleProps {
  fullWidth?: boolean
  disabled?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  selectContainer: {
    minWidth: 150,
    width: props.fullWidth ? '100%' : undefined,
    fontSize: props.disabled ? theme.shape.select.labelFontSizeDisabled : theme.shape.select.labelFontSize,
    color: theme.palette.text.primary,
    '& > div': {
      paddingTop: 0,
      height: theme.shape.inputField.height,
      maxHeight: theme.shape.inputField.height,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: props.disabled ? theme.shape.inputField.disabledBackgroundColor : 'inherit',
      '& input': {
        height: theme.shape.inputField.height,
        marginTop: -3,
      },
      '& div:first-of-type> div:first-of-type': {
        color: theme.shape.select.textColor,
        fontSize: theme.shape.select.fontSize,
      },
      '&:focus-within div[class*="placeholder"]': {
        opacity: 0,
      },
    },
  },
}))

interface Props<T> {
  label: string
  url: string
  filterByParameter: keyof T
  value: LabeledOption<string>
  mapFunction: (item: T) => LabeledOption<string>
  onSelect: (event: unknown) => void
  className?: string
  endpoint?: string
  fullWidth?: boolean
  tooltipText?: string
  disabled?: boolean
  clearable?: boolean
  highlighted?: boolean
  required?: boolean
}

/**
 * This component provides a select where (1) new options can be created by the user and (2) original options are fetched asynchronously
 */
const SelectCreatableFetched = <T extends Record<string, unknown>>({
  label,
  url,
  filterByParameter,
  value,
  mapFunction,
  onSelect,
  endpoint,
  tooltipText,
  disabled,
  clearable,
  fullWidth = false,
  highlighted = false,
  required = false,
}: Props<T>): React.ReactElement => {
  const { classes } = useStyles({ fullWidth, disabled })
  const selectRef = React.createRef<{ blur: () => void }>()
  const muiTheme = useTheme()

  const authFetch = useAuthFetchWithResult()

  const onLoadOptions = AwesomeDebouncePromise((searchString: string): Promise<{ value: string; label: string }[]> => {
    const basePath = [`${url}`]
    const filterPath = `?filter=${String(filterByParameter)}=*${searchString}*`
    if (searchString) basePath.push(filterPath)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options = authFetch<any>({
      path: basePath.join(''),
      endpoint,
    })
      .then(result => {
        const apiResultOptions = objectApiResult(result) as T[]
        return apiResultOptions.map(module => mapFunction(module)).sort((a, b) => a.label.localeCompare(b.label))
      })
      .catch(error => {
        console.error({ error })
        return []
      })
    return options
  }, 1000)

  const handleOnChange = (value: SingleValue<LabeledOption<string> | undefined>): void => {
    onSelect(value?.value)
    if (selectRef.current) {
      selectRef.current.blur()
    }
  }

  return (
    <Tooltip title={tooltipText ?? ''} open={!!tooltipText} arrow>
      <span>
        <AsyncSelectCreatable
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={selectRef}
          className={classes.selectContainer}
          cacheOptions={false}
          isClearable={clearable}
          escapeClearsValue
          placeholder={`${label} *`}
          defaultOptions
          isDisabled={disabled}
          styles={getSelectStyles(undefined, muiTheme, false, true)}
          components={{ DropdownIndicator, IndicatorSeparator: () => null, Control: getControlItem(label, highlighted, required) }}
          loadOptions={onLoadOptions}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          onChange={value => handleOnChange(value as SingleValue<LabeledOption<string>>)}
          createOptionPosition="last"
          value={value.value ? value : null}
        />
      </span>
    </Tooltip>
  )
}

export default SelectCreatableFetched
