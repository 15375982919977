export const downloadFile = (content: string, filename: string, type = 'image/png'): void => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  const blob = new Blob([content], { type })
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

export default downloadFile
