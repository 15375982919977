import React from 'react'

import { Button, IconButton as MuiIconButton, Fab, Tooltip, CircularProgress, FabProps, ButtonProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Search from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { J2Delete } from '@e3dc-react/icons'
import SaveIcon from '@mui/icons-material/Save'

import { LinkRouter } from '../Links'

interface StyleProps {
  hasUnderline?: boolean
  linkColor?: string
  hasLink?: boolean
  fullWidth?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  margin: {
    marginTop: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: props.hasLink ? 'pointer' : 'default',
    textDecoration: props.hasUnderline ? 'underline' : 'none',
    display: 'inline',
    color: props.hasLink ? props.linkColor ?? theme.palette.action.active : undefined,
    margin: 0,
    padding: 0,
    '&:hover,&:focus': {
      textDecoration: 'none',
    },
    '&:disabled': {
      cursor: 'default',
      color: theme.palette.action.disabled,
    },
    textAlign: 'left',
  },
  wrapper: {
    position: 'relative',
    width: props.fullWidth ? '100%' : 'auto',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  iconButton: {
    borderRadius: 12,
    padding: 6,
  },
  iconButtonBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transition: 'all 0.2s ease-in-out',

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      transition: 'all 0.2s ease-in-out',
    },
  },
}))

export interface StandardButtonProps {
  label?: string
  buttonProps?: Partial<FabProps> | Partial<ButtonProps>
  tooltip?: string
  className?: string
  disabled?: boolean
  onClick: () => void | Promise<void>
  size?: 'small' | 'medium' | 'large'
}

export const AddButton: React.VFC<StandardButtonProps> = ({ onClick, label, buttonProps, tooltip, className, disabled, size }) => (
  <ActionButton
    onClick={onClick}
    label={label}
    icon={props => <AddIcon {...props} />}
    buttonProps={buttonProps}
    tooltip={tooltip}
    className={className}
    disabled={disabled}
    size={size}
  />
)

export const EditButton: React.VFC<StandardButtonProps> = ({ onClick, label, buttonProps, tooltip, className }) => (
  <ActionButton
    onClick={onClick}
    label={label}
    icon={props => <EditIcon {...props} />}
    buttonProps={buttonProps}
    tooltip={tooltip}
    className={className}
  />
)

export const SaveButton: React.VFC<StandardButtonProps> = ({ onClick, label, buttonProps, tooltip, className, disabled = false }) => (
  <ActionButton
    onClick={onClick}
    label={label}
    icon={props => <SaveIcon {...props} />}
    buttonProps={{ ...buttonProps, color: 'secondary', variant: 'contained' }}
    tooltip={tooltip}
    className={className}
    disabled={disabled}
  />
)

export const RemoveButton: React.VFC<StandardButtonProps> = ({ onClick, label, buttonProps, tooltip }) => (
  <ActionButton onClick={onClick} label={label} icon={props => <DeleteIcon {...props} />} buttonProps={buttonProps} tooltip={tooltip} />
)

export interface ActionButtonProps extends StandardButtonProps {
  icon: (props?: { className: string }) => React.ReactElement
}

export const ActionButton: React.VFC<ActionButtonProps> = ({ onClick, label, icon, buttonProps, tooltip, className, disabled, size = 'small' }) => {
  const { classes, cx } = useStyles({})
  let button
  if (!label) {
    button = (
      <Fab
        color="secondary"
        size={size}
        className={cx(classes.margin, className)}
        onClick={onClick}
        disabled={disabled}
        {...(buttonProps as FabProps)}
      >
        {icon({ className: classes.extendedIcon })}
      </Fab>
    )
  } else {
    button = (
      <Button
        variant="outlined"
        color={'secondary'}
        startIcon={icon()}
        onClick={onClick}
        className={className}
        disabled={disabled}
        {...(buttonProps as ButtonProps)}
      >
        {label}
      </Button>
    )
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span>{button}</span>
      </Tooltip>
    )
  }

  return button
}

export interface LinkButtonProps {
  link?: string
  hasUnderline?: boolean
  linkColor?: string
  content: React.ReactNode
  onClick?: () => void
  linkClassName?: string
  clickable?: boolean
  dataCy?: string
  dataQa?: string
  href?: string
}

/**
 * Provides either a native link element or a react-router link, wrapped into a native link object.
 *
 * The first is used to provide link experience to resources outside the application.
 * The second is used for application internal navigation elements, that shall look like a link but behave as a button.
 *
 * Example:
 *
 * This will provide a native html link
 * ```
 * <LinkButton link="http://somwhere-else.com/agb">Link to partner AGB</<LinkButton>
 * ```
 *
 * This will provide a button styled a link, which ensures the behaviour of the internal router
 * ```
 * <LinkButton link="/deep/route/123">Link a internal route</<LinkButton>
 * ```
 *
 * @param link
 * @param hasUnderline
 * @param linkColor
 * @param content
 * @param onClick
 * @param clickable
 * @param linkClassName
 * @param dataCy
 * @constructor
 */
export const LinkButton: React.VFC<LinkButtonProps> = ({
  link,
  hasUnderline = true,
  linkColor,
  content,
  onClick,
  clickable = true,
  linkClassName,
  dataQa,
  ...rest
}) => {
  const { classes, cx } = useStyles({ hasUnderline, linkColor, hasLink: !!link || clickable })

  if (clickable && link)
    return (
      <LinkRouter className={cx(classes.linkButton, linkClassName)} to={link} disabled={!clickable} {...rest}>
        {content}
      </LinkRouter>
    )
  return (
    <button disabled={!clickable} className={cx(classes.linkButton, linkClassName)} onClick={() => onClick?.()} data-qa={dataQa} {...rest}>
      {content}
    </button>
  )
}

export type IconButtonType = 'menu' | 'logout' | 'search' | 'edit' | 'remove'
export type IconButtonSize = 'small' | 'medium'

export interface GenericIconButtonProps {
  onClick: () => void
  size?: IconButtonSize
  color?: 'primary' | 'secondary' | 'inherit'
}

export interface TypedIconButtonProps extends GenericIconButtonProps {
  type?: IconButtonType
  icon?: React.ReactNode
  withBackground?: boolean
  disabled?: boolean
}

export const IconButton: React.VFC<TypedIconButtonProps> = ({
  type,
  onClick,
  color,
  size = 'medium',
  icon,
  withBackground = false,
  disabled = false,
}) => {
  const { classes, cx } = useStyles({})
  let iconToUse

  if (type) {
    switch (type.toLowerCase()) {
      case 'menu':
        iconToUse = <MenuIcon />
        break
      case 'logout':
        iconToUse = <ExitToApp />
        break
      case 'search':
        iconToUse = <Search />
        break
      case 'edit':
        iconToUse = <EditIcon />
        break
      case 'remove':
        iconToUse = <J2Delete />
        break
      default:
    }
  }

  return (
    <MuiIconButton
      className={cx(classes.iconButton, { [classes.iconButtonBackground]: withBackground })}
      color={color ?? 'inherit'}
      onClick={onClick}
      size={size}
      disabled={disabled}
    >
      {icon ? icon : iconToUse}
    </MuiIconButton>
  )
}

export const MenuIconButton: React.VFC<GenericIconButtonProps> = ({ onClick, size, color }) => {
  return <IconButton type="menu" onClick={onClick} size={size} color={color} />
}

export const LogoutIconButton: React.VFC<GenericIconButtonProps> = ({ onClick, size, color }) => {
  return <IconButton type="logout" onClick={onClick} size={size} color={color} />
}

export const SearchIconButton: React.VFC<GenericIconButtonProps> = ({ onClick, size, color }) => {
  return <IconButton type="search" onClick={onClick} size={size} color={color} />
}
export const EditIconButton: React.VFC<GenericIconButtonProps> = ({ onClick, size, color }) => {
  return <IconButton type="edit" onClick={onClick} size={size} color={color} />
}

export const RemoveIconButton: React.VFC<GenericIconButtonProps> = ({ onClick, size = 'medium', color }) => {
  return <IconButton type="remove" onClick={onClick} size={size} color={color} />
}

export interface ButtonWithLoadingProps extends StandardButtonProps {
  isLoading?: boolean
  fullWidth?: boolean
  icon?: React.ReactElement
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
  dataCy?: string
  dataQa?: string
}

export const ButtonWithLoading: React.VFC<ButtonWithLoadingProps> = ({
  label,
  onClick,
  fullWidth,
  className,
  icon,
  color,
  isLoading = false,
  disabled = false,
  dataCy,
  dataQa,
  variant = 'contained',
  buttonProps,
}) => {
  const { classes } = useStyles({ fullWidth })

  return (
    <div className={classes.wrapper}>
      <Button
        {...buttonProps}
        variant={variant ?? 'contained'}
        color={color}
        disabled={disabled || isLoading}
        onClick={onClick}
        fullWidth={fullWidth}
        className={className}
        startIcon={icon}
        data-cy={dataCy}
        data-qa={dataQa}
      >
        {label}
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}
