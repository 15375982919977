import { J2Multiply } from '@e3dc-react/icons'
import { Box, CircularProgress, InputLabelProps, TextField, TextFieldProps, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import React from 'react'
import { useStyles, useTooltipStyles } from './styles'

type SimpleTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange?: (newValue: string) => void
  maxLength?: number
  clearable?: boolean
  suffix?: string
  prefix?: string | React.ReactNode
  loading?: boolean
  highlighted?: boolean
  InputLabelProps?: Partial<InputLabelProps>
  useTooltipErrorMessage?: boolean
  errorZIndex?: number
}

/** TextField component with the same styling as the TextField but does not debounce
 *  the values and lets its parent component handle its state. */
export const SimpleTextField: React.VFC<SimpleTextFieldProps> = ({
  prefix,
  suffix,
  maxLength,
  clearable = true,
  fullWidth = true,
  loading = false,
  highlighted = false,
  errorZIndex,
  ...textFieldProps
}) => {
  const { classes } = useTooltipStyles({ zIndex: errorZIndex })
  const { classes: inputStyles } = useStyles({ hasSuffix: !!suffix, highlighted })

  const { value, onChange, helperText, disabled, InputProps, ...other } = textFieldProps
  const { startAdornment, endAdornment, inputProps, ...otherInputProps } = InputProps ?? {}

  return (
    <Tooltip title={helperText || ''} open={!!helperText} arrow classes={classes}>
      <TextField
        value={value ?? ''}
        onChange={e => onChange?.(e.target.value)}
        fullWidth={fullWidth}
        error={Boolean(helperText)}
        helperText={helperText}
        margin="dense"
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {suffix && <Box>{suffix}</Box>}
              {clearable && !disabled && value !== '' && value !== undefined && (
                <IconButton onClick={() => onChange?.('')} classes={{ root: inputStyles.searchFieldIcon }} size="large">
                  <J2Multiply />
                </IconButton>
              )}
              {endAdornment}
              {loading && <CircularProgress size={24} />}
            </InputAdornment>
          ),
          startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : startAdornment ?? null,
          classes: {
            disabled: inputStyles.disabled,
          },
          inputProps: { maxLength, ...inputProps },
          ...otherInputProps,
        }}
        InputLabelProps={{
          classes: { root: inputStyles.inputLabel },
        }}
        classes={{ root: inputStyles.textField }}
        {...other}
      />
    </Tooltip>
  )
}
