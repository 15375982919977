import { useDispatch } from 'react-redux'
import 'whatwg-fetch'
import { stringify } from 'query-string'
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'

import { useAuthToken } from './AuthToken'
import { useRelogin } from '../Relogin'
import { authFetchAdd, authFetchSub } from '../../State/Auth/AuthActions'
import { useTypedSelector } from '../../State/RootReducer'
import { FetchErrorCodes, StatusCodes } from '../../Libs'
import { AuthFetchError } from '../../Types/api'

export interface Payload {
  filter?: string
  filterOr?: string
  connect?: string
  sort?: string
  limit?: number
  offset?: number
  publicKey?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decoded?: string | Record<string, any> | boolean
  reAuthToken?: string | boolean
  searchPhrase?: string
}

export type RequestType = AxiosRequestConfig['method']

interface RequestHeaders {
  'Accept-Language': string
}

export interface FetchProps {
  path: string
  method?: AxiosRequestConfig['method']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Payload | FormData | any
  isRetry?: boolean
  type?: ResponseType
  token?: string
  endpoint?: string
  option?: Record<string, unknown>
  abortSignal?: AbortSignal
  headers?: RequestHeaders
}
export interface FetchPropsWithResponse extends FetchProps {
  returnResponse: true
}

export function useAuthFetchWithResult(): <T>(props: FetchProps) => Promise<T> {
  return useAuthFetch(false)
}

export function useAuthFetchWithResponse(): <T>(props: FetchProps) => Promise<AxiosResponse<T>> {
  return useAuthFetch(true)
}

/**
 * AuthFetch CustomHook
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useAuthFetch(returnResponse: boolean): any {
  const relogin = useRelogin()
  const dispatch = useDispatch()
  const [getAuthToken] = useAuthToken()
  const logout = useTypedSelector(state => state.app.logout)

  /**
   * fetch that appends token to header and does automatic relogin if token is expired
   */
  async function _fetch<T>(props: FetchProps): Promise<T>
  async function _fetch<T>(props: FetchPropsWithResponse): Promise<AxiosResponse<T>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function _fetch<T>(props: FetchProps): Promise<any> {
    const {
      path,
      method = 'GET',
      payload = undefined,
      isRetry = false,
      type = undefined,
      endpoint = undefined,
      token = undefined,
      option = undefined,
      abortSignal,
      headers,
    } = props

    try {
      // increase fetch counter
      if (!isRetry) dispatch(authFetchAdd()) // in case isRetry is set, this is just a retry after re-auth... dont change the fetch counter

      let url = (endpoint ?? process.env.REACT_APP_API_ENDPOINT) + '/' + path
      const capitalizedMethod = method.toUpperCase()

      // append GET query string
      const query = capitalizedMethod === 'GET' && payload ? stringify(payload) : ''
      if (query) url += '?' + query

      let contentType

      // append payload
      if (capitalizedMethod !== 'GET' && payload) {
        if (payload instanceof FormData) {
          contentType = 'multipart/form-data'
        } else {
          contentType = 'application/json'
        }
      }

      const options: AxiosRequestConfig = {
        ...option,
        method,
        data: contentType === 'application/json' ? JSON.stringify(payload) : payload,
        headers: {
          authorization: 'Bearer ' + (token ?? getAuthToken()),
          'content-type': contentType,
          ...(headers ?? {}),
        },
        responseType: type,
      }

      // await new Promise(r => setTimeout(r, 2000)) // testing
      let response
      try {
        response = await axios(url, { ...options, signal: abortSignal })
      } catch (err) {
        const error = err as AuthFetchError
        if (axios.isCancel(err)) {
          throw err
        }

        if (error?.response?.status === StatusCodes.UNAUTHORIZED && error?.response?.data) {
          /** Check if the response is a Blob and parse it before relogin. */
          const isJsonBlob = error.response.data instanceof Blob
          const result = isJsonBlob ? JSON.parse(await (error.response.data as unknown as Blob).text()) : error.response.data
          const errno = result.attributes?.errno

          if (errno === FetchErrorCodes.JwtExpired || errno === FetchErrorCodes.NdaUnlockedRenewJwt) {
            if (!isRetry && !logout) {
              const newToken = await relogin()
              if (newToken) {
                const fetchProps = { path, method, endpoint, payload, isRetry: true, type: isJsonBlob ? 'blob' : type }

                if (returnResponse) {
                  return await _fetch<T>({ ...fetchProps, returnResponse })
                }

                return await _fetch<T>(fetchProps)
              }
            }
          }
        }

        throw err
      }
      // await new Promise(r => setTimeout(r, 2000)) // testing

      dispatch(authFetchSub())

      if (returnResponse) return response
      return await response.data
    } catch (err) {
      // subtract fetch counter
      if (!isRetry) {
        console.error('authFetch > catch', err)
        dispatch(authFetchSub()) // in case overwriteToken is set, this is just a retry after re-auth... dont handle errors.. just throw them and let the original fetch handle it
      }
      throw err
    }
  }

  return _fetch
}
