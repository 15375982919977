import React from 'react'

import { makeStyles } from 'tss-react/mui'
import { RouteContentSkeleton } from '../Skeletons'

const useStyles = makeStyles()(theme => ({
  root: {
    // width: '100%',
    // height: '100%',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
}))

/**
 * returns skeleton for route content
 */
const SuspenseContentFallback: React.VFC = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <RouteContentSkeleton />
    </div>
  )
}

export default SuspenseContentFallback
