import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import { TextSkeleton } from '../Skeletons'

interface EditorSkeletonProps {
  small?: boolean
}

export const EditorSkeleton: React.VFC<EditorSkeletonProps> = ({ small }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.editor}>
      <TextSkeleton variant="h3"></TextSkeleton>
      <TextSkeleton variant="h5"></TextSkeleton>
      <TextSkeleton variant="h5"></TextSkeleton>
      {!small && (
        <>
          <TextSkeleton variant="h5"></TextSkeleton>
          <TextSkeleton variant="h5"></TextSkeleton>
          <TextSkeleton variant="h3"></TextSkeleton>
          <TextSkeleton variant="h5"></TextSkeleton>
          <TextSkeleton variant="h5"></TextSkeleton>
        </>
      )}
    </Box>
  )
}
