import { AxiosResponse } from 'axios'

const doDownload = async (response: AxiosResponse<BlobPart>, fileName: string): Promise<void> => {
  const linkElement = document.createElement('a')
  document.body.appendChild(linkElement)
  linkElement.style.display = 'none'
  const blob2 = new Blob([response.data], { type: response.headers['content-type'] })
  const url = window.URL.createObjectURL(blob2)
  linkElement.href = url
  linkElement.download = fileName
  linkElement.click()
  window.URL.revokeObjectURL(url)
}

export default doDownload
