import React, { ErrorInfo } from 'react'

import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import Autorenew from '@mui/icons-material/Autorenew'

interface ErrorRootState {
  hasError: boolean
}

interface ErrorRootProps {
  children: React.ReactNode
}

class ErrorRoot extends React.Component<ErrorRootProps, ErrorRootState> {
  constructor(props: ErrorRootProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.log('ErrorRoot > componentDidCatch', { error, info })
    this.setState({ hasError: true })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Grid container>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={2}>
            &nbsp;
          </Grid>
          <Grid item xs={8} justifyContent="center">
            <Paper>
              <Typography variant="h4" color="secondary">
                &nbsp;
                <br />
                ERROR
                <br />
                REPEAT
                <br />
                &nbsp;
              </Typography>
              <Fab variant="extended" color="secondary" size="large" onClick={() => window.location.reload()}>
                <Autorenew />
                Reload
              </Fab>
              <br />
              &nbsp;
              <br />
              &nbsp;
            </Paper>
          </Grid>
        </Grid>
      )
    }
    return this.props.children
  }
}

export default ErrorRoot
