import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import DownloadSingle from './DownloadSingle'
import { DeleteGenerateUrl, DownloadGenerateUrl } from './FileTransferModel'
import { useAuthFetchWithResult } from '../../Hooks/AuthFetch'

const useStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}))

export interface DownloadProps {
  listUrl: string
  refresh: number
  deleteGenerateUrl: DeleteGenerateUrl
  downloadGenerateUrl: DownloadGenerateUrl
  apiEndpoint: string
}

export interface File {
  name: string
  size: number
}

const Download: React.VFC<DownloadProps> = ({ listUrl, deleteGenerateUrl, downloadGenerateUrl, refresh, apiEndpoint }) => {
  const { classes } = useStyles()
  const authFetch = useAuthFetchWithResult()
  const [listResult, setListResult] = useState<File[]>([])

  const fetchList = (): void => {
    authFetch<File[]>({ path: listUrl })
      .then(result => setListResult(result as File[]))
      .catch(() => null) // We don't need the error, but firefox crashes if not caught
  }

  useEffect(() => {
    fetchList()
  }, [refresh, listUrl]) // eslint-disable-line

  return (
    <Grid container alignItems="center" justifyContent="flex-start" className={classes.root}>
      {listResult &&
        listResult.map((file, index) => (
          <Grid item xs={12} key={index} md={6} lg={4}>
            <DownloadSingle
              key={file.name}
              deleteGenerateUrl={deleteGenerateUrl}
              onDeleteFinished={() => fetchList()}
              downloadGenerateUrl={downloadGenerateUrl}
              fileName={file.name}
              fileSize={file.size}
              apiEndpoint={apiEndpoint}
            />
          </Grid>
        ))}
    </Grid>
  )
}

export default Download
