import React, { useState } from 'react'
import { useTheme, useMediaQuery, DialogTitle, IconButton, Typography, DialogContent } from '@mui/material'
import MuiDialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from 'tss-react/mui'
import { useTypedSelector } from '../../State/RootReducer'
import ConfirmationDialog from './ConfirmationDialog'
import { useCustomTranslation } from '../../Hooks/useCustomTranslation'
import { DialogPaginationProps } from './DialogPagination'
import Actions from './Actions'
import { Transition } from './Transition'

interface StyleProps {
  place?: number
  app?: string
  blurBackground?: boolean
  scrollable: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingTop: (props?.place ?? 0) * 15 + 5,
    },
    height: '100%',
    '& .MuiDialog-paper.MuiPaper-root': {
      paddingBottom: 0,
      overflowX: props.scrollable ? undefined : 'hidden',
    },
    backdropFilter: props.blurBackground ? 'blur(3px)' : 'blur(0)',
  },
  dialogTitle: {
    minHeight: theme.shape.dialog.title.minHeight,
    display: 'flex',
    alignItems: 'center',
    [`${theme.breakpoints.down('md')} and (hover: none)`]: {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  dialogDivider: {
    borderTop: '2px solid',
    borderColor: theme.palette.grey.grey06,
    margin: `0 ${theme.shape.dialogDivider}px`,
  },
  title: {
    position: 'relative',
    marginLeft: theme.spacing(2),
    color: theme.palette.text.paperTitle,
    '&::before': {
      content: '""',
      display: 'block',
      width: 4,
      height: `calc(100% + ${theme.spacing(4)})`,
      backgroundColor: theme.palette.background.paperAccent,
      position: 'absolute',
      top: `-${theme.spacing(3)}`,
      left: `-${theme.spacing(2)}`,
    },
  },
}))

interface Action {
  id?: string
  name: string
  disabled?: boolean
  onClick?: () => void | Promise<void>
  className?: string
  isLoading?: boolean
}

export interface DialogActions {
  primary?: Action
  secondary?: Action | Action[]
  additional?: Action
}

interface DialogProps {
  title?: string | React.ReactElement
  onClose?: () => void
  place?: number
  open: boolean
  actions?: DialogActions
  additionalActionsLeft?: React.ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  confirmClose?: boolean
  disableClose?: boolean
  pagination?: DialogPaginationProps
  divider?: boolean
  blurBackground?: boolean
  scrollable?: boolean
  className?: string
  dataQa?: string
  useActions?: boolean
}

const Dialog: React.FC<DialogProps> = ({
  title,
  onClose,
  place,
  actions,
  additionalActionsLeft,
  children,
  pagination,
  className,
  maxWidth = 'md',
  confirmClose = false,
  disableClose,
  divider = true,
  blurBackground = false,
  scrollable = true,
  dataQa,
  useActions = true,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const theme = useTheme()
  const fullScreen = useMediaQuery(`${theme.breakpoints.down('md')} and (hover: none)`) // aims at phones and tablets
  const app = useTypedSelector(state => state.app.app)
  const { classes, cx } = useStyles({ place, blurBackground, scrollable })
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const { t } = useCustomTranslation()

  return (
    <>
      {confirmClose && (
        <ConfirmationDialog
          onClose={() => setConfirmationOpen(false)}
          onCancel={() => setConfirmationOpen(false)}
          onConfirm={() => {
            setConfirmationOpen(false)
            setIsOpen(false)
          }}
          text={t('dialog.confirmClose.text')}
          title={t('dialog.confirmClose.title')}
          open={confirmationOpen}
          data-qa={dataQa}
        />
      )}
      <MuiDialog
        open={isOpen}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth
        className={cx(classes.dialog, className)}
        TransitionComponent={Transition}
        keepMounted
        TransitionProps={{ onExited: () => onClose?.() }}
        onClose={() => {
          if (disableClose) return
          if (confirmClose) {
            setConfirmationOpen(true)
          } else {
            setIsOpen(false)
          }
        }}
        data-qa={dataQa}
      >
        <DialogTitle className={classes.dialogTitle} component={'div'}>
          {typeof title === 'string' ? (
            <Typography variant={theme.typography.dialog} className={classes.title} color="textPrimary">
              {title}
            </Typography>
          ) : (
            title
          )}
          {onClose ? (
            <IconButton
              className={classes.closeButton}
              data-qa={'button-modal-close'}
              onClick={() => {
                if (confirmClose) {
                  setConfirmationOpen(true)
                } else {
                  setIsOpen(false)
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>

        {divider && <div className={app === 'hager' ? undefined : classes.dialogDivider}></div>}

        {useActions && <Actions setOpen={setIsOpen} actions={actions} additionalActionsLeft={additionalActionsLeft} pagination={pagination} />}
      </MuiDialog>
    </>
  )
}

export default Dialog
