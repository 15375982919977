import moment from 'moment'

const parseDate = (mysqlDateString: string): Date | undefined => {
  if (!mysqlDateString) return undefined
  return moment.utc(mysqlDateString, 'YYYY-MM-DD HH:mm:ss').toDate()
}

export const parseNullableDate = (mysqlDateString: string): Date | null => {
  const date = parseDate(mysqlDateString)
  return date instanceof Date && !isNaN(date?.getTime()) ? date : null
}

export default parseDate
