import React from 'react'
import Grid from '@mui/material/Grid'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import { Typography, TypographyProps } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  center: {
    textAlign: 'center',
  },
}))

interface LoadingProps {
  circularProgress?: CircularProgressProps
  typography?: TypographyProps
  text?: string
}

const Loading: React.VFC<LoadingProps> = ({ circularProgress, typography, text }) => {
  const { classes } = useStyles()

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.center}>
        <CircularProgress {...circularProgress} />
        {text ? (
          <Typography variant="h6" {...typography}>
            {text}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default Loading
