import { useState, useEffect } from 'react'

/**
 * This custom hook returns a given value with a delay
 */
export const useDebounce = <T,>(value: T, delayInMs: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delayInMs)

    return () => {
      clearTimeout(handler)
    }
  }, [value]) // eslint-disable-line

  return debouncedValue
}
