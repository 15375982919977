import React from 'react'

import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/it'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/es'
import 'moment/locale/sv'
import 'moment/locale/fr-ch'
import 'moment/locale/de-ch'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { SnackbarProvider } from 'notistack'
import { SuspenseRoot } from '../Suspense'
import { Store } from 'redux'
import { Persistor } from 'redux-persist'
import { LocationProvider } from '../Provider/LocationProvider'
import QueryProvider from '../Provider/QueryProvider'
import { AvailableTheme } from '../../Libs/muiTheme'
import { Theme } from '@mui/material/styles'
import { ThemeProvider } from '../Provider'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { DefaultOptions } from 'react-query'
import i18next from 'i18next'
import { CookiesProvider } from 'react-cookie'
import ErrorBoundaryWrapper from '../Error/ErrorBoundaryWrapper'

export interface AppShellProps {
  store: Store
  persistor: Persistor
  logo?: string
  themes: { light?: Theme; dark?: Theme; default?: Theme }
  defaultTheme: AvailableTheme
  queryProviderDefaultOptions?: DefaultOptions
  errorComponent?: React.ReactNode
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const AppShell: React.FC<AppShellProps> = ({
  store,
  persistor,
  logo,
  themes,
  defaultTheme,
  children,
  queryProviderDefaultOptions,
  errorComponent,
}): React.ReactElement => {
  return (
    <CacheProvider value={muiCache}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18next.language}>
        <ReduxProvider store={store}>
          <ThemeProvider themes={themes} defaultTheme={defaultTheme}>
            <SnackbarProvider maxSnack={3} preventDuplicate={true}>
              {/* if a app doesnt use persistor, we gotta throw this in conditionally... at that point we might wanna define the redux provider+perist gate and whatever in the app itself and wrap children in it here*/}
              <PersistGate persistor={persistor}>
                <QueryProvider queryClientConfig={queryProviderDefaultOptions}>
                  <ErrorBoundaryWrapper logo={logo} errorComponent={errorComponent}>
                    <LocationProvider>
                      <CookiesProvider>
                        <SuspenseRoot>{children}</SuspenseRoot>
                      </CookiesProvider>
                    </LocationProvider>
                  </ErrorBoundaryWrapper>
                </QueryProvider>
              </PersistGate>
            </SnackbarProvider>
          </ThemeProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </CacheProvider>
  )
}

export default AppShell
