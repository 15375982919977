import React from 'react'
import { Button, ButtonGroup as MuiButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { useDerivedState } from '../../Hooks/UseDerivedState'

const useStyles = makeStyles<{ fullWidth?: boolean; useContrastColorForActiveButton: boolean }>()((theme, props) => ({
  button: {
    fontWeight: 600,
    borderRadius: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },
  buttonInactive: {
    backgroundColor: theme.palette.buttonGroup.inactiveBackground,
    color: theme.palette.buttonGroup.inactiveColor,
    borderWidth: theme.palette.buttonGroup.borderWidthInactive,
    borderColor: theme.palette.buttonGroup.borderColor,
    borderStyle: 'solid',

    '&:hover': {
      color: theme.palette.secondary.contrastText,
    },

    '&.MuiButtonGroup-groupedContainedSecondary:not(:last-child)': {
      borderColor: theme.palette.buttonGroup.borderColor,
      borderWidth: theme.palette.buttonGroup.borderWidthInactive,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonActive: {
    backgroundColor: theme.palette.buttonGroup.activeBackground,
    color: theme.palette.buttonGroup.activeColor,
    borderWidth: theme.palette.buttonGroup.borderWidth,
    borderStyle: 'solid',
    '&.MuiButtonGroup-groupedContainedSecondary:not(:first-child)': {
      backgroundColor: theme.palette.buttonGroup.activeBackground,
    },
    '&.MuiButtonGroup-groupedContainedSecondary:not(:last-child)': {
      borderWidth: theme.palette.buttonGroup.borderWidth,
      borderColor: theme.palette.buttonGroup.borderColorActive,
      backgroundColor: theme.palette.buttonGroup.activeBackground,
    },
    '&:hover': {
      [theme.breakpoints.up('md')]: props.useContrastColorForActiveButton
        ? {
            color: theme.palette.secondary.contrastText,
          }
        : undefined,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  root: {
    borderRadius: theme.shape.borderRadius,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: props.fullWidth ? 1 : 'inherit',
    overflow: 'hidden',
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  highlightedText: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
  },
}))

export interface ButtonAction {
  onChange: () => void
  label?: string
  id: string
  icon?: React.ReactNode
  disabled?: boolean
}

export interface ButtonGroupProps {
  actions: ButtonAction[]
  active?: number
  className?: string
  wrapperClassName?: string
  title?: string
  size?: 'small' | 'medium' | 'large'
  highlightTitle?: boolean
  disabled?: boolean
  fullWidth?: boolean
  fontSize?: number
  useContrastColorForActiveButton?: boolean
}

const ButtonGroup: React.VFC<ButtonGroupProps> = ({
  actions,
  active,
  title,
  className,
  wrapperClassName,
  size,
  highlightTitle = false,
  disabled = false,
  fullWidth = false,
  fontSize,
  useContrastColorForActiveButton = true,
}) => {
  const { classes, cx } = useStyles({ fullWidth, useContrastColorForActiveButton })
  const [activeIndex, setActiveIndex] = useDerivedState(active)
  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))

  /* TODO: the fullWidth option i currently blocked by the behaviour of the overall wrapper here */
  const onActiveChange = (index: number): void => {
    setActiveIndex(active ?? index)
    actions[index].onChange()
  }

  return (
    <div className={cx(classes.wrapper, wrapperClassName)}>
      {title && (
        <Typography variant={'caption'} gutterBottom={false} className={classes.title}>
          {title}
          {highlightTitle && <span className={classes.highlightedText}>✱</span>}
        </Typography>
      )}
      <MuiButtonGroup
        variant="contained"
        color="secondary"
        aria-label="contained secondary button group"
        classes={{
          groupedContainedPrimary: classes.button,
          root: classes.root,
        }}
        className={className}
        disableElevation={true}
        size={'small'}
        disabled={disabled}
        fullWidth={fullWidth}
      >
        {actions.map((action, i) => {
          return (
            <Button
              key={i}
              variant="contained"
              // color={i === activeIndex ? 'primary' : 'secondary'}
              onClick={() => onActiveChange(i)}
              disabled={action.disabled}
              startIcon={action.label ? action.icon : undefined}
              className={cx({ [classes.buttonActive]: i === activeIndex, [classes.buttonInactive]: i !== activeIndex })}
              size={size}
              data-cy={`button-group-action-${action.id}`}
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontSize: fontSize ?? (downSm ? theme.typography.caption.fontSize : theme.typography.body1.fontSize),
                }}
              >
                {!action.label && action.icon ? action.icon : action.label}
              </Typography>
            </Button>
          )
        })}
      </MuiButtonGroup>
    </div>
  )
}

export default ButtonGroup
