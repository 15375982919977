import { Theme } from '@mui/material'
import {
  CSSObjectWithLabel,
  ControlProps,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  GroupBase,
} from 'react-select'
import { CustomStyles } from './FeatureRichSelect'
import { OptionTypeBase } from '../../Types/globalTypes'

export const getSelectStyles = (
  error: { message: string } | undefined | string,
  muiTheme: Theme,
  isMinimal?: boolean,
  hasBorder?: boolean,
  highlighted?: boolean,
  changeMenuPosition?: boolean,
  customSelectStyles?: CustomStyles
): StylesConfig<OptionTypeBase | undefined, boolean, GroupBase<OptionTypeBase>> => ({
  container: (base: CSSObjectWithLabel) => ({
    ...base,
    height: isMinimal ? 25 : 'inherit',

    // minWidth: 200, //todo: only for filters like in portalD

    ...customSelectStyles?.container,
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    height: isMinimal ? 25 : 'inherit',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: isMinimal ? 'flex-end' : 'flex-start',
    paddingLeft: hasBorder ? muiTheme.shape.select.paddingLeft : 0,
    textWrap: 'nowrap',
    ...customSelectStyles?.valueContainer,
  }),
  input: (base: CSSObjectWithLabel) => ({
    height: '100%',
    ...customSelectStyles?.input,
  }),
  indicatorsContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    height: isMinimal ? 25 : 'inherit',
    ...customSelectStyles?.indicatorsContainer,
  }),
  control: (props: CSSObjectWithLabel, state: ControlProps<OptionTypeBase | undefined, boolean, GroupBase<OptionTypeBase>>) => ({
    ...props,
    minHeight: isMinimal ? 25 : 'inherit',
    backgroundColor: state.isDisabled ? muiTheme.shape.inputField.disabledBackgroundColor : muiTheme.palette.background.paper,
    boxShadow: 'none', //state.isFocused ? `0 0 0 2px ${muiTheme.palette.primary.main}` : 'none',
    borderColor: error
      ? muiTheme.palette.error.main
      : state.isFocused
      ? muiTheme.shape.select.controlIsFocusedColor
      : state.isDisabled
      ? muiTheme.palette.grey.inputBorderDisabled
      : muiTheme.palette.grey.inputBorder,
    ':hover': {
      borderColor: state.isFocused ? muiTheme.palette.primary.main : muiTheme.palette.text.primary,
      borderWidth: isMinimal ? 0 : state.isFocused ? 2 : 1,
    },
    borderWidth: isMinimal || !hasBorder ? 0 : state.isFocused ? 2 : 1,
    ...(highlighted ? { backgroundColor: muiTheme.palette.background.changedInputField } : {}),
    ...customSelectStyles?.control,
  }),
  menuPortal: (props: CSSObjectWithLabel) => ({ ...props, zIndex: 9999, ...customSelectStyles?.menuPortal }),
  singleValue: (props: CSSObjectWithLabel, state: SingleValueProps<OptionTypeBase | undefined, boolean, GroupBase<OptionTypeBase>>) => ({
    ...props,
    color: state.isDisabled ? muiTheme.palette.text.primaryDisabled : muiTheme.palette.text.primary,
    ...customSelectStyles?.singleValue,
  }),
  multiValue: (props: CSSObjectWithLabel) => ({ ...props, backgroundColor: muiTheme.palette.background.chip, ...customSelectStyles?.multiValue }),
  multiValueLabel: (props: CSSObjectWithLabel) => ({ ...props, color: muiTheme.palette.text.primary, ...customSelectStyles?.multiValueLabel }),
  option: (props: CSSObjectWithLabel, state: OptionProps<OptionTypeBase | undefined, boolean, GroupBase<OptionTypeBase>>) => ({
    ...props,
    color: muiTheme.palette.text.primary,
    backgroundColor: state.isFocused
      ? muiTheme.palette.select.optionFocused
      : state.isSelected
      ? muiTheme.palette.select.optionActive
      : muiTheme.palette.background.paper,
    '& > p': {
      fontSize: muiTheme.shape.select.fontSize,
    },
    fontSize: muiTheme.shape.select.fontSize,
    ...customSelectStyles?.option,
  }),
  menu: (props: CSSObjectWithLabel) => ({
    ...props,
    backgroundColor: muiTheme.palette.background.paper2,
    position: changeMenuPosition ? 'inherit' : 'absolute',
    zIndex: changeMenuPosition ? 4 : 0,
    ...customSelectStyles?.menu,
  }),
  clearIndicator: (props: CSSObjectWithLabel, state: ClearIndicatorProps<OptionTypeBase | undefined, boolean, GroupBase<OptionTypeBase>>) => ({
    ...props,
    paddingRight: 0,
    paddingLeft: 0,
    color: 'inherit',
    ...customSelectStyles?.clearIndicator,
  }),
  dropdownIndicator: (props: CSSObjectWithLabel, state: DropdownIndicatorProps<OptionTypeBase | undefined, boolean, GroupBase<OptionTypeBase>>) => ({
    ...props,
    paddingLeft: 0,
    color: state.isDisabled ? muiTheme.palette.grey.grey02 : muiTheme.shape.select.dropdownIndicatorColor,
    ...customSelectStyles?.dropdownIndicator,
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    lineBreak: 'anywhere',
    textAlign: isMinimal ? 'end' : 'inherit',
    zIndex: changeMenuPosition ? 4 : 0,
    ...customSelectStyles?.menuList,
  }),
})
