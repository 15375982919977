/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'

import { objectApiResult } from '../Libs/ApiHelpers'
import { Payload, useAuthFetchWithResult } from './AuthFetch/AuthFetch'
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query'
import { StatusCodes } from '../Libs/http-status-codes'
import { AuthFetchError } from '../Types/api'
import { FetchErrorCodes } from '../Libs'

const DEFAULT_MAX_FETCH_RETRIES = 2

export interface FetchError {
  message: string
}
export interface UseFetchProps {
  url: string
  payload?: Payload | any
  dependencies?: any[]
  token?: string
  connect?: string
  endpoint?: string
  doNotFetch?: boolean
  useIsFetching?: boolean
  retryOnFailure?: boolean
}

export type FetchedArrayDataType = { [key: string]: any }[]
export type FetchedObjectType = { [key: string]: any }
export type FetchedObjectApiDataType = { [key: string]: any[] }

export interface UseFetchPropsWithArray extends UseFetchProps {
  returnAsArray?: true
}

/**
 * @template T - type returned by both this and fetch Functions
 * @deprecated - we decided to not use this hook for new fetching methods anymore
 */
export function useFetch<T extends FetchedArrayDataType | FetchedObjectType>(
  props: UseFetchProps
): [
  T | undefined,
  boolean,
  AuthFetchError | undefined,
  boolean,
  (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<T, AuthFetchError>>
] {
  return useFetchAbstract<T>({ ...props })
}

/**
 * @template T - type returned by this function
 * @template U - only if returnsAsArray is defined - type returned by useFetch Function (e.g. if objectApiResult is applied, the T Type is modified)
 */
export function useFetchApiObject<T extends FetchedArrayDataType>(
  props: UseFetchProps
): [T | undefined, boolean, Error | undefined, boolean, (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<T, AuthFetchError>>] {
  return useFetchAbstract({ ...props, returnAsArray: true })
}

function useFetchAbstract<T extends FetchedArrayDataType | FetchedObjectType>(
  props: UseFetchProps
): [
  T | undefined,
  boolean,
  AuthFetchError | undefined,
  boolean,
  (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<T, AuthFetchError>>
]

function useFetchAbstract<T extends FetchedArrayDataType>(
  props: UseFetchPropsWithArray
): [
  T | undefined,
  boolean,
  AuthFetchError | undefined,
  boolean,
  (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<T, AuthFetchError>>
]

/**
 * @template T - if returnsAsArray type returned by this function, else the type returned by fetch function
 * @template U - only if returnsAsArray is defined - type returned by useFetch Function (e.g. if objectApiResult is applied, the T Type is modified)
 */
function useFetchAbstract<T extends FetchedArrayDataType, U extends FetchedObjectApiDataType>({
  url,
  payload,
  returnAsArray,
  dependencies = [],
  token,
  endpoint,
  doNotFetch,
  useIsFetching,
  retryOnFailure = true,
}: UseFetchPropsWithArray): [
  T | undefined,
  boolean,
  AuthFetchError | undefined,
  /* hasAuthError*/
  boolean,
  /* refetch callback */
  (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<T, AuthFetchError>>
] {
  const authFetch = useAuthFetchWithResult()

  const { data, isLoading, isFetching, error, isError, refetch } = useQuery<unknown, AuthFetchError, T>(
    [url, payload],
    async () => {
      if (doNotFetch) return undefined
      const fetchedData = await authFetch<T | U>({ path: url, method: 'GET', payload, token, endpoint })

      if (returnAsArray && typeof fetchedData === 'object' && !Array.isArray(fetchedData) && fetchedData !== null) {
        return objectApiResult<U, T>(fetchedData as U)
      }

      return fetchedData as T
    },
    {
      retry: (failureCount, thrownError) => {
        if (!retryOnFailure || thrownError.response?.data.attributes?.errno === FetchErrorCodes.NdaDoesNotExist) {
          return false
        }

        const isAxiosError = Boolean(thrownError.isAxiosError)
        const breakForResponse =
          thrownError.response?.status === StatusCodes.NOT_FOUND || thrownError.response?.data?.errno === FetchErrorCodes.DoesNotExist

        if (!isAxiosError) {
          console.warn('An unsupported error was thrown. Stop retry attempt now', thrownError)
          return false
        }

        return !(failureCount >= DEFAULT_MAX_FETCH_RETRIES && breakForResponse)
      },
    }
  )

  useEffect(() => {
    if (dependencies.length > 0) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, url, endpoint, token, returnAsArray, JSON.stringify(payload)])

  return [data, isLoading || (!!useIsFetching && isFetching), error ?? undefined, isError && error?.response?.status === 403, refetch]
}

export default useFetch
