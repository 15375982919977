import React from 'react'
import { useTheme, IconButton } from '@mui/material'
import { J1LeftarrowD, J1RightarrowD } from '@e3dc-react/icons'
import { makeStyles } from 'tss-react/mui'

export interface DialogPaginationProps {
  count: number
  page: number
  setPage: (page: number) => void
}

const useStyles = makeStyles()(theme => ({
  arrowWrapper: {
    color: theme.palette.secondary.main,

    '& svg': {
      fontSize: 30,
    },
  },
}))

const DialogPagination: React.VFC<DialogPaginationProps> = ({ count, page, setPage }) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const lastPage = count

  const handleBackButtonClick = (): void => {
    setPage(page - 1)
  }

  const handleNextButtonClick = (): void => {
    setPage(page + 1)
  }

  return (
    <div className={classes.arrowWrapper}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page" data-cy={'pagination-prev'} size="large">
        {theme.direction === 'rtl' ? <J1RightarrowD /> : <J1LeftarrowD />}
      </IconButton>
      <span>{`${page}/${count}`}</span>
      <IconButton onClick={handleNextButtonClick} disabled={page >= lastPage} aria-label="next page" data-cy={'pagination-next'} size="large">
        {theme.direction === 'rtl' ? <J1LeftarrowD /> : <J1RightarrowD />}
      </IconButton>
    </div>
  )
}

export default DialogPagination
