import React from 'react'
import { Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'
import { Variant } from '@mui/material/styles/createTypography'

interface StyleProps {
  titleTop?: boolean
  icon: React.ReactNode
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  textWrapper: {
    display: 'flex',
    flexDirection: props.titleTop ? 'column' : 'column-reverse',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: props.icon ? theme.spacing(1) : 0,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export interface InfoTextProps {
  title?: string
  value?: string | React.ReactElement
  titleTop?: boolean
  icon?: React.ReactNode
  className?: string
  titleVariant?: Variant
  valueVariant?: Variant
}

const InfoText: React.VFC<InfoTextProps> = ({ title, value, titleTop, icon, titleVariant, valueVariant, className }) => {
  const { classes, cx } = useStyles({ titleTop, icon })

  return (
    <div className={cx(classes.wrapper, className)}>
      {icon}
      <div className={classes.textWrapper}>
        <Typography variant={titleVariant ?? 'subtitle1'}>{title}</Typography>
        <Typography variant={valueVariant ?? 'subtitle2'}>{value}</Typography>
      </div>
    </div>
  )
}

export default InfoText
